import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../theme/utils/app-validators';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { AuthService } from '../../pages/auth/auth.service';
import { SnackbarService } from '../../shared/components/toast/snackbar.service';
import { ProjectMappingService } from '../project-mapping/project-mapping.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  public form: FormGroup;
  public settings: Settings;
  private userInfo: any;
  // passPattern = "/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,12}$/";

  constructor(
    public appSettings: AppSettings,
    public fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private projectMappingService: ProjectMappingService,
    private toast: SnackbarService
  ) {
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      'password': ["", [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,12}$/)]],
      'confirmPassword': ["", [Validators.required, Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,12}$/)]]
    })
  }

  ngOnInit(): void {
  }

  onSubmit(values: Object): void {
    this.form.markAllAsTouched();
    console.log('Form-Values', values);
    if (this.form.valid) {
      if (this.form.controls.password.value === this.form.controls.confirmPassword.value) {
        let resetKey: any;
        this.route.queryParams.subscribe((p) => {
          resetKey = p.key;
        })
        let data = {
          resetKey: resetKey,
          password: this.form.controls.password.value,
        }
        this.projectMappingService.resetPassword(data).subscribe((res) => {
          console.log("Reset-Password-->", res);
          this.toast.openSnackBar("Password Reset Success");
          this.router.navigate(['/login'])
        });
      } else {
        this.toast.openSnackBar("Password & Confirm Password is not Matching",);
      }
    } else {
      if (this.form.controls.password.errors.required) {
        this.toast.openSnackBar("Please Enter Password", true);
      } else if (this.form.controls.password.errors?.pattern) {
        this.toast.openSnackBar("Password Must contain atleast 1 Uppercase, Lowercase, SpecialCharacters", true);
      }

      if (this.form.controls.confirmPassword.errors.required) {
        this.toast.openSnackBar("Please Enter Confirm-Password", true);
      } else if (this.form.controls.confirmPassword.errors?.pattern) {
        this.toast.openSnackBar("Password Must contain atleast 1 Uppercase, Lowercase, SpecialCharacters", true);
      }
    }
    // this.projectMappingService.resetPassword()
  }

}
