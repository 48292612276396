import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class QcAdminUserService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}
  
  getQaUserListByUserId(userid:number) {
    const currEndPoint = this.apiUrl + `qc-control/getqauserlistbyuserid/${userid}`;
    return this.http.get(currEndPoint);
  }

  getAllQCEnabledProjectsList() {
    const currEndPoint = this.apiUrl + `qc-control/getprojectlist`;
    return this.http.get(currEndPoint);
  }

  getCountriesListByProject(projectId: number) {
    const currEndPoint = this.apiUrl + `qc-control/getcountrylist/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getSiteListByProjectCountry(projectId: number, countryCode: number) {
    const currEndPoint =
      this.apiUrl + `qc-control/getsitelist/${projectId}/${countryCode}`;
    return this.http.get(currEndPoint);
  }

  getAllQCEnabledUsersList() {
    const currEndPoint = this.apiUrl + `qc-control/getqauserlist`;
    return this.http.get(currEndPoint);
  }

  // QC Admin List - endpoints
  getDocUploadResourceProjWithoutSite(projectId: any) {
    const currEndPoint =
      this.apiUrl +
      `qc-control/getdocuploadresourceprojwithoutsite/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getDocUploadProjResourceList(projectId: any, siteId: number) {
    const currEndPoint =
      this.apiUrl +
      `qc-control/getdocuploadprojresourcelist/${projectId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getMonitorIssueLog(projectId: any, siteId: number) {
    const currEndPoint =
      this.apiUrl + `qc-control/getmonitorissuelog/${projectId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getDrugDestructionLog(projectId: any, siteId: number) {
    const currEndPoint =
      this.apiUrl + `qc-control/getdrugdestructionlog/${projectId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getSitePersonnelTrainingList(projectId: any, siteId: number) {
    const currEndPoint =
      this.apiUrl +
      `qc-control/getsitepersonneltraininglist/${projectId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getDocUploadSiteList(projectId: any, siteId: number) {
    const currEndPoint =
      this.apiUrl + `qc-control/getdocuploadsitelist/${projectId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getdocuploadprojectlist(projectId: any) {
    const currEndPoint =
      this.apiUrl + `qc-control/getdocuploadprojectlist/${projectId}`;
    return this.http.get(currEndPoint);
  }

  assignDocsToQCUsers(userId: number, docDetailsCollection) {
    const currEndPoint = this.apiUrl + `qc-control/assignqcuser/${userId}`;
    return this.http.post(currEndPoint, docDetailsCollection);
  }

  // QC User List - endpoints
  getQADocSiteList(userId: any) {
    const currEndPoint = this.apiUrl + `qc-control/getqadocsitelist/${userId}`;
    return this.http.get(currEndPoint);
  }

  getQADocProjectList(userId: any) {
    const currEndPoint =
      this.apiUrl + `qc-control/getqadocprojectlist/${userId}`;
    return this.http.get(currEndPoint);
  }

  getQADocResourceProjectList(userId: any) {
    const currEndPoint =
      this.apiUrl + `qc-control/getqadocresourceprojectlist/${userId}`;
    return this.http.get(currEndPoint);
  }

  getQASiteTrainingList(userId: any) {
    const currEndPoint =
      this.apiUrl + `qc-control/getqasitetraininglist/${userId}`;
    return this.http.get(currEndPoint);
  }

  getQADrugDestructionLog(userId: any) {
    const currEndPoint =
      this.apiUrl + `qc-control/getqadrugdestructionlog/${userId}`;
    return this.http.get(currEndPoint);
  }

  getQAMonitorIssueLog(userId: any) {
    const currEndPoint =
      this.apiUrl + `qc-control/getqamonitorissuelog/${userId}`;
    return this.http.get(currEndPoint);
  }

  approveRejectQCDoc(docId: number, approvalStatusInfo) {
    const currEndPoint = this.apiUrl + `qc-control/approveorrejectqc/${docId}`;
    return this.http.post(currEndPoint, approvalStatusInfo);
  }

  approveRejectQCDocIsf(docId: number, approvalStatusInfo) {
    const currEndPoint = this.apiUrl + `qc-control/approveorrejectqcforisf/${docId}`;
    return this.http.post(currEndPoint, approvalStatusInfo);
  }
}
