import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { emailValidator } from '../../theme/utils/app-validators';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { AuthService } from '../../pages/auth/auth.service';
import { SnackbarService } from '../../shared/components/toast/snackbar.service';
import { ProjectMappingService } from '../project-mapping/project-mapping.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public form: FormGroup;
  public settings: Settings;
  private userInfo: any;

  constructor(
    public appSettings: AppSettings,
    public fb: FormBuilder,
    public router: Router,
    private projectMappingService: ProjectMappingService,
    private authService: AuthService,
    private toast: SnackbarService
  ) {
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      'email': [null, Validators.compose([Validators.required, emailValidator])],
    })
  }

  public onSubmit(values: Object): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      console.log("Form-Values:", values);
      this.projectMappingService.forgotPassword(values).subscribe((res: any) => {
        console.log("Forgot-Password:", res);
        if (res?.length == 0) {
          this.toast.openSnackBar("Invalid email id, Please enter valid email!!", true);
        } else {
          this.toast.openSnackBar("Password reset link has been sent to your email");
          this.router.navigate(['/login'])
        }
      });
    } else {
      if (this.form.controls.email.errors.required) {
        this.toast.openSnackBar("Please Enter E-mail", true);
      } else if (this.form.controls.email.hasError('invalidEmail')) {
        this.toast.openSnackBar("Please Enter Valid E-Mail", true);
      }
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.settings.loading = false;
  }

}
