import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AppHttpService {
  BASE_URL = environment.apiUrl;
  LOGIN_URL = environment.loginApi;
  defaultHeader = { Accept: "application/json" };

  constructor(private http: HttpClient) {}

  get(url: string) {
    return this.http.get(`${this.BASE_URL}${url}`);
  }
  post(url: string, data: any, headers = null) {
    return this.http.post(`${this.BASE_URL}${url}`, data, {
      headers: headers ? headers : this.defaultHeader,
    });
  }
  put(url: string, data: any, headers = null) {
    return this.http.put(`${this.BASE_URL}${url}`, data, {
      headers: headers ? headers : this.defaultHeader,
    });
  }
  postLogin(url: string, data: any, headers = null) {
    return this.http.post(`${this.LOGIN_URL}${url}`, data, {
      headers: headers ? headers : this.defaultHeader,
    });
  }
}
