export class GlobalConstants {
  
  public static apiURL: string = ''

  public static key: string = 'asycr@12sudhi$34jeev'

  //CRO Roles
  public static crpm: string = 'ROLE_GRAVITY_CRO_PROJECT_MANAGER'
  public static crdm: string = 'ROLE_GRAVITY_CRO_DATA_MANAGER'
  public static crqa: string = 'ROLE_GRAVITY_CRO_QUALITY_ASSURANCE_MANAGER'
  public static crcra: string = 'ROLE_GRAVITY_CRO_CLINICAL_RESEARCH_ASSOCIATE'
  public static crcta: string = 'ROLE_GRAVITY_CRO_CLINICAL_TRIAL_ASSISTANT'

  //Sponsor Roles
  public static sppm: string = 'ROLE_GRAVITY_SPONSOR_PROJECT_MANAGER'
  public static spmm: string = 'ROLE_GRAVITY_SPONSOR_MEDICAL_MONITOR'
  public static spqa: string = 'ROLE_GRAVITY_SPONSOR_QUALITY_ASSURANCE_MANAGER'
  public static spcra: string = 'ROLE_GRAVITY_SPONSOR_CLINICAL_RESEARCH_ASSOCIATE'
  public static spcta: string = 'ROLE_GRAVITY_SPONSOR_CLINICAL_TRIAL_ASSISTANT'

  //Site Roles
  public static pi: string = 'ROLE_GRAVITY_PRINCIPAL_INVESTIGATOR'
  public static si: string = 'ROLE_GRAVITY_SUB_INVESTIGATOR'
  public static sc: string = 'ROLE_GRAVITY_STUDY_CO-ORDINATOR'
  public static sn: string = 'ROLE_GRAVITY_STUDY_NURSE'
  public static ph: string = 'ROLE_GRAVITY_PHARMACISTS'
  public static llh: string = 'ROLE_GRAVITY_LOCAL_LAB_HEAD'
  public static lt: string = 'ROLE_GRAVITY_LAB_TECHNICIAN'

  //QA Roles
  public static qaa: string = 'ROLE_GRAVITY_QA_ADMIN'
  public static qau: string = 'ROLE_GRAVITY_QA_USER'

  //Gravity Admin
  public static admin: string = 'ROLE_GRAVITY_ADMIN'
  public static superadmin: string = 'ROLE_ASSAY'
}
