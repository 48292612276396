import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

export const MY_FORMATS = {
  parse: {
      dateInput: 'DD-MMM-YYYY',
  },
  display: {
      dateInput: 'DD-MMM-YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'DD-MMM-YYYY',

      monthYearA11yLabel: 'MMM YYYY',
  },
};

@Component({
  selector: 'app-add-site-dashboard',
  templateUrl: './add-site-dashboard.component.html',
  styleUrls: ['./add-site-dashboard.component.scss'],
  providers: [

    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
],
})
export class AddSiteDashboardComponent implements OnInit {
  public form:FormGroup;
  isAdd: boolean;
  constructor(public dialogRef: MatDialogRef<AddSiteDashboardComponent>,
              @Inject(MAT_DIALOG_DATA) public croData: any,
              public fb: FormBuilder) {
    dialogRef.disableClose = true;
   
    this.form = this.fb.group({
      id: null,
      caApprovalDate: ['', Validators.required],
      ecApprovalDate: ['', Validators.required],
      siteInitiationDate: ['', Validators.required],
      greenLightedOn: ['', Validators.required],

      screening: ['', Validators.required],
      randomization: ['', Validators.required],
      onGoing: ['', Validators.required],
      screeningFailure: ['', Validators.required],
      terminated: ['', Validators.required],
      completed: ['', Validators.required],
      firstPatientIn: ['', Validators.required],
      lastPatientIn: ['', Validators.required],
      siteClosure: ['', Validators.required]

    });
    this.isAdd = true;
    
  }

  ngOnInit() {
   
  }
  close(): void {
    this.dialogRef.close();
  }
  submitForm = () => {
    console.log(this.form.valid);
    console.log(this.form.value, 'values');
  }

}







