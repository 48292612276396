import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as UAParser from "ua-parser-js";
import { DatePipe } from "@angular/common";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { SnackbarService } from "../toast/snackbar.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MMM-YYYY",
  },
  display: {
    dateInput: "DD-MMM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD-MMM-YYYY",
    monthYearA11yLabel: "MMM YYYY",
  },
};
@Component({
  selector: "app-cra-model",
  templateUrl: "./cra-model.component.html",
  styleUrls: ["./cra-model.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class CraModelComponent implements OnInit {
  craVerify = "";
  password = "";
  comments = "";
  userAgentDetails: any;
  docTitle = '';
  docDate = '';
  fileUploadFormData: File;
  maxDateLimit = new Date();
  userInfo: any;

  constructor(
    public dialogRef: MatDialogRef<CraModelComponent>,
    private datePipe: DatePipe,
    private toast: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public modalData: any
  ) {
    dialogRef.disableClose = true;
    let parser = new UAParser();
    this.userAgentDetails = parser.getResult();
  }

  ngOnInit(): void {
    console.log("ModalData:", this.modalData);
    this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
  }

  close() {
    this.dialogRef.close(false);
  }

  onFileSelected(fileUpEve, e) {
    if (!fileUpEve?.files) return;
    console.log(fileUpEve?.files);

    this.fileUploadFormData = fileUpEve.files[0];
    console.log("fileUploadFormData", this.fileUploadFormData);
    // this.fileUploadFormData;

    console.log(fileUpEve?.files);
  }

  onConfirmClick() {
    let info = JSON.parse(localStorage.getItem("USER_INFO"));
    let userid = info.tokenid;
    let email = info.username;
    if (this.modalData.type === 'cra') {
      if (this.password !== '' && this.craVerify !== '') {
        let Data = {
          id: this.modalData.data.id,
          browser: JSON.stringify(this.userAgentDetails),
          auditHistory: this.modalData.data.auditHistory,
          email: email,
          password: this.password,
          craVerificationStatus: parseInt(this.craVerify),
          craVerificationBy: userid,
        };
        this.dialogRef.close(Data);
      } else {
        this.toast.openSnackBar("Please fill the all Fields", true);
      }

    } else if (this.modalData.type === 'resolvedBy') {
      if (this.password !== '' && this.comments !== '') {
        let Data = {
          id: this.modalData.data.id,
          browser: JSON.stringify(this.userAgentDetails),
          email: email,
          password: this.password,
          resolutionComments: this.comments,
          ResolvedUserId: userid,
        }
        console.log("ResolvedModal:", Data);
        this.dialogRef.close(Data);
      } else {
        this.toast.openSnackBar('Please fill all mandatory Fields', true);
      }
    } else if (this.modalData.type === 'signatureVerify') {
      if (this.password !== '') {
        let Data = {
          id: this.modalData.data.id,
          browser: JSON.stringify(this.userAgentDetails),
          email: email,
          password: this.password,
          esignatureBy: userid,
        }
        console.log("signatureVerify:", Data);
        this.dialogRef.close(Data);
      } else {
        this.toast.openSnackBar("please fill all fields", true);
      }
    } else if (this.modalData.type === 'createSign') {
      if (this.docTitle !== '' && this.docDate !== '') {
        let Data = {
          id: this.modalData.data.id,
          projectId: this.modalData.data.projectId,
          categoryId: this.modalData.data.categoryId,
          subCategoryId: this.modalData.data.subCategoryId,
          contentTypeId: this.modalData.data.contentTypeId,
          subContentTypeId: this.modalData.data.subContentTypeId,
          esignatureDocumentTitle: this.docTitle,
          esignatureDocumentFileName: this.fileUploadFormData.name,
          esignatureDocumentLocation: '',
          esignatureDocumentDate: this.datePipe.transform(this.docDate, 'yyyy-MM-dd'),
          esignatureBy: userid,
          browser: JSON.stringify(this.userAgentDetails),
        }
        console.log("Data:", Data);
        this.dialogRef.close(Data);
      } else {
        this.toast.openSnackBar("Please fill all fields", true);
      }
    } else if (this.modalData.type === 'destructedBy') {
      if (this.password !== "") {
        let Data = {
          id: this.modalData.data.id,
          browser: JSON.stringify(this.userAgentDetails),
          email: email,
          password: this.password,
        }
        console.log("Data:", Data);
        this.dialogRef.close(Data);
      } else {
        this.toast.openSnackBar("Please fill all fields", true);
      }
    } else if (this.modalData.type === 'acknowledge') {
      if (this.password !== "") {
        let Data = {
          userId: this.userInfo.tokenid,
          browser: JSON.stringify(this.userAgentDetails),
          docUploadId: this.modalData.data.id,
          email: this.userInfo.username,
          password: this.password,
        }
        console.log("Acknowledge:", Data);
        this.dialogRef.close(Data);
      } else {
        this.toast.openSnackBar("Please fill all fields", true);
      }
    }
  }
}
