import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  remarks: string = ''
  constructor(public dialogRef: MatDialogRef<InfoModalComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  onConfirmClick(): void {
    if(this.modalData.type !== "displayMessage") 
      this.dialogRef.close(this.remarks);
    if(this.modalData.type === "displayMessage")
      this.dialogRef.close(true)
  }

  close() {
    this.dialogRef.close(false);
  }

}
