import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SiteWorkingService {
  apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  tmfUploadList(sponsorid: number, projectid: number, siteid: number) {
    const currEndPoint = this.apiUrl + `site-working-file/gettmfuploadlist/${sponsorid}/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }
  //  Common Pdf Api
  commondocuploadpdfdetails(id: number) {
    const currEndPoint = this.apiUrl + `site-working-file/getcommondocuploadpdfdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  // 174 PiUpdate
  update174siteteamackdetails(id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/update174siteteamackdetails/${id}`;
    return this.http.put(currEndPoint, data);
  }

  // PDF Details Api's
  get174PdfDetails(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/get174xpdfdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  get1833PdfDetails(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/get1833pdfdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  get1834PdfDetails(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/get1834pdfdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  categoriesByTitle(categoryName: { name: string }) {
    const currEndPoint = this.apiUrl + `category/isfcategorylist`;
    return this.http.post(currEndPoint, categoryName);
  }

  // 7x CategoryList
  categoryByTitle(categoryName: { name: string }) {
    const currEndPoint = this.apiUrl + `category/isfcategorynamelistonly `;
    return this.http.post(currEndPoint, categoryName);
  }

  getSubCategoriesByTitle(subCategoryName: { name: string }) {
    const currEndPoint = this.apiUrl + `category/subcategorylist`;
    return this.http.post(currEndPoint, subCategoryName);
  }

  getContentTypeByTitle(contentTypeName: { name: string }) {
    const currEndPoint = this.apiUrl + `category/isfcontenttypelist`;
    return this.http.post(currEndPoint, contentTypeName);
  }

  getSubContentTypeByTitle(subContentTypeName: { name: string }) {
    const currEndPoint = this.apiUrl + `category/subcontenttypelist`;
    return this.http.post(currEndPoint, subContentTypeName);
  }

  getIsfGlobalAuditHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contentid: number,
    subcontentid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/getisf1xglobalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contentid}/${subcontentid}`;
    return this.http.get(currEndPoint);
  }

  getDocumentTitleList(
    projectid: number,
    siteid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisfadddocumenttitle/${projectid}/${siteid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  getDocumentById(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/getdocumentupload/${id}`;
    return this.http.get(currEndPoint);
  }

  // 1.1.1
  getStudyTeamContactList(
    projectId: number,
    sponsorId: number,
    siteId: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get11studyteamlist/${projectId}/${sponsorId}/${siteId}`;
    return this.http.get(currEndPoint);
  }
  //  1.2 to 1.6
  get12to16List(
    sponsorid: number,
    projectid: number,
    siteid: number,
    category: number,
    subcategory: number,
    contenttype: number,
    subcontenttype: number,
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get12to16list/${sponsorid}/${projectid}/${siteid}/${category}/${subcategory}/${contenttype}/${subcontenttype}`;
    return this.http.get(currEndPoint);
  }

  delete11to16Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/delete11to16record/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  // 1.7.1
  get171DataById(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/get171patientscreening/${id}`;
    return this.http.get(currEndPoint);
  }

  get171GlobalAuditHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisf171globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  get171List(
    siteid: number,
    sponsorid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get171list/${siteid}/${sponsorid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getInvestigatorList(projectid: number, siteid: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/getinvestigatornames/${projectid}/${siteid}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }

  getIneligibleReasonList() {
    const currEndPoint =
      this.apiUrl + `site-working-file/get171ineligiblereasonlist`;
    return this.http.get(currEndPoint);
  }

  create171Record(data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/createpatientscreening`;
    return this.http.post(currEndPoint, data);
  }

  update171Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/updatepatientscreening/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  delete171Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/delete171record/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  download171Report(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/get171downloadreport`;
    return this.http.get(currEndPoint, data);
  }

  update171CRAVerify(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/update171craverification`;
    return this.http.post(currEndPoint, data);
  }

  // 1.7.2
  get172List(
    siteid: number,
    sponsorid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get172list/${siteid}/${sponsorid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  get172GlobalAuditHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisf172globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  get172DataById(id: number) {
    const currEndPoint = this.apiUrl + `site-working-file/get172informedconsentlog/${id}`;
    return this.http.get(currEndPoint);
  }

  delete172Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/delete172record/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  download172Report(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/get172downloadreport`;
    return this.http.get(currEndPoint, data);
  }

  create172Record(data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/createinformedconsentlog`;
    return this.http.post(currEndPoint, data);
  }

  update172Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/updateinformedconsentlog/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  get172LanguageList() {
    const currEndPoint = this.apiUrl + `site-working-file/get172languagelist`;
    return this.http.get(currEndPoint);
  }

  update172CRAVerify(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/update172craverification`;
    return this.http.post(currEndPoint, data);
  }

  // 1.7.3
  get173List(
    sponsorid: number,
    projectid: number,
    siteid: number,
    category: number,
    subcategory: number,
    contenttype: number,
    subcontenttype: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get173list/${sponsorid}/${projectid}/${siteid}/${category}/${subcategory}/${contenttype}/${subcontenttype}`;
    return this.http.get(currEndPoint);
  }

  create173Record(data: any) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/create173sitepersonneldoc
    `;
    return this.http.post(currEndPoint, data);
  }

  update173Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/update173sitepersonneldoc/${Id}
  `;
    return this.http.put(currEndPoint, data);
  }

  delete173Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/delete173record/${Id}
  `;
    return this.http.put(currEndPoint, data);
  }

  // 1.7.4
  get174List(
    sponsorid: number,
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get174list/${sponsorid}/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  get174DataById(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/getsitepersonneltraining174/${id}`;
    return this.http.get(currEndPoint);
  }

  get174GlobalAuditHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisf174globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  get174DocumentTitleList(
    projectid: number,
    siteid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisfadddocumenttitle174/${projectid}/${siteid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  modeOfTrainingList() {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get174traininglist
    `;
    return this.http.get(currEndPoint);
  }
  getTopicList() {
    const currEndPoint = this.apiUrl + `site-working-file/get174topiclist`;
    return this.http.get(currEndPoint);
  }

  trainerDropDownList(projectid: number, siteid: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get174trainerlist/${projectid}/${siteid}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }

  traineeList(projectid: number, siteid: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get174traineelist/${projectid}/${siteid}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }

  create174Record(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/create174doc`;
    return this.http.post(currEndPoint, data);
  }
  update174Record(id: number, data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/update174doc/${id}`;
    return this.http.put(currEndPoint, data);
  }
  delete174Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/delete174record
    /${Id}`;
    return this.http.put(currEndPoint, data);
  }

  get174PiDetails(id: any, resourceid: any) {
    const currEndPoint = this.apiUrl + `site-working-file/getpisiteteamackdetails/${id}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  update174CraVerification(data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/update174craverification`;
    return this.http.post(currEndPoint, data);
  }

  // 1.7.5 - 1.7.7
  get175To177TableList(
    sponsorid: number,
    projectid: number,
    siteid: number,
    category: number,
    subcategory: number,
    contenttype: number,
    subcontenttype: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get173list/${sponsorid}/${projectid}/${siteid}/${category}/${subcategory}/${contenttype}/${subcontenttype}`;
    return this.http.get(currEndPoint);
  }

  delete175To177TableRecord(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/delete173record/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  create175To177TableRecord(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/create175to177doc`;
    return this.http.post(currEndPoint, data);
  }

  update175To177TableRecord(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/update175to177doc/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  // 1.7.8
  get178DataById(id: any) {
    const currEndPoint = this.apiUrl + `site-working-file/getdocumentupload/${id}`;
    return this.http.get(currEndPoint);
  }

  // 1.8x
  get18xList(
    sponsorid: number,
    projectId: number,
    siteId: number,
    categoryId: number,
    subcategoryId: number,
    contenttype: number,
    subcontenttype: number,
  ) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get18xlist/${sponsorid}/${projectId}/${siteId}/${categoryId}/${subcategoryId}/${contenttype}/${subcontenttype}`;
    return this.http.get(currEndPoint);
  }

  get1833DataById(id: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/getdocumentupload/${id}`;
    return this.http.get(currEndPoint);
  }

  create1833Record(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/create1833Log`;
    return this.http.post(currEndPoint, data);
  }

  update1833Record(Id: number, data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/update1833Log/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  update1833Cra(data: any) {
    const currEndPoint =
      this.apiUrl + `study-reference-file/update2xcraverification`;
    return this.http.post(currEndPoint, data);
  }

  getVisitorNameList(projectId: number, siteId: number, sponsorId: number) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get1833visitorlist/${projectId}/${siteId}/${sponsorId}`;
    return this.http.get(currEndPoint);
  }

  getPurposeOfVisitList() {
    const currEndPoint =
      this.apiUrl + `site-working-file/get1833purposeofvisitlist`;
    return this.http.get(currEndPoint);
  }

  get1834List(siteId: number, sponsorId: number, projectId: number) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get1834list/${siteId}/${sponsorId}/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getMemberList(projectId: number, siteId: number) {
    const currEndPoint =
      this.apiUrl +
      `site-working-file/get1834memberlist/${projectId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getCategoryList() {
    const currEndPoint =
      this.apiUrl + `site-working-file/get1834monitoringissuelist`;
    return this.http.get(currEndPoint);
  }

  delete1833Record(Id: number, data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/delete173record/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  verifySignature(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/signatureverification`;
    return this.http.post(currEndPoint, data);
  }

  createESignature(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/create1833esignature`;
    return this.http.post(currEndPoint, data);
  }

  get1834GlobalHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisf1834globalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  // 1.8.3.4
  get1834DataById(id: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/get1834issuelog/${id}`;
    return this.http.get(currEndPoint);
  }

  get1834DocumentTitleList(
    projectid: number,
    siteid: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number,
  ) {
    const currEndPoint = this.apiUrl + `site-working-file/getisfadddocumenttitle1834/${projectid}/${siteid}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  create1834Record(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/create1834Log`;
    return this.http.post(currEndPoint, data);
  }

  update1834Record(Id: number, data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/update1834Log/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  update1834Cra(data: any) {
    const currEndPoint =
      this.apiUrl + `site-working-file/update1834craverification`;
    return this.http.post(currEndPoint, data);
  }

  delete1834xRecord(Id: number, data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/delete1834log/${Id}`;
    return this.http.put(currEndPoint, data);
  }

  update1834memberverification(data: any) {
    const currEndPoint = this.apiUrl + `site-working-file/update1834memberverification`;
    return this.http.post(currEndPoint, data);
  }
}