import {
  Component,
  OnInit,
  Input,
  Output,
  ViewEncapsulation,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { DataTransferService } from "../../../../shared/services/data-transfer.service";
import { AppSettings } from "../../../../app.settings";
import { Settings } from "../../../../app.settings.model";
import { Menu } from "../menu.model";
import { MenuService } from "../menu.service";

@Component({
  selector: "app-vertical-menu",
  templateUrl: "./vertical-menu.component.html",
  styleUrls: ["./vertical-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class VerticalMenuComponent implements OnInit, OnChanges {
  @Input("menuItems") menuItems: Menu[];
  @Input("menuParentId") menuParentId: number;
  @Output() onClickMenuItem: EventEmitter<any> = new EventEmitter<any>();
  parentMenu: Array<any>;
  public settings: Settings;
  activeMenuType: string = "";
  activeMenuTitle: string = "";

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    public router: Router,
    public route: ActivatedRoute,
    private dataTransferService: DataTransferService
  ) {
    this.settings = this.appSettings.settings;
    if (!localStorage.getItem('menuItems')) {
      let cc = localStorage.setItem('menuItems', JSON.stringify(this.menuItems));
      console.log(cc)
    }
  }

  ngOnInit() {
    // console.log(this.route, this.router);
    this.route.queryParams.subscribe((res) => {
      // console.log(131313,res);
      if (res.menuId) {
        this.onClick(res.menuId)
      }
    })
    this.route.params.subscribe((res) => {
      // console.log(121212121,res);
    })
  }

  ngOnChanges() {
    if (this.menuItems) {
      this.parentMenu = this.menuItems.filter(
        (item) => item.parentId == this.menuParentId
      );
    }
    // console.log("ngOnChanges", this.menuItems);
    // console.log("ParentId", this.menuParentId);
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let url = this.router.url.split('/').pop();
        if (url !== 'dashboard') {
          this.route.queryParams.subscribe((p: any) => {
            console.log('MenuId', p.menuId);
            if (p.menuId) {
              this.onClick(parseInt(p.menuId))
            }
          })
        }
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById("main-content");
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName(
            "mat-drawer-content"
          )[0].scrollTop = 0;
        }
      }
    });
  }

  getMenuTitle() {
    console.log("MenuTitle Running!");
    let Item = this.menuItems.find((el) => {
      if (el.title !== undefined) {
        let t = el.title.split("-")[1];
        console.log(t.toLowerCase());
      }
    })
  }

  onClick(menuId: number) {

    this.onClickMenuItem.emit(menuId);
    let title = this.menuItems.find(
      (item) =>
        item.menuId === menuId
    )?.title;
    if (typeof title !== undefined) this.activeMenuTitle = title;
    // determining the type of menuItem clicked - whether its category, sub_category, content_type, sub_content_type
    const isParentIdZero = (currMenuId: number) => this.menuItems.find((item) => (item.menuId == currMenuId && item.parentId == 0))

    if (isParentIdZero(menuId)) this.activeMenuType = "category";

    if (!isParentIdZero(menuId)) {
      let currMenuParentId: any = [...this.menuItems].filter(
        (item) => { return item.menuId == menuId }
      )[0] || null;
      currMenuParentId = currMenuParentId ? currMenuParentId.parentId : "none";
      // const parentMenu = this.menuItems.find(
      //   (item) => item.menuId == currMenuParentId
      // );
      let parentMenu: any = [...this.menuItems].filter(
        (item) => { return item.menuId == currMenuParentId }
      )[0] || {};
      if (isParentIdZero(parentMenu.menuId)) {
        this.activeMenuType = "sub_category";
      } else if (!isParentIdZero(parentMenu.menuId)) {
        let grandParentId: any = [...this.menuItems].filter(
          (item) => { return item.menuId == parentMenu.menuId }
        )[0] || null;
        // console.log(grandParentId);
        grandParentId = grandParentId ? grandParentId.parentId : "none";
        // const grandParentMenu = this.menuItems.find(
        //   (item) => item.menuId == grandParentId
        // );

        let grandParentMenu: any = [...this.menuItems].filter(
          (item) => { return item.menuId == grandParentId }
        )[0] || {};
        if (isParentIdZero(grandParentMenu.menuId)) {
          this.activeMenuType = "content_type";
        } else {
          this.activeMenuType = "sub_content_type";
        }
      }
    }

    sessionStorage.setItem(
      "activeISFMenuData",
      JSON.stringify({
        menuType: this.activeMenuType,
        menuTitle: this.activeMenuTitle,
      })
    );

    if (!this.menuItems.find((item) => item.menuId == menuId)?.hasSubMenu) {
      this.dataTransferService.updateActiveMenuItemData({
        menuType: this.activeMenuType,
        menuTitle: this.activeMenuTitle,
      });
    }

    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
    this.menuService.toggleMenuItem(menuId, this.menuItems);
  }
}