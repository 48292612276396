import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddSiteDashboardComponent } from './add-site-dashboard/add-site-dashboard.component';

@Component({
  selector: 'app-site-dashboard',
  templateUrl: './site-dashboard.component.html',
  styleUrls: ['./site-dashboard.component.scss']
})
export class SiteDashboardComponent implements OnInit {
  public todoList:Array<any>;
  constructor(public dialog: MatDialog) {
    this.todoList = [
      { text: 'Check me out' },
      { text: 'Curabitur dignissim nunc a tellus euismod, quis pretium ipsum convallis'},
      { text: 'Vivamus dapibus pulvinar ipsum, sit amet elementum sapien tincidunt non'},
      { text: 'Praesent viverra nisl a pharetra viverra'},
      { text: 'Lorem ipsum dolor sit amet, possit denique oportere at his, etiam corpora deseruisse te pro' },
      { text: 'Ex has semper alterum, expetenda dignissim' },
      { text: 'Nulla nisl urna, lobortis in leo vel, porta faucibus nulla'},
      { text: 'Simul erroribus ad usu' },
      { text: 'Nulla nisl urna, lobortis in leo vel, porta faucibus nulla'},
      { text: 'Simul erroribus ad usu' }
    ];
   }

  ngOnInit(): void {
  }

  public openDialog(type,data){
    let tableData = [];
    if(data) data.id = 1;
    console.log(data, 'data');

    
    let dialogRef = this.dialog.open(AddSiteDashboardComponent, {
        data: {type: type, data: data},
        width: '800px'
      });

    dialogRef.afterClosed().subscribe(data => {
        if(data){
          (data.id) ? this.updateProject(data) : this.addProject(data);
        }
    });
  }
  addProject = (data) => {

  }
  updateProject = (data) => {
    
  }


}
