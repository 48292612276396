import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';

@Component({
  selector: 'app-loadingdashboard',
  templateUrl: './loadingdashboard.component.html',
  styleUrls: ['./loadingdashboard.component.scss']
})
export class LoadingdashboardComponent implements OnInit {


  public settings: Settings;
  constructor (  
    public appSettings:AppSettings, 
  private spinner: NgxSpinnerService) {  
  }

 
  ngOnInit() { 
    this.settings = this.appSettings.settings;
    console.log("============",this.settings)
    // this.settings.loadingSpinner = false
    this.spinner.show();
  }
}
