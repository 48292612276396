import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ProjectMappingService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) { }

  getProjectsByUser(userid: number) {
    const currEndPoint = this.apiUrl + `users/getallprojects/${userid}`;
    return this.http.get(currEndPoint);
  }

  forgotPassword(data: any) {
    const currEndPoint = this.apiUrl + `users/forgetpassword`;
    return this.http.post(currEndPoint, data);
  }

  resetPassword(data: any) {
    const currEndPoint = this.apiUrl + `users/confirmpassword`;
    return this.http.post(currEndPoint, data);
  }

  getproteammapwithdiffrole(projectid: number, roleid: number, resourceId: number) {
    const currEndPoint = this.apiUrl + `project/getproteammapwithdiffrole/${projectid}/${roleid}/${resourceId} `;
    return this.http.get(currEndPoint);
  }

  // tmfProjectByUserId
  getTmfProjectByUserId(userid: number) {
    const currEndPoint = this.apiUrl + `users/gettmfprojectbyuserid/${userid}`;
    return this.http.get(currEndPoint);
  }

  // isfProjectByUserId
  getIsfSiteByUserId(userid: number) {
    const currEndPoint = this.apiUrl + `users/getisfsitebyuserid/${userid}`;
    return this.http.get(currEndPoint);
  }

  getIsfProjectBySiteId(siteid: number, userid: number) {
    const currEndPoint = this.apiUrl + `users/getisfprojectbysiteid/${siteid}/${userid}`;
    return this.http.get(currEndPoint);
  }

  // create Project endPoints
  getAllProjects() {
    const currEndPoint = this.apiUrl + "project/getallproject";
    return this.http.get(currEndPoint);
  }

  getProjectsBySponsorId(sponsorId: number) {
    const currEndPoint =
      this.apiUrl + `project/getprojectsbysponsorid/${sponsorId}`;
    return this.http.get(currEndPoint);
  }

  getProjectById(projectId: number) {
    const currEndPoint = this.apiUrl + `project/getproject/${projectId}`;
    return this.http.get(currEndPoint);
  }

  addNewProject(data: any) {
    const currEndPoint = this.apiUrl + "project/createproject";
    return this.http.post(currEndPoint, data);
  }

  updateProject(projectId: number, data: any) {
    const currEndPoint = this.apiUrl + `project/updateproject/${projectId}`;
    return this.http.put(currEndPoint, data);
  }

  deleteProject(projectId: number) {
    const currEndPoint = this.apiUrl + `project/deleteproject/${projectId}`;
    return this.http.delete(currEndPoint);
  }

  // active project/sponsor/CRO/site end points
  getAllActiveSponsors() {
    const currEndPoint = this.apiUrl + `project/getactivesponsors`;
    return this.http.get(currEndPoint);
  }

  getAllActiveCROs() {
    const currEndPoint = this.apiUrl + `project/getactivecros`;
    return this.http.get(currEndPoint);
  }

  getAllActiveProjects() {
    const currEndPoint = this.apiUrl + `project/getactiveprojects`;
    return this.http.get(currEndPoint);
  }

  // fetching all activeSites & projectsBySiteId meant for use at site dropdown in dashboard page

  getISFDashboardDropdownSites() {
    const currEndPoint = this.apiUrl + `project/getisfsitedashboardlist`;
    return this.http.get(currEndPoint);
  }

  getProjectsBySiteId(siteId: number) {
    const currEndPoint =
      this.apiUrl + `project/getisfprojectdashboardlist/${siteId}`;
    return this.http.get(currEndPoint);
  }

  // Site Mapping endPoints
  getAllSiteMappings(projectid: number, croid: number) {
    const currEndPoint = this.apiUrl + `project/getsite/${projectid}/${croid}`;
    return this.http.get(currEndPoint);
  }

  getAllSitesByCountryId(countryId: number) {
    const currEndPoint = this.apiUrl + `project/getallsites/${countryId}`;
    return this.http.get(currEndPoint);
  }

  addNewSiteMapping(data: any) {
    const currEndPoint = this.apiUrl + "project/createcrosite";
    return this.http.post(currEndPoint, data);
  }

  updateSiteMapping(siteid: number, data: any) {
    const currEndPoint = this.apiUrl + `project/updatecrosite/${siteid}`;
    return this.http.put(currEndPoint, data);
  }

  deleteSiteMapping(projectCroSiteId: number) {
    const currEndPoint =
      this.apiUrl + `project/deletesitemapping/${projectCroSiteId}`;
    return this.http.delete(currEndPoint);
  }

  // Resource Mapping endPoints

  getResourceMapProjects() {
    const currEndPoint = this.apiUrl + `project/getrmprojects`;
    return this.http.get(currEndPoint);
  }

  getResourceMappingSponsors(projectId: number) {
    const currEndPoint = this.apiUrl + `project/getrmsponsor/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getResourceMappingCROs(projectId: number) {
    const currEndPoint = this.apiUrl + `project/getrmcros/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getResourceMappingSites(projectId: number, croId: number) {
    const currEndPoint =
      this.apiUrl + `project/getrmsites/${projectId}/${croId}`;
    return this.http.get(currEndPoint);
  }

  getRMCROResources() {
    const currEndPoint = this.apiUrl + `project/getrmcroresources`;
    return this.http.get(currEndPoint);
  }

  getRMSiteResources() {
    const currEndPoint = this.apiUrl + `project/getrmsiteresources`;
    return this.http.get(currEndPoint);
  }

  getRMSponsorResources() {
    const currEndPoint = this.apiUrl + `project/getrmsponsorresources`;
    return this.http.get(currEndPoint);
  }

  getRMCRORoles() {
    const currEndPoint = this.apiUrl + `project/getrmcroroles`;
    return this.http.get(currEndPoint);
  }

  getRMSponsorRoles() {
    const currEndPoint = this.apiUrl + `project/getrmsponsorroles`;
    return this.http.get(currEndPoint);
  }

  getRMSiteRoles() {
    const currEndPoint = this.apiUrl + `project/getrmsiteroles`;
    return this.http.get(currEndPoint);
  }

  getRMCROList(projectId: number) {
    const currEndPoint = this.apiUrl + `project/getrmcrolist/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getRMSponsorList(projectId: number) {
    const currEndPoint = this.apiUrl + `project/getrmsponsorlist/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getRMSiteList(projectId: number, croId: number, siteId: number) {
    const currEndPoint =
      this.apiUrl + `project/getrmlist/${projectId}/${croId}/${siteId}`;
    return this.http.get(currEndPoint);
  }

  getRMCROCountriesList(projectId: number, croId: number) {
    const currEndPoint =
      this.apiUrl + `project/getrmcountries/${projectId}/${croId}`;
    return this.http.get(currEndPoint);
  }

  getRMSponsorCountriesList(projectId: number, sponsorid: number) {
    const currEndPoint =
      this.apiUrl + `project/getrmcountrylist/${projectId}/${sponsorid}`;
    return this.http.get(currEndPoint);
  }

  getRMCountryWiseSites(
    countries: number[],
    projectId: number,
    resourceTypeId: number,
    mappingType: string
  ) {
    const currEndPoint =
      this.apiUrl +
      `project/getrmsitebycountries/${projectId}/${resourceTypeId}/${mappingType}`;
    return this.http.post(currEndPoint, countries);
  }

  getAllResources(
    projectid: number,
    type: string,
    croid: number,
    siteid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `project/getresource/${projectid}/${type}/${croid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  addNewResourceMapping(data: any) {
    const currEndPoint = this.apiUrl + "project/createsiteteam";
    return this.http.post(currEndPoint, data);
  }

  updateResourceMapping(projectId: number, data: any) {
    const currEndPoint = this.apiUrl + "";
    return this.http.put(currEndPoint, data);
  }

  deleteResourceMapping(projectSiteTeamId: number) {
    const currEndPoint =
      this.apiUrl + `project/deleteresourcemapping/${projectSiteTeamId}`;
    return this.http.delete(currEndPoint);
  }

  // Drug Mapping endPoints

  getAllDrugs(projectid: number, croid: number) {
    const currEndPoint =
      this.apiUrl + `project/getcrodrug/${projectid}/${croid}`;
    return this.http.get(currEndPoint);
  }

  getDrugMappingById(drugId: number) {
    const currEndPoint = this.apiUrl + `project/getdrug/${drugId}`;
    return this.http.get(currEndPoint);
  }

  addNewDrugMapping(data: any) {
    const currEndPoint = this.apiUrl + "project/createdrug";
    return this.http.post(currEndPoint, data);
  }

  updateDrugMapping(projectCroSiteMasterId: number, data: any) {
    const currEndPoint =
      this.apiUrl + `project/updatecrodrug/${projectCroSiteMasterId}`;
    return this.http.put(currEndPoint, data);
  }

  deleteDrugMapping(drugId: number) {
    const currEndPoint = this.apiUrl + `project/deletedrugmapping/${drugId}`;
    return this.http.delete(currEndPoint);
  }

  getAllRoles() {
    const currEndPoint = this.apiUrl + "project/getallrole";
    return this.http.get(currEndPoint);
  }


  // getRoleByUserId

  getRoleForTmfByUserId(userid: number, projectid: number) {
    const currEndPoint = this.apiUrl + `users/getrolefortmfbyuserid/${userid}/${projectid}`;
    return this.http.get(currEndPoint);
  }

  getRoleForIsfByUserId(userid: number, siteid: number, projectid: number) {
    const currEndPoint = this.apiUrl + `users/getroleforisfbyuserid/${userid}/${siteid}/${projectid}`;
    return this.http.get(currEndPoint);
  }
}
