import { Injectable } from '@angular/core'
import { HttpClient, HttpEvent, HttpRequest, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http'
import { Observable, BehaviorSubject } from 'rxjs'
import { environment } from '../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class S3FileOperationService {

  private DocumentInfo = new BehaviorSubject<number>(0)

  fileName = this.DocumentInfo.asObservable()

  constructor(private http: HttpClient) {}
 
  getFiles(): Observable<any> {
    return this.http.get(environment.apiUrl + 'amazon/api/files')
  }

  downloadS3FileByKey(key: string): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders().set('Accept', 'application/pdf')
    //Excel - application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8
    return this.http.get(environment.apiUrl + 'amazon/api/file/' + key, {
      headers: headers,
      observe: 'response',
      responseType: 'blob',
    })
  }

  getPdfDocument(key: string): Observable<any> {
    var headers_object = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'))

    // Initialize Params Object
    let params = new HttpParams()
    // Begin assigning parameters
    params = params.append('keyname', key)
    return this.http.get(environment.apiUrl + 'amazon/api/file/download', {
      params: params,
      headers: headers_object,
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
    })
  }

  getImageDocument(key: string): Observable<any> {
    var headers_object = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('token'))

    // Initialize Params Object
    let params = new HttpParams()
    // Begin assigning parameters
    params = params.append('keyname', key)
    return this.http.get(environment.apiUrl + 'amazon/api/file/downloadimage', {
      params: params,
      headers: headers_object,
      responseType: 'blob' as 'json',
      observe: 'response' as 'body',
    })
  }

  getPdfURLDocument(key: string): Observable<any> {
    let params = new HttpParams().set('keyname', key)
    return this.http.get(environment.apiUrl + 'amazon/api/file/downloadurl', { params })
  }
 
  setSrc(path) {
    console.log(path)
    this.DocumentInfo.next(path)
  }

  getUnSecSignedFile(body): Observable<any> { 
    const fileUrl = body.fileUrl
    const relativePath : any = fileUrl.substring(fileUrl.indexOf('gravity'), fileUrl.length);
    console.log('---fileUrl : ' + relativePath)
    const file = { fileUrl : relativePath }
    return this.http.post(environment.apiUrl + 'aws/unsecure/file', file)
  }
}
