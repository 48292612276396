import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TrialManagementService {
  apiUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getTrialManagementDetails(projectId: number) {
    const currEndPoint =
      this.apiUrl + `trial-management/gettmfdetails/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getTrialManageDocsStatusList(
    projectId: number,
    categoryid: number = 0,
    subcategoryid: number = 0,
    contentTypeId: number = 0,
    subContentTypeId: number = 0
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettmfadddocumenttitle/${projectId}/${categoryid}/${subcategoryid}/${contentTypeId}/${subContentTypeId}`;
    return this.http.get(currEndPoint);
  }

  getTrialManageDocsFileNoteList(
    projectId: number,
    categoryid: number = 0,
    subcategoryid: number = 0,
    contentTypeId: number = 0,
    subContentTypeId: number = 0
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettmffilenotedocumenttitle/${projectId}/${categoryid}/${subcategoryid}/${contentTypeId}/${subContentTypeId}`;
    return this.http.get(currEndPoint);
  }

  getTrialManagementDocsList(
    projectId: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/getdocumentlist/${projectId}/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  addTrialManagementDocs(trialManagmentDocDetails: any) {
    const currEndPoint = this.apiUrl + `trial-management/createnewdoc`;
    return this.http.post(currEndPoint, trialManagmentDocDetails);
  }

  addTrialManagementExistingDocs(trialManagmentDocDetails: any) {
    const currEndPoint = this.apiUrl + `trial-management/createexistdoc`;
    return this.http.post(currEndPoint, trialManagmentDocDetails);
  }

  getTMF1XGlobalAuditHistory(
    projectId: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subContentTypeId
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettmf1xglobalaudithistory/${projectId}/${categoryid}/${subcategoryid}/${contenttypeid}/${subContentTypeId}`;
    return this.http.get(currEndPoint);
  }

  getTMF1XAuditTrialDetailsPDF(documentId: number) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettmf1xpdfaudittraildetails/${documentId}`;
    return this.http.get(currEndPoint);
  }

  getTrialManagementDocById(documentId) {
    const currEndPoint =
      this.apiUrl + `trial-management/gettmftrialdocumentbyid/${documentId}`;
    return this.http.get(currEndPoint);
  }

  updateTrialManagementDoc(documentId, editedData) {
    const currEndPoint = this.apiUrl + `trial-management/update/${documentId}`;
    return this.http.put(currEndPoint, editedData);
  }

  deleteTrialManagementDoc(documentId: number, editedData) {
    const currEndPoint =
      this.apiUrl + `trial-management/deletedocument/${documentId}`;
    return this.http.put(currEndPoint, editedData);
  }

  // 1.2.1 - trialTeam members

  // endPoint to perform check whether to allow user to add
  getTrialTeamDetails(projectId: number) {
    const currEndPoint =
      this.apiUrl + `trial-management/gettmftrialteamdetail/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getResourceForTrialTeam(projectId: number) {
    const currEndPoint =
      this.apiUrl + `trial-management/getresourcetrialteam/${projectId}`;
    return this.http.get(currEndPoint);
  }

  getRoleForTrialTeam(projectId: number, resourceId: number) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/getroletrialteam/${projectId}/${resourceId}`;
    return this.http.get(currEndPoint);
  }

  getTrialAssociatedCountries(
    projectId: number,
    resourceId: number,
    roleId: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettrialassociatedcountries/${projectId}/${resourceId}/${roleId}`;
    return this.http.get(currEndPoint);
  }

  getTrialAssociatedSites(
    projectId: number,
    resourceId: number,
    roleId: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettrialassociatedsites/${projectId}/${resourceId}/${roleId}`;
    return this.http.get(currEndPoint);
  }

  // 1.2.1 - list api
  getAllDocTrialResProject(projectId: number) {
    const currEndPoint =
      this.apiUrl + `trial-management/getalldoctrialresproject/${projectId}`;
    return this.http.get(currEndPoint);
  }

  addTrialTeamMemberCV(trialTeamMemberCV: any) {
    const currEndPoint = this.apiUrl + `trial-management/createnewresourcedoc`;
    return this.http.post(currEndPoint, trialTeamMemberCV);
  }

  getTMF121AuditTrialDetailsPDF(documentId: number) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettmf121pdfaudittraildetails/${documentId}`;
    return this.http.get(currEndPoint);
  }

  getTMF121GlobalAuditHistory(
    projectId: number,
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subContentTypeId
  ) {
    const currEndPoint =
      this.apiUrl +
      `trial-management/gettmf121globalaudithistory/${projectId}/${categoryid}/${subcategoryid}/${contenttypeid}/${subContentTypeId}`;
    return this.http.get(currEndPoint);
  }

  getTrialTeamResourceById(resourceId: number) {
    const currEndPoint =
      this.apiUrl + `trial-management/gettrialteamresource/${resourceId}`;
    return this.http.get(currEndPoint);
  }

  updateTrialTeamResourceCV(resourceId: number, trialTeamMemberCV: any) {
    const currEndPoint =
      this.apiUrl + `trial-management/updateresourcedoc/${resourceId}`;
    return this.http.put(currEndPoint, trialTeamMemberCV);
  }

  deleteTrialTeamMemberCV(cvId: number, deleteObj: any) {
    const currEndPoint =
      this.apiUrl + `trial-management/deleteresourcedocument/${cvId}`;
    return this.http.put(currEndPoint, deleteObj);
  }

  // 2x - endPoints
  add2XProdandTrialDoc(trialTeamMemberCV: any) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/createprodtrialdoc2x`;
    return this.http.post(currEndPoint, trialTeamMemberCV);
  }

  getTMF2XAssociatedCountries(projectId: number) {
    const currEndPoint =
      this.apiUrl +
      `cent-reg-irbiec-234x/gettmf2xassociatedcountries/${projectId}/`;
    return this.http.get(currEndPoint);
  }

  getTMF2XAssociatedSites(associatedSiteCountry: {
    projectId: number;
    countries: number[];
  }) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/gettmf2xassociatedsites`;
    return this.http.post(currEndPoint, associatedSiteCountry);
  }

  getTMF2XCentrialDoclist(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contentid: number,
    subcontentid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `cent-reg-irbiec-234x/getcentrialdoclist2x/${projectid}/${categoryid}/${subcategoryid}/${contentid}/${subcontentid}`;
    return this.http.get(currEndPoint);
  }

  get2xdocuploadprojectsById(documentId: number) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/get2xdocuploadprojects/${documentId}`;
    return this.http.get(currEndPoint);
  }

  update2xResourceDoc(documentId: number, editedData: any) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/update2xresourcedoc/${documentId}`;
    return this.http.put(currEndPoint, editedData);
  }

  delete2xDocument(documentId: number, deletedData: any) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/delete2xdocument/${documentId}`;
    return this.http.put(currEndPoint, deletedData);
  }

  get2xSendtoSiteDetails(projectId: number) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/get2xsendtositedetails/${projectId}`;
    return this.http.get(currEndPoint);
  }

  get2xCraDetails(projectId: number) {
    const currEndPoint =
      this.apiUrl + `cent-reg-irbiec-234x/get2xcradetails/${projectId}`;
    return this.http.get(currEndPoint);
  }

  get2xPISiteDetails(document_upload_id: number) {
    const currEndPoint =
      this.apiUrl +
      `cent-reg-irbiec-234x/get2xpisitedetails/${document_upload_id}`;
    return this.http.get(currEndPoint);
  }
}
