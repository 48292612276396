import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

import { Menu } from "./menu.model";
import {
  // verticalProjectMenuItems,l
  // verticalSiteMenuItems1,
  horizontalMenuItems,
  // reportMenuItems,
} from "./menu";

@Injectable()
export class MenuService {
  siteMenus: any;

  constructor(private location: Location, private router: Router) { }

  // public getVerticalMenuItems(): Array<Menu> {
  //   return reportMenuItems;
  // }

  public getHorizontalMenuItems(): Array<Menu> {
    return horizontalMenuItems;
  }

  public expandActiveSubMenu(menu: Array<Menu>) {
    let url = this.location.path();
    let routerLink = url; // url.substring(1, url.length);
    let activeMenuItem = menu.filter((item) => item.routerLink === routerLink);
    if (activeMenuItem[0]) {
      let menuItem = activeMenuItem[0];
      while (menuItem.parentId != 0) {
        let parentMenuItem = menu.filter(
          (item) => item.menuId == menuItem.parentId
        )[0];
        menuItem = parentMenuItem;
        this.toggleMenuItem(menuItem.menuId, false);
      }
    }
  }

  public populateSubmenu(menuId: any, menus: any) {
    let parentId = menus.find((el: any) => el.menuId === parentId).parentId;
    let _menuItem = document.getElementById("menu-item-" + parentId);
    let _subMenu = document.getElementById("sub-menu-" + parentId);
    console.log("Submenu:", _subMenu);
    console.log(" _menuItem.classList:", _menuItem.classList);
    if (_subMenu) {
      _subMenu.classList.add("show");
      _menuItem.classList.add("expanded");
    }
    this.populateSubmenu(parentId, menus);

  }

  public toggleMenuItem(menuId, menuList: any = false) {
    let menus = (menuList) ? menuList : JSON.parse(localStorage.getItem('menuItems'))
    var urlQuery = (function () {
      var querysearch = String(window.location.search).replace("?", "").split("&");
      var query = {};
      for (var qs in querysearch) {
        var qso = String(querysearch[qs]).split("=");
        query[qso[0]] = qso[1] || "none";
      }
      return query;
    })()
    let getparent = function ({ menuref, menuId, match, parent, flow, level }) {
      for (var gp in menus) {
        if (menus[gp][menuref] == menuId) {
          match[menuId] = { ...menus[gp], _parent: (level) ? parent : !parseInt(menus[gp].parentId) };
          level++;
          if (flow == "parent" && menus[gp].parentId != "0") {
            match = getparent({ menuref: "menuId", menuId: menus[gp].parentId, match, parent: true, flow: "parent", level })
          }
        }
      }
      return match
    }
    let onParent = getparent({
      menuId, menuref: "menuId", match: {}, parent: false, flow: "parent", level: 0
    })
    let onChild = getparent({
      menuId, menuref: "menuId", match: {}, parent: false, flow: "child", level: 0
    })
    if (Object.keys(onParent).length > 1) {
      var lastmenu = onParent[urlQuery["menuId"]] || {};
      // console.log("i am url id of menu",lastmenu, urlQuery);
      for (var op in onParent) {
        let menuItem, subMenu;
        var opo = onParent[op];
        // console.log("on parent item", opo, opo.parentId);
        if (opo.hasSubMenu) {
          // console.log("Submenu")
          menuItem = document.getElementById("menu-item-" + opo.menuId);
          subMenu = document.getElementById("sub-menu-" + opo.menuId);
          // console.log("Submenu", subMenu);
          // console.log("Menuitem", menuItem);
          if (menuItem) {
            if (opo.menuId != menuId) {
              // var mieve = menuItem.classList.contains("expanded")?"remove":"add";
              // menuItem.classList[mieve]("expanded"); 
            } else {
              menuItem.classList.add("expanded")
            }
          }
          if (subMenu) {
            if (onChild[opo.menuId]) {
              var mieve = subMenu.classList.contains("show") ? "remove" : "add";
              subMenu.classList[mieve]("show");
            } else {
              subMenu.classList.add("show")
            }
          }
        }
      }
    } else {
      for (var op in onChild) {
        let menuItem: any, subMenu: any;
        var opo = onChild[op];
        menuItem = document.getElementById("menu-item-" + opo.menuId);
        subMenu = document.getElementById("sub-menu-" + opo.menuId);
        // console.log(menuItem, subMenu);
        if (menuItem) {
          var mieve = menuItem.classList.contains("expanded") ? "remove" : "add";
          menuItem.classList[mieve]("expanded");
          // menuItem.classList.add("expanded")
        }
        if (subMenu) {
          var mieve = subMenu.classList.contains("show") ? "remove" : "add";
          subMenu.classList[mieve]("show");
          // subMenu.classList.add("show")
        }
      }
    }
  }

  public closeOtherSubMenus(menu: Array<Menu>, menuId) {
    let currentMenuItem = menu.filter((item) => item.menuId == menuId)[0];
    if (typeof currentMenuItem?.parentId !== undefined) {
      if (currentMenuItem?.parentId == 0 && !currentMenuItem.target) {
        menu.forEach((item) => {
          if (item.menuId != menuId) {
            let subMenu = document.getElementById("sub-menu-" + item.menuId);
            let menuItem = document.getElementById("menu-item-" + item.menuId);
            if (subMenu) {
              if (subMenu.classList.contains("show")) {
                subMenu.classList.remove("show");
                menuItem.classList.remove("expanded");
              }
            }
          }
        });
      }
    }
  }

}