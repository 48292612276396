import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PermissionsDirective } from '../directives/permissions.directive'

@NgModule({
  declarations: [PermissionsDirective],
  imports: [CommonModule],
  exports: [PermissionsDirective],
  schemas: [NO_ERRORS_SCHEMA],
})
export class PermissionsModule {}
