import { Directive, Input, ElementRef, OnInit } from '@angular/core'
import * as SecureLS from 'secure-ls'
import { GlobalConstants } from '../common/global-constants'

@Directive({
  selector: '[ngxPermissionsOnly]',
})
export class PermissionsDirective implements OnInit {
  ls
  profileName

  constructor(private elementRef: ElementRef) {
    console.log('constructor')
    console.log(elementRef)
  }

  ngOnInit(): void {
    console.log('init' + this.ngxPermissionsOnly)
    this.validate()
  }

  @Input() ngxPermissionsOnly: string[]

  validate() {
    this.ls = new SecureLS({ encodingType: 'des', isCompression: false, encryptionSecret: GlobalConstants.key })
    this.profileName = this.ls.get('profileName')

    console.log(' profilename :' + this.profileName)
    if (this.ngxPermissionsOnly.indexOf(this.profileName) == -1) {
      this.elementRef.nativeElement.style.display = 'none'
    }
  }
}
