import { Component } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { AuthService } from './pages/auth/auth.service';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd, NavigationStart } from "@angular/router"; 
import { DataTransferService } from './shared/services/data-transfer.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  userDetails: any = [];
  private pageRefreshCheck: Subscription;
  private activeISFMenuItemSubscription: Subscription;

  constructor(
    public appSettings:AppSettings, 
    private authService: AuthService,  
    private router: Router,
    private dataTransferService: DataTransferService,
    private spinner: NgxSpinnerService
    ){
      this.settings = this.appSettings.settings;

      if (this.authService.isLoggedIn()) {
        this.authService.navigateToDashboard();
      } else {
        this.authService.redirectToLoginPage();
      }    
      this.authService.getUserDetails$.subscribe(x => {
        // console.log(x, 'xxxx');
        this.userDetails = x;
      })

      // preserving the state of Last Navigated 
      this.pageRefreshCheck = this.router.events.subscribe(event => {
          if(event instanceof NavigationStart && !router.navigated) {
            this.dataTransferService.updateActiveMenuItemData(JSON.parse(sessionStorage.getItem('activeISFMenuData')))
          }
      })
  } 

  ngOnInit() { 
    // this.settings.loadingSpinner = false
    this.spinner.show();
  }
 
  //this.authService.redirectUrl = route.snapshot.url;
  
}