import { AbstractControl, AsyncValidatorFn } from '@angular/forms'
import { TrialManagementService } from '../../features/trial-master-file/trial-management/trial-management.service'
import { map } from 'rxjs/operators'
import { SiteWorkingService } from '../../features/investigator-site-file/site-working-file/site-working.service'
import { SiteReferrenceFileService } from '../../features/investigator-site-file/site-reference-file/site-referrence-file.service'


export function uniqueTitleValidator(trialManagementService: TrialManagementService, projectId: number, categoryid: number = 0, subcategoryid: number = 0, contentTypeId: number = 0, subContentTypeId: number = 0): AsyncValidatorFn {
    return (control: AbstractControl) => {
        return trialManagementService.getTrialManageDocsStatusList(projectId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
            map((docs: any) => {
                const similiarTitles = docs.find(item => {
                    console.log(item?.documentTitle, '-', control.value)
                    return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                })

                return similiarTitles ? { titleAlreadyExists: true } : null
            })
        )
    }
}
export function uniqueFileNoteTitleValidator(trialManagementService: TrialManagementService, projectId: number, categoryid: number = 0, subcategoryid: number = 0, contentTypeId: number = 0, subContentTypeId: number = 0): AsyncValidatorFn {
    return (control: AbstractControl) => {
        return trialManagementService.getTrialManageDocsFileNoteList(projectId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
            map((docs: any) => {
                console.log("Files:", docs, 'Value:', control.value);
                const similiarTitles = docs.find(item => {
                    console.log(item?.documentTitle?.toLowerCase(), control.value.toLowerCase(), item?.documentTitle?.toLowerCase() === control.value.toLowerCase())
                    return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                })
                console.log("similiarTitles", similiarTitles)
                return similiarTitles ? { titleAlreadyExists: true } : null
            })
        )
    }
}

export function isfUniqueTitleValidator(siteWorkingService: SiteWorkingService, projectId: number, siteId, categoryid: number, subcategoryid: number, contentTypeId: number, subContentTypeId: number): AsyncValidatorFn {
    return (control: AbstractControl) => {
        if (contentTypeId === 253) {
            return siteWorkingService.get174DocumentTitleList(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
                map((docs: any) => {
                    const similiarTitles = docs.find(item => {
                        return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                    })

                    return similiarTitles ? { titleAlreadyExists: true } : null
                })
            )
        } else if (contentTypeId === 260) {
            return siteWorkingService.get1834DocumentTitleList(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
                map((docs: any) => {
                    console.log(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId);
                    const similiarTitles = docs.find(item => {
                        return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                    })

                    return similiarTitles ? { titleAlreadyExists: true } : null
                })
            )
        } else {
            return siteWorkingService.getDocumentTitleList(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
                map((docs: any) => {
                    console.log(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId);
                    const similiarTitles = docs.find(item => {
                        return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                    })

                    return similiarTitles ? { titleAlreadyExists: true } : null
                })
            )
        }

    }
}

export function TitleValidator(SiteReferrenceFileService: SiteReferrenceFileService, projectId: number, siteId, categoryid: number, subcategoryid: number, contentTypeId: number, subContentTypeId: number): AsyncValidatorFn {
    return (control: AbstractControl) => {
        console.log("Running!!", projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId);
        return SiteReferrenceFileService.get33documentTitleList(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
            map((docs: any) => {
                console.log("Docs-->", docs);
                const similiarTitles = docs.find(item => {
                    return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                })
                console.log("titleAlreadyExists", similiarTitles ? { titleAlreadyExists: true } : null)
                return similiarTitles ? { titleAlreadyExists: true } : null
            })
        )
    }
}