import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 

import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component'; 


export const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/login' },
    { 
        path: '', 
        component: PagesComponent, children: [
            // { path: '', pathMatch: 'full', redirectTo: 'landing' },
            { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
            // { path: 'project-dashboard', component: ProjectDashboardComponent, data: { breadcrumb: 'Project Dashboard' } },
            // { path: 'project', loadChildren: () => import('./pages/project-module/project-module.module').then(m => m.ProjectModuleModule) , data: { breadcrumb: 'Project Dashboard' } },
            // { path: 'site', loadChildren: () => import('./pages/site/site.module').then(m => m.SiteModule) , data: { breadcrumb: 'Site Dashboard' } },
            
            { path: 'org', loadChildren: () => import('./pages/org/org.module').then(m => m.OrgModule), data: { breadcrumb: 'ORG' } },
            
            { path: 'project-mapping', loadChildren: () => import('./pages/project-mapping/project-mapping.module').then(m => m.ProjectMappingModule), data: { breadcrumb: 'Project Mapping' } },
            
            { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule), data: { breadcrumb: 'Settings' } },
         
            { path: 'site', loadChildren: () => import('./features/investigator-site-file/investigator-site-file.module').then(m => m.InvestigatorSiteFileModule) , data: { breadcrumb: 'Site Dashboard' } },
            
            {path: 'project', loadChildren: () => import('./features/trial-master-file/trial-master-file.module').
             then(m => m.TrialMasterFileModule), data: {breadcrumb: 'Project Dashboard'}},

            {path: 'reports', loadChildren: () => import('./features/reports/reports.module').then(m => m.ReportsModule), data: {
                breadcrumb: 'Reports'
            }},

            {path: 'tmf-reports', loadChildren: () => import('./features/tmf-reports/tmf-reports.module').then(m => m.TmfReportsModule), data: {
                breadcrumb: 'Reports'
            }},
            {path: 'isf-reports', loadChildren: () => import('./features/isf-reports/isf-reports.module').then(m => m.IsfReportsModule), data: {
                breadcrumb: 'Reports'
            }},
             {path: 'qc-dashboard', loadChildren: () => import('./pages/qc-admin-user/qc-admin-user.module').then(m => m.QcAdminUserModule)}
        ]
    },
    { path: 'login', loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },
    { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
]; 

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, // <- comment this line for activate lazy load
            relativeLinkResolution: 'legacy',
            // useHash: true
        })
    ],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }