import { ValidationErrors } from "@angular/forms";

export function  getFormValidationErrors(form:any) {
    let totalErrors = 0;
    let controlErrors: ValidationErrors;
    let errList:any[] = [];
    Object.keys(form.controls).forEach(key => {
      controlErrors= form.get(key).errors;
      if (controlErrors != null) {
         totalErrors++;
         Object.keys(controlErrors).forEach(keyError => {
           errList.push({
            control:key,
            errType:keyError,
           })
          });
      }
    });
    console.log('Number of errors: ' ,totalErrors);
    return ({totalErrors, errList})
  }