import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";

import { MatTableDataSource } from "@angular/material/table";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatDialog } from "@angular/material/dialog";

import { TrialManagementService } from "../../../features/trial-master-file/trial-management/trial-management.service";
import { ProjectMappingService } from "../../../pages/project-mapping/project-mapping.service";
import { DataTransferService } from "../../../shared/services/data-transfer.service";
import { InvestigatorSiteFileService } from "../investigator-site-file.service";
import { SiteWorkingService } from "../site-working-file/site-working.service";
import { PdfViewerComponent } from "../../../shared/components/pdf-viewer/pdf-viewer.component";
import { AddEditLabRecordDevComponent } from "../site-working-file/logs-and-forms/lab-record-devia-four-seven-x/add-edit-lab-record-dev/add-edit-lab-record-dev.component";
import { PromptModalComponent } from "../../../shared/components/prompt-modal/prompt-modal.component";
import { SnackbarService } from "../../../shared/components/toast/snackbar.service";
import * as UAParser from "ua-parser-js";
import { StudyReferenceFileService } from "../study-reference-file/study-reference-file.service";
import { CraModelComponent } from "../../../shared/components/cra-model/cra-model.component";
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { PermissionsService } from "../../../permissions/permissions.service";
import { DatePipe } from "@angular/common";
import { GlobalConstants } from "../../../common/global-constants";


@Component({
  selector: "app-other-related-documents",
  templateUrl: "./other-related-documents.component.html",
  styleUrls: ["./other-related-documents.component.scss"],
  providers: [DatePipe]
})
export class OtherRelatedDocumentsComponent implements OnInit {
  public settings: Settings;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public headerTitle: string;
  userAgentDetails: any;
  public form: FormGroup;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns = [
    "sno",
    "documentTitle",
    "versionNo",
    "documentStatus",
    "comments",
    "uploadedBy",
    "uploadedOn",
    "pisiteTeamAcknowledgement",
    "craVerificationBy",
    "craVerificationOn",
    "action",
  ];

  menuItemsHierarchy: Observable<any>;
  activeISFMenuItemSubscription: Subscription;
  routerData: any;
  currData: any;
  table_data: any;
  sponsorList: any;
  projectList: any;
  investigatorList: any;
  piSiteDetails: any[] = [];
  userInfo: any;
  piData: any;

  siteNo: any;
  siteId: any;
  projectId: any;
  sponsorId: any;
  resourceId: any;
  categoryId: any;
  subCategoryId: any;
  contentTypeId: any;
  subContentTypeId: any;
  roleName: any;
  projectName: any;
  sponsorName: any;
  resourceName: any;
  categoryName: any;
  subCategoryName: any;
  contentName: any;
  subContentTypeName: any;
  globalAuditHistory: any;
  menuList: any;
  activeMenuTitle: any;
  addButtonAccess: boolean;
  editButtonAccess: boolean;
  deleteButtonAccess: boolean;
  downloadButtonAccess: boolean;
  printButtonAccess: boolean;
  showDelete: boolean = false;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    public ISFService: InvestigatorSiteFileService,
    public dataTransferService: DataTransferService,
    private trialManagementService: TrialManagementService,
    private projectMapService: ProjectMappingService,
    private siteWorkingService: SiteWorkingService,
    private snackBar: SnackbarService,
    private studyRefService: StudyReferenceFileService,
    public appSettings: AppSettings,
    private readonly permissions: PermissionsService

  ) {
    this.settings = this.appSettings.settings;
    this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    this.form = this.fb.group({
      project: [""],
    });
    this.routerData = this.route.snapshot.data;
    let title =
      this.router.url &&
      this.router.url.split("/")[this.router.url.split("/").length - 1];
    this.headerTitle = title.replace(/-|_isf|NA|isAddNA/g, " ");

    this.dataTransferService.getLandingPageISFData().subscribe(res => {
      console.log("Dash-Res=>", res);
      if (res.siteId !== 0 && res.projectId !== 0) {
        this.ngOnInit();
      }
    })

  }

  ngOnInit() {
    this.currData = sessionStorage.getItem("dashboardData") ? JSON.parse(sessionStorage.getItem("dashboardData")) : '';
    this.roleName = sessionStorage.getItem('roleName')
    this.projectId = this.currData.projectId;
    this.siteId = this.currData.siteId;
    console.log(this.currData, "currData");
    this.userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    this.getResourceByuserId(this.userInfo.tokenid);
    this.roleName = sessionStorage.getItem('roleName');

    let parser = new UAParser();
    this.userAgentDetails = parser.getResult();
    this.activeISFMenuItemSubscription =
      this.dataTransferService.getActiveMenuItemData.subscribe((data) => {
        console.log(`Curr Active menu Item Data `, data);
        this.activeMenuTitle = data.menuTitle;
        let menu = { name: data.menuTitle.split('-')[1].toString().trim() };
        console.log('Menu:', menu);
        this.menuItemsHierarchy = this.siteWorkingService.categoryByTitle(menu);
        this.menuItemsHierarchy.subscribe((data) => {
          console.log(`Hierarchy of currActiveMenuItem `, data);
          (this.categoryName = data[0].categoryName
            ? data[0].categoryName
            : ""),
            this.categoryId = data[0].categoryId;
          // Role Access

          const RoleId = sessionStorage.getItem('roleId');
          console.log('---PatientScreening - RoleId : ' + RoleId);

          this.permissions.toggleButton(RoleId, this.activeMenuTitle, 'ISF').then((res) => {
            this.menuList = res;
            console.log('--- PatientScreening - menuList : ', res);

            if (res.length > 0) {
              this.addButtonAccess = this.menuList[0].add;
              this.editButtonAccess = this.menuList[0].edit;
              this.downloadButtonAccess = this.menuList[0].download;
              this.printButtonAccess = this.menuList[0].print;
              this.deleteButtonAccess = this.menuList[0].deleted;
              console.log('--- PatientScreening - this.menuList.add : ' + JSON.stringify(this.menuList[0]?.add));
              console.log('--- PatientScreening - this.menuList.edit : ' + JSON.stringify(this.menuList[0]?.edit));
              console.log('--- PatientScreening - this.menuList.download : ' + JSON.stringify(this.menuList[0]?.download));
              console.log('--- PatientScreening - this.menuList.print : ' + JSON.stringify(this.menuList[0]?.print));
              console.log('--- PatientScreening - this.menuList.deleted : ' + JSON.stringify(this.menuList[0]?.deleted));
            }
          });
          // this.getGlobalAuditHistory();
          this.getSponsors();
          this.getProjectList();
        });
      });

  }

  async getResourceByuserId(userid: number) {
    await this.ISFService.getResourceByUserId(userid).subscribe((resource: any) => {
      this.showDelete = resource[0].resource_at.toLowerCase() !== 'site' ? true : false;
      console.log("Resource==>", this.showDelete);
    })
  }

  ngOnDestroy(): void {
    this.activeISFMenuItemSubscription.unsubscribe();
  }

  handleSite(item) {
    this.projectId = item.value;
    let selectedProject = this.projectList.filter(
      (p) => p.projectId === item.value
    );
    this.projectId = selectedProject[0].projectId;
    this.projectName = selectedProject[0].projectName;
    this.siteNo = selectedProject[0].siteNo;
    this.getSponsors();
    this.getTableData();
    this.getGlobalAuditHistory();
  }

  handleResource(item) {
    console.log("Resource", item.value);
  }

  getGlobalAuditHistory() {
    this.studyRefService
      .get7xGlobalAuditHistory(
        this.projectId,
        this.categoryId ? this.categoryId : 0,
        this.subCategoryId ? this.subCategoryId : 0,
        this.contentTypeId ? this.contentTypeId : 0,
        this.subContentTypeId ? this.subContentTypeId : 0
      )
      .subscribe((res: any) => {
        let history = [];
        res.forEach(el => {
          if (el.auditHistory !== null) {
            el.auditHistory.forEach((item) => {
              if (item.length) {
                item.forEach(it => {
                  let obj = {
                    Title: el.documentTitle,
                    VersionNo: el.versionNo,
                    ...it,
                  }
                  history.push(obj);
                })
              } else {
                let obj = {
                  Title: el.documentTitle,
                  VersionNo: el.versionNo,
                  ...item,
                }
                history.push(obj);
              }
            })
          }
        });
        history.forEach(el => {
          el['Date Time'] = el['Date Time'] !== null ? new Date(el['Date Time']).toLocaleString("en-US", { timeZone: "Asia/Kolkata" }) : '';
          el.Status = el.Status.split('&');
        });
        history = history.reduce((acc, c) => {
          if (c.Status.length === 1) {
            return [...acc, c];
          } else if (c.Status.length > 1) {
            return [
              ...acc,
              ...c.Status.map((s) => {
                return { ...c, Status: [s] };
              }),
            ];
          }
        }, []);
        this.globalAuditHistory = history;
      });
  }

  getSponsors() {
    this.trialManagementService
      .getTrialManagementDetails(this.projectId)
      .subscribe((data) => {
        this.sponsorList = data;
        console.log("sponsorList:", this.sponsorList);
        this.sponsorId = this.sponsorList[0].sponsorId
          ? this.sponsorList[0].sponsorId
          : "";
        this.sponsorName = this.sponsorList[0].sponsorName
          ? this.sponsorList[0].sponsorName
          : "";
      });
  }

  getProjectList() {
    if (this.roleName === GlobalConstants.superadmin ||
      this.roleName === GlobalConstants.admin) {

      this.projectMapService.getProjectsBySiteId(this.currData.siteId)
        .subscribe((data: any) => {
          this.projectList = data;
          console.log("projectList", data)
          let selectedProject = this.projectList.filter(
            (item) => item.projectId === this.currData.projectId
          );
          this.projectId = selectedProject[0].projectId;
          this.projectName = selectedProject[0].projectName;
          this.siteNo = selectedProject[0].siteNo;
          this.form.patchValue({ project: this.projectId });
          this.getTableData();
        });
    }
    else {
      this.projectMapService.getIsfProjectBySiteId(this.currData.siteId, this.userInfo.tokenid)
        .subscribe((data: any) => {
          console.log("projectList", data)
          this.projectList = data;
          let selectedProject = this.projectList.filter(
            (item) => item.projectId === this.currData.projectId
          );
          this.projectId = selectedProject[0].projectId;
          this.projectName = selectedProject[0].projectName;
          this.siteNo = selectedProject[0].siteNo;
          this.form.patchValue({ project: this.projectId });
          this.getTableData();
        });
    }
  }

  getTableData() {
    this.dataSource = new MatTableDataSource([]);
    this.siteWorkingService
      .get175To177TableList(
        this.sponsorId,
        this.projectId,
        this.siteId,
        this.categoryId ? this.categoryId : 0,
        this.subCategoryId ? this.subCategoryId : 0,
        this.contentTypeId ? this.contentTypeId : 0,
        this.subContentTypeId ? this.subContentTypeId : 0
      )
      .subscribe((data: any[]) => {
        // console.log("Data:",data)
        let piList = [];
        let tempData = [];
        data.forEach((el) => {
          this.studyRefService.getPISiteDetails(el.id).subscribe((l: any) => {
            piList = l;
            console.log("piSiteDetails", l);
            this.studyRefService.get2xPiSiteTeamAckDetails(el.id, this.userInfo.tokenid).subscribe((res: any) => {
              console.log("Ack-Res:", res);
              this.piData = res;
              console.log("PISiteDetails->", piList);
              let p = piList.find(i => i['resourceId'] === this.userInfo['tokenid']);
              if (res.length > 0) {

                let item = {
                  ...el,
                  showBtn: true,
                  acknowledgementStatus: res[0].status,
                  piDataId: p ? true : false,
                }
                // console.log("Item", item);
                tempData.push(item)
              } else {
                tempData.push(el);
              }

              console.log("TableData", tempData);
              this.dataSource = new MatTableDataSource(tempData);
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              this.getGlobalAuditHistory();
            })
          })
        })
      });
  }

  openDialog(type, data) {
    let tableSource: any = {};

    if (type === "history") {
      tableSource.title = "Audit History";
      if (data.auditHistory !== null) {
        data.auditHistory.forEach(el => {
          el['Date Time'] = el['Date Time'] !== null ? new Date(el['Date Time']).toLocaleString("en-US", { timeZone: "Asia/Kolkata" }) : '';

          if (el.Status !== undefined) {
            el.Status = el.Status.split('&');
          }
        });
      }
      data.auditHistory = data.auditHistory.reduce((acc, c) => {
        if (c.Status.length === 1) {
          return [...acc, c];
        } else if (c.Status.length > 1) {
          return [
            ...acc,
            ...c.Status.map((s) => {
              return { ...c, Status: [s] };
            }),
          ];
        }
      }, []);
      (tableSource.columns = [
        "Os",
        "Os Version",
        "Browser",
        "Browser Version",
        "Status",
        "Added By",
        "Date Time",
      ]),
        tableSource.tableData = data.auditHistory ? data.auditHistory : "NA";
    } else if (type === "globalAuditHistory") {
      //let keys = Object.keys(this.globalAuditHistory[0]);
      tableSource.title = "Global Audit History";
      (tableSource.columns = [
        "Title",
        "VersionNo",
        "Os",
        "Os Version",
        "Browser",
        "Browser Version",
        "Status",
        "Added By",
        "Date Time",
      ]),
        tableSource.tableData = this.globalAuditHistory;
    } else if (type === "add") {
      data = {
        siteId: this.siteId,
        sponsor: this.sponsorName,
        sponsorId: this.sponsorId,
        project: this.projectName,
        projectId: this.projectId,
        category: this.categoryName ? this.categoryName : 0,
        categoryId: this.categoryId ? this.categoryId : 0,
        subcategory: this.subCategoryName ? this.subCategoryName : "",
        subCategoryId: this.subCategoryId ? this.subCategoryId : 0,
        contentType: this.contentName ? this.contentName : 0,
        contentId: this.contentTypeId ? this.contentTypeId : 0,
        subContentType: this.subContentTypeName ? this.subContentTypeName : 0,
        subContentTypeId: this.subContentTypeId ? this.subContentTypeId : "",
      };
    } else if (type === 'edit') {
      data = {
        ...data,
        siteId: this.siteId,
        sponsor: this.sponsorName,
        sponsorId: this.sponsorId,
        project: this.projectName,
        projectId: this.projectId,
        category: this.categoryName ? this.categoryName : 0,
        categoryId: this.categoryId ? this.categoryId : 0,
        subcategory: this.subCategoryName ? this.subCategoryName : "",
        subCategoryId: this.subCategoryId ? this.subCategoryId : 0,
        contentType: this.contentName ? this.contentName : 0,
        contentId: this.contentTypeId ? this.contentTypeId : 0,
        subContentType: this.subContentTypeName ? this.subContentTypeName : 0,
        subContentTypeId: this.subContentTypeId ? this.subContentTypeId : "",
      };
    }

    let dialogRef = this.dialog.open(AddEditLabRecordDevComponent, {
      data: {
        type: type,
        data:
          type === "history" || type === "globalAuditHistory"
            ? tableSource
            : data,
      },
      width: "1000px",
    });
    this.settings.loadingModal = false;

    dialogRef.afterClosed().subscribe((result) => {
      this.getTableData();
    });
  }

  openAcknowledgeDialog(data) {
    // console.log("PI-Data>", this.piData[0].docUploadId);
    let dialogRef = this.dialog.open(AddEditLabRecordDevComponent, {
      data: { type: "acknowledge", data: data, autoFocus: false },
      width: "600px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (typeof result !== undefined) {
        this.getTableData();
      }
    });
  }

  openPISiteDetailDialog(data) {
    let tableSource: any = {};
    let tempData = [];
    this.studyRefService.getPISiteDetails(data.id).subscribe(
      (resp: any) => {
        console.log(
          "PiSiteDetails:", resp
        );
        resp.map((el) => {
          if (el.acknowledgementStatus === 0) {
            el.acknowledgementStatus = 'PENDING';
          } else {
            el.acknowledgementStatus = 'ACKNOWLEDGED';
          }
        });
        tableSource.title = "PI/Site Team Acknowledgement Details";
        resp.forEach((el: any) => {
          console.log(el);
          let item =
          {
            "Site": el.site,
            "Country": el.country,
            "Resource": el.resource,
            "Status": el.acknowledgementStatus,
            "Acknowledged On": this.datePipe.transform(el.acknowledgedOn, 'dd/MM/yyy, h:mm:ss a',"-0000"),
            //'Acknowledged On': el.acknowledgedOn !== null ? new Date(el.acknowledgedOn).toLocaleString("en-US", { timeZone: "Asia/Kolkata" }) : '',
          }
          tempData.push(item);
        })
        this.piSiteDetails = tempData;
        console.log("this.piSiteDetails", this.piSiteDetails);
        (tableSource.columns = [
          "Site",
          "Country",
          "Resource",
          "Status",
          "Acknowledged On",
        ]),
          (tableSource.tableData = this.piSiteDetails);

        let dialogRef = this.dialog.open(AddEditLabRecordDevComponent, {
          data: { type: "viewDetails", data: tableSource, autoFocus: false },
          width: "1200px",
        });
      }
    )
  }

  openCRADetailDialog(data) {
    let dialogRef = this.dialog.open(AddEditLabRecordDevComponent, {
      data: { type: "cra", data: data, autoFocus: false },
      width: "600px",
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.getTableData();
    });
  }

  promptForDelete = (item) => {
    console.log(item, "item");
    let dialogRef = this.dialog.open(PromptModalComponent, {
      data: { type: "withComment", content: "Are you sure want to delete?" },
      width: "600px",
    });

    dialogRef.afterClosed().subscribe((data) => {
      let Data = { browser: JSON.stringify(this.userAgentDetails) };
      let type = typeof data;
      if (type === "string" && data !== "NA") {
        this.settings.loading = true;
        this.siteWorkingService
          .delete175To177TableRecord(item.id, Data)
          .subscribe((res) => {
            console.log("Delete", res);
            this.settings.loading = false;
            this.getTableData();
          });
      } else if (type === "boolean") {
        this.snackBar.openSnackBar("Delete Cancelled", true);
        this.settings.loading = false;

      }
      this.getTableData();
    });
  };

  viewFile = (item) => {
    this.siteWorkingService.commondocuploadpdfdetails(item.id).subscribe(
      (pdf: any) => {
        console.log("PDF", pdf);
        let dialogRef = this.dialog.open(PdfViewerComponent, {
          data: {
            info: {
              documentTitle: pdf[0].documentTitle ? pdf[0].documentTitle : '',
              documentVersion: pdf[0].documentVersion ? pdf[0].documentVersion : '',
              documentStatus: pdf[0].documentStatus ? pdf[0].documentStatus : '',
              documentType: pdf[0]?.documentLocation
                ?.slice(pdf[0]?.documentLocation?.lastIndexOf("."))
                ?.toLowerCase(),
              documentLocation: pdf[0]?.documentLocation ? pdf[0]?.documentLocation : '',
              documentSize: "",
              createdBy: pdf[0].createdBy ? pdf[0].createdBy : '',
              updatedBy: pdf[0].updatedBy ? pdf[0].updatedBy : '',
              deletedBy: pdf[0].deletedBy ? pdf[0].deletedBy : '',
            },
            file: pdf[0]?.documentLocation ? pdf[0]?.documentLocation : '',
          },
          width: "1200px",
        });

        dialogRef.afterClosed().subscribe((data) => { });
      });
  };
}
