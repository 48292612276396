import { Component, OnInit, ViewEncapsulation, ViewChild } from "@angular/core";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { Menu } from "../menu/menu.model";
import { MenuService } from "../menu/menu.service";
import { NavSideMenuService } from "../menu/nav-side-menu.service";
import { GlobalConstants } from "app/common/global-constants";
// import { verticalProjectMenuItems } from "../menu/menu";
import { PermissionsService } from "../../../permissions/permissions.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class SidenavComponent implements OnInit {
  public userImage = "../assets/img/users/user.jpg";
  public menuItems: Menu[] = [];
  public settings: Settings;
  roleId: number;
  menuaccessbtn: boolean;
  menuList: any;
  activeMenuTitle: any;
  addButtonAccess: boolean;
  editButtonAccess: boolean;
  deleteButtonAccess: boolean;
  downloadButtonAccess: boolean;
  printButtonAccess: boolean;
  showDelete: boolean = false;
  sideMenu: any;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    private navSideMenuService: NavSideMenuService,
    private readonly permissions: PermissionsService

  ) {
    this.settings = this.appSettings.settings;
    // this.sideMenu = false;
  }

  ngOnInit() {
    this.roleId = parseInt(sessionStorage.getItem('roleId'));
    let roleName = sessionStorage.getItem('roleName');
    if (roleName === GlobalConstants.qaa || roleName === GlobalConstants.qau) {
      sessionStorage.setItem('sidenavIsPinned', "false");
      this.settings.sidenavIsPinned = false;
    } else {
      sessionStorage.setItem('sidenavIsPinned', "true");
    }
    this.getMenus(this.roleId);
    this.navSideMenuService
      .getAllTMFReportSidebarMenuListByRoleId(this.roleId).subscribe(
        (resp) => {
          // console.log("tmf-reports:",resp);
        }
      )
  }

  async getMenus(roleId: number) {
    let data = sessionStorage.dashboardData
      ? JSON.parse(sessionStorage.dashboardData)
      : null;
    if (data.type === "site") {
      this.navSideMenuService
        .getAllISFSidebarMenuListByRoleId(roleId)
        .subscribe((res: Menu[]) => {
          // console.log(res);
          this.menuItems = [];
          for (var mi in res) {
            var mio = res[mi];
            // console.log(mio,mio["menu"]);
            if (mio["menu"]) {
              // console.log(mio["menu"], 222222)
              this.menuItems.push(mio);
            }
          }
          // console.log("Menu_Items:", this.menuItems)
          localStorage.setItem('menuItems', JSON.stringify(this.menuItems))
          const sideMenu = localStorage.getItem('menuItems.menu');

        });
    } else if (data.type === "project") {
      this.navSideMenuService
        .getAllTMFSidebarMenuListByRoleId(roleId)
        .subscribe((res: Menu[]) => {
          // console.log(res);
          this.menuItems = [];
          for (var mi in res) {
            var mio = res[mi];
            // console.log(mio,mio["menu"]);
            if (mio["menu"]) {
              // console.log(mio["menu"], 222222)
              this.menuItems.push(mio);
            }
          }
          // console.log("Menu_Items:", this.menuItems)
          localStorage.setItem('menuItems', JSON.stringify(this.menuItems))
          const sideMenu = localStorage.getItem('menuItems.menu');

        });
    } else if (data.type === "report" && data.reports === "isf") {
      this.navSideMenuService
        .getAllISFReportSidebarMenuListByRoleId(roleId)
        .subscribe((res: Menu[]) => {
          this.menuItems = [];
          let menus = res.filter((item) => item.id !== 1);
          for (var mi in menus) {
            var mio = menus[mi];
            // console.log(mio,mio["menu"]);
            if (mio["menu"]) {
              // console.log(mio["menu"], 222222)
              this.menuItems.push(mio);
            }
          }
          localStorage.setItem('menuItems', JSON.stringify(this.menuItems))
        });
    } else if (data.type === "report" && data.reports === "tmf") {
      this.navSideMenuService
        .getAllTMFReportSidebarMenuListByRoleId(roleId)
        .subscribe((res: Menu[]) => {
          this.menuItems = [];
          let menus = res.filter((item) => item.id !== 2);
          for (var mi in menus) {
            var mio = menus[mi];
            // console.log(mio,mio["menu"]);
            if (mio["menu"]) {
              // console.log(mio["menu"], 222222)
              this.menuItems.push(mio);
            }
          }
          localStorage.setItem('menuItems', JSON.stringify(this.menuItems))
        });
    }
  }

  changeVerticalMenus(menuId: number) {
    // console.log("MenuId", menuId);
    // console.log("Menu-Items-->", this.menuItems);
    const currRootMenuData = JSON.parse(sessionStorage.dashboardData);
    if (menuId === 310 || menuId === 311 || menuId === 96) {
      const prevRootMenuType = currRootMenuData.type;
      console.log("prevRootMenuType : ", prevRootMenuType);
      console.log("menuId : ", menuId);
      currRootMenuData.type = "report";
      sessionStorage.setItem("dashboardData", JSON.stringify(currRootMenuData));
      // const updatedMenuItems = this.menuService.getVerticalMenuItems();
      let updatedMenuItems = [];

      if (prevRootMenuType === "site") {
        this.navSideMenuService
          .getAllISFReportSidebarMenuListByRoleId(this.roleId)
          .subscribe((res: Menu[]) => {
            this.menuItems = [];
            updatedMenuItems = res;
            console.log("-------", res)
            let menus = updatedMenuItems.filter((item) => item.id !== 1);
            for (var mi in menus) {
              var mio = menus[mi];
              if (mio["menu"]) {
                this.menuItems.push(mio);
              }
            }
            localStorage.setItem('menuItems', JSON.stringify(this.menuItems))
            console.log("UpdatedMenu:", this.menuItems)
          });
      }
      if (prevRootMenuType === "project") {
        this.navSideMenuService
          .getAllTMFReportSidebarMenuListByRoleId(this.roleId)
          .subscribe((res: Menu[]) => {
            this.menuItems = [];
            updatedMenuItems = res;
            let menus = updatedMenuItems.filter((item) => item.id !== 2);
            console.log(menus);
            for (var mi in menus) {
              var mio = menus[mi];
              if (mio["menu"]) {
                this.menuItems.push(mio);
              }
            }
            localStorage.setItem('menuItems', JSON.stringify(this.menuItems))
            console.log("UpdatedMenu:", this.menuItems)
          });
      }
    }
    let updatedMenuItems = [];
    let title = this.menuItems.find(el => el.menuId === menuId)?.title;
    if (typeof title !== undefined && title === 'Project Dashboard' && currRootMenuData.type === "report") {
      currRootMenuData.type = "project";
      sessionStorage.setItem("dashboardData", JSON.stringify(currRootMenuData));
      this.navSideMenuService
        .getAllTMFSidebarMenuListByRoleId(this.roleId)
        .subscribe((res: Menu[]) => {
          updatedMenuItems = res;
          console.log("Menu_List:", res)
          this.menuItems = updatedMenuItems;
          console.log("Menu_List:", this.menuItems)
        });
    }
    if (typeof title !== undefined && title === 'Site Dashboard' && currRootMenuData.type === "report") {
      currRootMenuData.type = "site";
      console.log("Site_running");
      sessionStorage.setItem("dashboardData", JSON.stringify(currRootMenuData));
      this.navSideMenuService
        .getAllISFSidebarMenuListByRoleId(this.roleId)
        .subscribe((res: Menu[]) => {
          updatedMenuItems = res;
          console.log(":report", res)
          this.menuItems = updatedMenuItems.filter((item) => item.id !== 1);
        });
    }
  }

  public closeSubMenus() {
    let menu = document.getElementById("vertical-menu");
    console.log("menu-------->", menu);
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }
}