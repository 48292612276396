import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { columnsByPin } from "@swimlane/ngx-datatable";

export interface AuditHistory {
  "Added by": string;
  Browser: string;
  "Browser Version": string;
  // "Date Time": string;
  Os: string;
  "Os Version": string;
  Status: string;
}

@Component({
  selector: "app-audit-table",
  templateUrl: "./audit-table.component.html",
  styleUrls: ["./audit-table.component.scss"],
})
export class AuditTableComponent implements OnInit {
  public displayedColumns = [];
  @Input() tableData: any;
  @Input() columns: any;
  // @Output() commonEvents = new EventEmitter<any>()
  constructor() {}

  ngOnInit(): void {}

  ngOnChanges() {
    const fallBackColumns = [
      // "docTitle",
      // "versionNo",
      "Os",
      "Os Version",
      "Browser",
      "Browser Version",
      "Status",
      "Added By",
      "Date Time",
    ];
    this.displayedColumns = this?.columns ? this.columns : fallBackColumns;
    console.log(this.displayedColumns, "this.displayedColumns");
    console.log(`Table-Data `, this.tableData);
  }

  // handleCommonEvents = () => {
  //   this.commonEvents.emit({
  //     type: 'Edit',
  //     data: ''
  //   })
  // }
}
