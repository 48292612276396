import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

interface LandingPageISFData {
  siteId: number;
  projectId: number;
}

interface LandingPageTMFData {
  projectId: number;
}

interface activeISFMenuItem {
  menuType: string;
  menuTitle: string;
}

interface dashboardData {
  type: string;
}
interface tourProgress {
  type: boolean;
}

@Injectable({
  providedIn: "root",
})
export class DataTransferService {
  // using an RxJs subject to transfer data between landing dashboard page & ISF home page
  private ISFSiteandProjectData = new BehaviorSubject<LandingPageISFData>({
    siteId: 0,
    projectId: 0,
  });
  private TMFProjectData = new BehaviorSubject<LandingPageTMFData>({
    projectId: 0,
  });
  private ActiveMenuItemData = new BehaviorSubject<activeISFMenuItem>({
    menuType: "category",
    menuTitle: "Dashboard",
  });
  private dashData = new BehaviorSubject<dashboardData>({ type: "type" });
  private tourProgress = new BehaviorSubject<tourProgress>({ type: false });
  private touched = new BehaviorSubject("false");
  private currentRoute = new BehaviorSubject("/");
  constructor() {}

  // Used to trigger components
  public getDashData = this.dashData.asObservable();
  // public isDataChanged = this.touched.asObservable();

  updateDashData(dashboardData: dashboardData) {
    return this.dashData.next(dashboardData);
  }

  getDataChanged(): Observable<any> {
    return this.touched.asObservable();
  }

  updateDataChanged(value: any) {
    return this.touched.next(value);
  }

  // our Subject also behave as an observable
  public getLandingPageISFData(): Observable<any> {
    return this.ISFSiteandProjectData.asObservable();
  }

  // our Subject which behaves as an observable emits any new data using the next method
  updateLandingPageISFData(landingPageISFData: LandingPageISFData) {
    this.ISFSiteandProjectData.next(landingPageISFData);
  }

  public getLandingPageTMFData = this.TMFProjectData.asObservable();

  updateLandingPageTMFData(landingPageTMFData: LandingPageTMFData) {
    this.TMFProjectData.next(landingPageTMFData);
  }

  public getActiveMenuItemData = this.ActiveMenuItemData.asObservable();

  updateActiveMenuItemData(activeISFMenuItemTitle: activeISFMenuItem) {
    this.ActiveMenuItemData.next(activeISFMenuItemTitle);
  }

  public getTourProgress(): Observable<any> {
    return this.tourProgress.asObservable();
  }

  updateTourProgress(data: tourProgress) {
    this.tourProgress.next(data);
  }

  public getCurrentRoute(): Observable<any> {
    return this.currentRoute.asObservable();
  }

  updateCurrentRoute(data: any) {
    this.currentRoute.next(data);
  }
}
