import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import * as UAParser from "ua-parser-js";
import { DatePipe } from "@angular/common";

import { SnackbarService } from "../../../../../../shared/components/toast/snackbar.service";
import { isfUniqueTitleValidator, uniqueTitleValidator } from "../../../../../../shared/validators/unique-title-async-validators";
import { GlobalConstants } from "../../../../../../shared/utils/util-types";
import { FormsService } from "../../../../../../shared/services/forms.service";
import { isfUniqueVersionValidator, uniqueDocVersionValidator } from "../../../../../../shared/validators/unique-document-version-validators";
import { TrialManagementService } from "../../../../../../features/trial-master-file/trial-management/trial-management.service";
import { SiteWorkingService } from "../../../site-working.service";
import { AppSettings } from '../../../../../../app.settings';
import { Settings } from '../../../../../../app.settings.model';
import { getFormValidationErrors } from "../../../../../../shared/validators/formValidationErrors";
import { StudyReferenceFileService } from "../../../../../../features/investigator-site-file/study-reference-file/study-reference-file.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MMM-YYYY",
  },
  display: {
    dateInput: "DD-MMM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD-MMM-YYYY",
    monthYearA11yLabel: "MMM YYYY",
  },
};
@Component({
  selector: "app-add-edit-lab-record-dev",
  templateUrl: "./add-edit-lab-record-dev.component.html",
  styleUrls: ["./add-edit-lab-record-dev.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class AddEditLabRecordDevComponent implements OnInit {
  public settings: Settings;
  public form: FormGroup;
  maxDateLimit = new Date();
  documentStatusList: Array<any>;
  currCRUDType: any;
  isFileTypeValid: boolean = true;
  editedDocId: number;
  fileUploadFormData: File;
  currData: any;
  submitted: boolean = false;
  alphaNumericPattern = "^[a-zA-Z0-9]$";
  numberRegEx = "^[0-9]{1,2}([.][0-9]{1,2})?$";
  userAgentDetails: any;
  public craForm: FormGroup;
  public ackForm: FormGroup;
  Submitted: boolean = false;
  userInfo: any;
  invalidPassword: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddEditLabRecordDevComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private datePipe: DatePipe,
    public fb: FormBuilder,
    private snackBar: SnackbarService,
    private globalConstants: GlobalConstants,
    private formsService: FormsService,
    private trialManagementService: TrialManagementService,
    private siteWorkingService: SiteWorkingService,
    public appSettings: AppSettings,
    private studyRefService: StudyReferenceFileService,
  ) {
    dialogRef.disableClose = true;
    this.settings = this.appSettings.settings;
    let { type, data } = this.modalData;
    this.craForm = this.fb.group({
      id: [''],
      browser: [''],
      auditHistory: [''],
      email: [''],
      password: ['', Validators.required],
      craVerificationStatus: ['', Validators.required],
      craVerificationBy: [''],
    })
    this.ackForm = this.fb.group({
      userId: [''],
      browser: [''],
      docUploadId: [''],
      email: [''],
      password: ['', Validators.required],
    })
    this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    this.form = fb.group({
      sponsorId: [{ value: "", disabled: true }],
      projectId: [{ value: "", disabled: true }],
      categoryId: [{ value: "", disabled: true }],
      subCategoryId: [{ value: "", disabled: true }],
      contentTypeId: [{ value: "", disabled: true }],
      subContentTypeId: [{ value: "", disabled: true }],
      documentStatus: ["", Validators.required],
      documentTitle: ["", [Validators.required, Validators.pattern(/^(?:[a-zA-Z0-9\s]+)?$/)]],
      versionNo: ["", [Validators.required, Validators.pattern(/^[0-9\.]{1,5}$/)]],
      documentDate: [""],
      comments: [""],
      qcVerificationRequired: [""],
      craVerificationRequired: [""],
      piSiteTeamAckRequired: [""],
    });

    let parser = new UAParser();
    this.userAgentDetails = parser.getResult();
    console.log(`Complete Result Object `, this.userAgentDetails);
  }

  ngOnInit(): void {
    console.log("ModalData", this.modalData);
    this.currCRUDType = this.modalData.type;
    this.currData = this.modalData.data;

    let { type, data } = this.modalData;

    if (type === "add") {
      this.form.patchValue({
        sponsorId: data.sponsor,
        projectId: data.project,
        categoryId: data.category,
        subCategoryId: data.subcategory ? data.subcategory : "-",
        contentTypeId: data.contentType ? data.contentType : "-",
        subContentTypeId: data.subContentTypeId ? data.subContentTypeId : "-",
      }),
        this.form.addControl("comments", this.fb.control(""));
      this.form.addControl(
        "documentStatus",
        this.fb.control("", Validators.required)
      );
      this.form.addControl(
        "uploadDoc",
        this.fb.control("", Validators.required)
      );
      this.form.addControl(
        "documentTitle",
        this.fb.control("", {
          validators: [Validators.required, Validators.pattern(this.alphaNumericPattern)],
          asyncValidators: [
            isfUniqueTitleValidator(
              this.siteWorkingService,
              this.modalData.projectId,
              this.currData.siteId,
              this.modalData.categoryId,
              this.modalData.subCategoryId,
              this.modalData.contentId ? this.modalData.contentId : 0,
              this.modalData.subContentTypeId ? data.subContentTypeId : 0
            ),
          ],
        })
      );
      this.form.addControl(
        "versionNo",
        this.fb.control("", {
          validators: [Validators.required, Validators.pattern(this.numberRegEx), Validators.maxLength(5), Validators.minLength(1)],
          asyncValidators: [
            isfUniqueVersionValidator(
              this.siteWorkingService,
              this.form.controls?.documentTitle.value,
              data.projectId,
              this.currData.siteId,
              data.categoryId,
              data.subCategoryId,
              data.contentId ? data.contentId : 0,
              data.subContentTypeId ? data.subContentTypeId : 0
            ),
          ],
        })
      );
      this.form.addControl(
        "documentDate",
        this.fb.control("", Validators.required)
      );
      this.form.addControl("comments", this.fb.control("")),
        this.form.addControl(
          "qcVerificationRequired",
          this.fb.control("", Validators.required)
        ),
        this.form.addControl(
          "piSiteTeamAckRequired",
          this.fb.control("", Validators.required)
        ),
        this.form.addControl(
          "craVerificationRequired",
          this.fb.control("", Validators.required)
        );
      this.form?.get("documentStatus")?.valueChanges.subscribe((value) => {
        console.log(`Document Status `, value);
        if (value === "newDocument") {
          this.form.get("documentTitle").enable();
          this.form.get("documentTitle").patchValue("");
          this.form
            .get("documentTitle")
            .setAsyncValidators(
              isfUniqueTitleValidator(
                this.siteWorkingService,
                data.projectId,
                this.currData.siteId,
                data.categoryId,
                data.subCategoryId,
                data.contentId ? data.contentId : 0,
                data.subContentTypeId ? data.subContentTypeId : 0
              )
            );
          this.form.get("versionNo").clearAsyncValidators();
          this.form.get("versionNo").patchValue("");
        }
        if (value !== "newDocument") {
          this.form.get("documentTitle").disable();
          this.form.get("documentTitle").patchValue(value);
          this.form.get("versionNo").patchValue("");
          const currDocumentVersion = this.documentStatusList.find(
            (item) => item.documentTitle === value
          ).documentVersion;
          this.form
            .get("versionNo")
            .setAsyncValidators(
              isfUniqueVersionValidator(
                this.siteWorkingService,
                this.form.controls?.documentTitle.value,
                data.projectId,
                this.currData.siteId,
                data.categoryId,
                data.subCategoryId,
                data.contentId ? data.contentId : 0,
                data.subContentTypeId ? data.subContentTypeId : 0
              ),
            );
          // this.form.get("versionNo").patchValue(currDocumentVersion);
        }
      });
    } else if (type === "edit") {
      this.editedDocId = data.id;
      this.form.get("documentStatus").disable();
      this.form.patchValue({
        sponsorId: data.sponsor,
        projectId: data.project,
        categoryId: data.category,
        subCategoryId: data.subcategory ? data.subcategory : "-",
        contentTypeId: data.contentType ? data.contentType : "-",
        subContentTypeId: data.subContentType ? data.subContentType : "-",
        documentStatus: data.documentTitle,
        documentTitle: data.documentTitle,
        versionNo: data.versionNo,
        documentDate: data.versionDate,
        comments: data.comments,
        qcVerificationRequired: JSON.stringify(data.qcVerificationRequired),
        craVerificationRequired: JSON.stringify(data.craVerificationRequired),
        piSiteTeamAckRequired: JSON.stringify(data.siteTeamAcknowledgementRequired),
      });
      this.form.get('documentStatus').disable();
    }
    this.siteWorkingService
      .getDocumentTitleList(
        data.projectId,
        this.currData.siteId,
        data.categoryId,
        data.subCategoryId,
        data.contentId ? data.contentId : 0,
        data.subContentTypeId ? data.subContentTypeId : 0
      )
      .subscribe((resp: any) => {
        this.documentStatusList = resp.filter(el => el.documentStatus !== 'DELETED');
        console.log(`documentStatusList`, resp);
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  onFileSelected(fileUpEve, e) {
    const validFileExtArray = ["pdf", "jpg", "png", "bmp", "jpeg"];

    if (!fileUpEve?.files) return;
    console.log(fileUpEve?.files);
    this.isFileTypeValid = this.formsService.validateFileType(
      fileUpEve.files[0],
      validFileExtArray
    );
    if (!this.isFileTypeValid)
      return this.snackBar.openSnackBar(`Unsupported file format `, true);

    this.fileUploadFormData = fileUpEve.files[0];
    console.log("fileUploadFormData", this.fileUploadFormData);
    this.form.controls?.uploadDoc?.patchValue(this.fileUploadFormData);

    console.log(fileUpEve?.files);
  }

  decideDocStatus = () => {
    if (
      parseInt(this.form.controls?.craVerificationRequired.value) === 1 &&
      parseInt(this.form.controls?.qcVerificationRequired.value) === 0
    ) {
      return this.globalConstants.UNDER_CRA_REVIEW;
    }
    if (
      parseInt(this.form.controls?.craVerificationRequired.value) === 1 &&
      parseInt(this.form.controls?.qcVerificationRequired.value) === 1
    ) {
      return this.globalConstants.UNDER_QC_REVIEW;
    }
    if (
      parseInt(this.form.controls?.craVerificationRequired.value) === 0 &&
      parseInt(this.form.controls?.qcVerificationRequired.value) === 1
    ) {
      return this.globalConstants.UNDER_QC_REVIEW;
    }
    if (
      parseInt(this.form.controls?.craVerificationRequired.value) === 0 &&
      parseInt(this.form.controls?.qcVerificationRequired.value) === 0
    ) {
      return this.globalConstants.UPLOADED;
    }
    if (
      this.currCRUDType === "add" &&
      this.form.controls?.documentStatus?.value === "newDocument"
    )
      return this.globalConstants.UPLOADED;
    if (this.currCRUDType === "edit") return this.globalConstants.UPLOADED;

    return this.globalConstants.SUPERSEDED;
  };

  getTitleDocumentId() {
    if (
      this.form.controls?.documentStatus?.value !== "newDocument"
    ) {
      let Id: any;
      Id = this.documentStatusList?.find(
        (item) => item.documentTitle === this.form.controls.documentTitle.value
      )?.documentTitleId;
      if (Id === undefined || 0) {
        return 0;
      } else {
        return Id;
      }
    } else {
      let id: any;
      id = this.documentStatusList?.find(
        (item) => item.documentTitle === this.form.controls.documentTitle.value
      )?.documentTitleId;
      console.log("ExistDocId-->", id);
      if (id === undefined || 0) {
        console.log("id", id);
        return 0;
      } else {
        console.log("id", id);
        return id;
      }
    }
  }


  getVersionDate() {
    if (this.currCRUDType === "add" || this.currCRUDType === "edit")
      return this.datePipe.transform(
        this.form.controls?.documentDate.value,
        "yyyy-MM-dd"
      );
  }

  getDocumentTitle() {
    if (this.currCRUDType === "add" || this.currCRUDType === "edit")
      return this.form.getRawValue().documentTitle;
  }

  submitForm() {
    this.submitted = true;
    console.log("Form-Values", this.form.getRawValue());
    let { data } = this.modalData;
    console.log(this.form.valid);
    this.form.markAllAsTouched();

    console.log(JSON.stringify(this.form.getRawValue(), null, 2));
    let err = getFormValidationErrors(this.form);
    console.log("--errList", err.errList);
    if (!this.form.valid) {
      if (err['totalErrors'] === 1) {
        if (err.errList[0].errType !== "titleAlreadyExists") {
          return;
        }
      } else if (err['totalErrors'] > 1) {
        return;
      }
    };
    const fd = new FormData();

    const addApiDataFormat = {
      siteId: this.currData.siteId ? this.currData.siteId : 0,
      projectId: this.currData.projectId ? this.currData.projectId : 0,
      croId: "",
      sponsorId: this.currData.sponsorId ? this.currData.sponsorId : 0,
      categoryId: this.currData.categoryId ? this.currData.categoryId : 0,
      subCategoryId: this.currData.subCategoryId
        ? this.currData.subCategoryId
        : 0,
      contentTypeId: this.currData.contentId
        ? this.currData.contentId
        : 0,
      subContentTypeId: this.currData.subContentTypeId
        ? this.currData.subContentTypeId
        : 0,
      fileType: "ISF",
      documentNo: this.currCRUDType === "edit" ? this.editedDocId : 0,
      documentFileName: "",
      documentLocation: "",
      documentTitle: this.getDocumentTitle(),
      documentStage: "",
      documentStatus: this.decideDocStatus(),
      versionNo: this.form.controls?.versionNo?.value
        ? this.form.controls.versionNo.value
        : 0,
      versionDate: this.getVersionDate(),
      craVerificationRequired: this.form.controls.craVerificationRequired.value,
      piSiteTeamAckRequired: this.form.controls.piSiteTeamAckRequired.value,
      comments: this.form.controls.comments.value,
      browser: this.userAgentDetails,
      auditHistory: JSON,
      qcVerificationRequired: this.form.controls.qcVerificationRequired.value,
      documentType: "",
      documentTitleId: this.getTitleDocumentId(),
      documentId: this.currCRUDType === 'edit' ? this.editedDocId : 0,
    };
    if (this.currCRUDType !== "edit") {
      fd.append("file", this.fileUploadFormData);
    }

    fd.append("siteId", addApiDataFormat.siteId);
    fd.append("projectId", addApiDataFormat.projectId);
    fd.append("croId", addApiDataFormat.croId);
    fd.append("sponsorId", addApiDataFormat.sponsorId);
    fd.append("categoryId", addApiDataFormat.categoryId);
    fd.append("subCategoryId", addApiDataFormat.subCategoryId);
    fd.append("contentTypeId", addApiDataFormat.contentTypeId);
    fd.append("subContentTypeId", addApiDataFormat.subContentTypeId);
    fd.append("fileType", addApiDataFormat.fileType);
    fd.append("documentFileName", addApiDataFormat.documentFileName);
    fd.append("documentLocation", addApiDataFormat.documentLocation);
    fd.append("documentTitle", addApiDataFormat.documentTitle);
    fd.append("documentStage", addApiDataFormat.documentStage);
    fd.append("documentStatus", addApiDataFormat.documentStatus);
    fd.append("versionNo", addApiDataFormat.versionNo);
    fd.append("versionDate", this.datePipe.transform(addApiDataFormat.versionDate, 'yyyy-MM-dd'));
    fd.append(
      "craVerificationRequired",
      addApiDataFormat.craVerificationRequired
    );
    fd.append("piSiteTeamAckRequired", addApiDataFormat.piSiteTeamAckRequired);
    fd.append("comments", addApiDataFormat.comments);
    fd.append("browser", JSON.stringify(addApiDataFormat.browser));
    fd.append(
      "qcVerificationRequired",
      addApiDataFormat.qcVerificationRequired
    );
    fd.append("documentType", addApiDataFormat.documentType);
    fd.append('documentTitleId', addApiDataFormat.documentTitleId);
    fd.append('documentId', JSON.stringify(addApiDataFormat.documentId));
    this.settings.loadingModal = true;
    console.log("FormData", addApiDataFormat);
    if (this.currCRUDType === "add") {
      console.log("ApiDataFormat", addApiDataFormat);
      this.siteWorkingService.create175To177TableRecord(fd).subscribe(
        (resp) => {
          this.settings.loadingModal = false;
          console.log("New Record Added", resp);
          this.snackBar.openSnackBar("New Record Added");
          this.dialogRef.close(true);
        },
        (error) => {
          console.log("Error at Creating Record", JSON.stringify(error));
          if (error.message) {
            this.snackBar.openSnackBar(error.message, true);
          } else {
            this.snackBar.openSnackBar(error, true);
          }
        }
      );
    }
    if (this.currCRUDType === "edit") {
      this.siteWorkingService
        .update175To177TableRecord(this.currData.id, fd)
        .subscribe(
          (resp) => {
            this.settings.loadingModal = false;
            console.log("Record Updated", resp);
            this.snackBar.openSnackBar("Record Updated");
            this.dialogRef.close(true);
          },
          (error) => {
            console.log("Error at  Record Update", JSON.stringify(error));
            if (error.message) {
              this.snackBar.openSnackBar(error.message, true);
            } else {
              this.snackBar.openSnackBar(error, true);
            }
          }
        );
    }
  }

  craSubmitForm() {
    this.Submitted = true;
    this.craForm.patchValue({
      id: this.modalData.data.id,
      browser: JSON.stringify(this.userAgentDetails),
      auditHistory: this.modalData.data.auditHistory,
      email: this.userInfo.username,
      password: this.craForm.controls.password.value,
      craVerificationStatus: this.craForm.controls.craVerificationStatus.value,
      craVerificationBy: this.userInfo.tokenid,
    });
    console.log("FormValues:", this.craForm.value);
    console.log("FormValid:", this.craForm.valid);
    if (this.craForm.valid) {
      let data = {
        id: this.modalData.data.id,
        browser: JSON.stringify(this.userAgentDetails),
        auditHistory: this.modalData.data.auditHistory,
        email: this.userInfo.username,
        password: this.craForm.controls.password.value,
        craVerificationStatus: parseInt(this.craForm.controls.craVerificationStatus.value),
        craVerificationBy: this.userInfo.tokenid,
      };
      this.studyRefService
        .update2xCraVerification(data).subscribe({
          next: (res: any) => {
            console.log("CRA-Resp-->", res);
            this.dialogRef.close(res);
          },
          error: (err: any) => {
            if (err.message === 'Please Enter Valid Credentials') {
              this.invalidPassword = true;
            } else {
              this.snackBar.openSnackBar(err.message, true);
            }
          }
        })
    }
  }

  ackSubmitForm() {
    this.Submitted = true;
    this.ackForm.patchValue({
      userId: this.userInfo.tokenid,
      browser: JSON.stringify(this.userAgentDetails),
      docUploadId: this.modalData.data.id,
      email: this.userInfo.username,
      password: this.ackForm.controls.password.value,
    });
    console.log("FormValues:", this.ackForm.value);
    console.log("FormValid:", this.ackForm.valid);
    if (this.ackForm.valid) {
      let data = {
        userId: this.userInfo.tokenid,
        browser: JSON.stringify(this.userAgentDetails),
        docUploadId: this.modalData.data.id,
        email: this.userInfo.username,
        password: this.ackForm.controls.password.value,
      };
      this.studyRefService.updatesiteteamackdetails(this.modalData.data.id, data).subscribe({
        next: (res: any) => {
          console.log("ACK-Resp-->", res);
          this.dialogRef.close(res);
        },
        error: (err: any) => {
          if (err.message === 'Please Enter Valid Credentials') {
            this.invalidPassword = true;
          } else {
            this.snackBar.openSnackBar(err.message, true);
          }
        }
      })
    }
  }
}
