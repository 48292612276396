import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class NavSideMenuService {
  apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getAllISFSidebarMenuList() {
    const currEndPoint = this.apiUrl + `users/getallisfmenulist`;
    return this.http.get(currEndPoint);
  }

  getAllISFSidebarMenuListByRoleId(roleId: number) {
    const currEndPoint =
      this.apiUrl + `users/getallisfmenulistbyroleid/${roleId}`;
    return this.http.get(currEndPoint);
  }

  getAllTMFSidebarMenuList() {
    const currEndPoint = this.apiUrl + `users/getalltmfmenulist`;
    return this.http.get(currEndPoint);
  }

  getAllTMFSidebarMenuListByRoleId(roleId: number) {
    const currEndPoint =
      this.apiUrl + `users/getalltmfmenulistbyroleid/${roleId}`;
    return this.http.get(currEndPoint);
  }

  getAllAdminSidebarMenuList() {
    const currEndPoint = this.apiUrl + `users/getalladminmenulist`;
    return this.http.get(currEndPoint);
  }

  getAllAdminSidebarMenuListByRoleId(roleId: number) {
    const currEndPoint =
      this.apiUrl + `users/getalladminmenulistbyroleid/${roleId}`;
    return this.http.get(currEndPoint);
  }

  getAllISFReportSidebarMenuList() {
    const currEndPoint = this.apiUrl + `users/getallisfreportmenulist`;
    return this.http.get(currEndPoint);
  }

  getAllISFReportSidebarMenuListByRoleId(roleId: number) {
    const currEndPoint =
      this.apiUrl + `users/getallisfreportmenulistbyroleid/${roleId}`;
    return this.http.get(currEndPoint);
  }

  getAllTMFReportSidebarMenuList() {
    const currEndPoint = this.apiUrl + `users/getalltmfreportmenulist`;
    return this.http.get(currEndPoint);
  }

  getAllTMFReportSidebarMenuListByRoleId(roleId: number) {
    const currEndPoint =
      this.apiUrl + `users/getalltmfreportmenulistbyroleid/${roleId}`;
    return this.http.get(currEndPoint);
  }
}
