import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, UrlSegment, NavigationEnd } from "@angular/router";
import { BehaviorSubject } from 'rxjs';
import { AppHttpService } from '../../shared/services/app-http.service';
import { Observable, of } from 'rxjs'
import { catchError, mapTo, tap } from 'rxjs/operators'
import { Settings } from '../../app.settings.model';
import { AppSettings } from '../../app.settings';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  getUserDetails$ = new BehaviorSubject<any>(null);
  redirectUrl: UrlSegment[] = [];
  public settings: Settings;

  constructor(public appSettings: AppSettings, private httpService: AppHttpService, private router: Router) {
    this.settings = this.appSettings.settings;
  }

  login(user: { username: string; password: string }) {
    return this.httpService.postLogin('auth/login', user)
  }

  getUserDetails(user: any) {
    return this.httpService.postLogin('auth/role', user);
  }
  updateUserDetails(user: any) {
    localStorage.setItem('USER_INFO', JSON.stringify(user));
    // this.getUserDetails$.next(user);
    if (user.roleName === 'ROLE_GRAVITY_QA_ADMIN' || user.roleName === 'ROLE_GRAVITY_QA_USER') {
      this.navigateToDashboard('/qc-dashboard', true)
    } else {
      this.navigateToDashboard('/dashboard', false);
    }
  }

  setAccessToken(token: string) {
    localStorage.setItem('ACCESS_TOKEN', token)
  }

  getAccessToken() {
    return localStorage.getItem('ACCESS_TOKEN')
  }

  setRefreshToken(token: string) {
    localStorage.setItem('REFRESH_TOKEN', token)
  }

  getRefreshToken() {
    return localStorage.getItem('REFRESH_TOKEN')
  }

  setSessionData = (key: string, value: any) => {
    sessionStorage.setItem(key, value);
  }
  getSessionData = (key: string) => {
    return sessionStorage.getItem(key) ? JSON.parse(sessionStorage.getItem(key)) : null;
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.getUserDetails$.next(null);
    this.router.navigate(['/']);
  }
  isLoggedIn() {
    const user = localStorage.getItem('USER_INFO');
    // this.getUserDetails$.next(JSON.parse(user));
    return !!user;
  }

  navigateToDashboard(url: string = null, isQC = false) {
    this.settings.menu = sessionStorage.sidenavIsPinned == 'true' && !url ? 'vertical' : 'horizontal';
    this.settings.sidenavIsPinned = sessionStorage.sidenavIsPinned == 'true' && !url ? true : true;
    if (url && !isQC) {
      this.router.navigate(['/dashboard']);
    }

    if (url && isQC) {
      this.router.navigate([url]);
    }
  }
  redirectToLoginPage() {
    if (!window.location.href.includes('/reset-password')) {
      this.router.navigate(['/']);
    }
  }




}
