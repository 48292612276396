import { Injectable } from '@angular/core'
import { NavSideMenuService } from 'app/theme/components/menu/nav-side-menu.service'

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {

  ls
  userInfo
  roleName
  dashboardInfo
  menuList: any
  activeISFMenuData: any

  constructor(private readonly menuService: NavSideMenuService) { }

  async toggleButton(roleId: string, activeTMFMenuTitle: any, type: any) {
    // console.log('--- Role Name :' + roleId)
    // console.log('--- activeTMFMenuTitle :' + activeTMFMenuTitle)
    let readonly = false
    const shortListedControlAccess = [];
    if (roleId === null || undefined) {
      console.error("RoleId is Null || undefined");
    }
    if (sessionStorage.getItem('menulist')) {
      this.menuList = JSON.parse(sessionStorage.getItem('menulist'));
    } else {
      if (type === 'TMF') {
        this.menuList = await this.menuService.getAllTMFSidebarMenuListByRoleId(parseInt(roleId)).toPromise();
      } else if (type === 'ISF') {
        this.menuList = await this.menuService.getAllISFSidebarMenuListByRoleId(parseInt(roleId)).toPromise();
      }
    }
    if (this.menuList !== null) {

      for (var i = 0; i < this.menuList.length; i++) {
        var item = this.menuList[i];
        if (item.title === activeTMFMenuTitle) {
          readonly = item.add
          readonly = item.isFileNote
          readonly = item.edit
          readonly = item.download
          readonly = item.print
          readonly = item.deleted
          //print
          //edit
          //download 
          //...all
          shortListedControlAccess.push(item);
        }
      }
    }

    return shortListedControlAccess;
  }

  //End of toggle
}
