import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  constructor() { }
  
  // a fileUpload validator so we are not pass
  validateFileType(file: File, acceptedFileTypes) {
    const isTypeAccepted = acceptedFileTypes.findIndex(item => item === file.name.split('.')[1])
    console.log(`File is `, file)
    return isTypeAccepted !== -1 ? true : false 
  }
}