import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { MatMenuTrigger } from '@angular/material/menu';
import  { GlobalConstants } from '../../../../common/global-constants';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems:Array<any>;
  public settings: Settings;
  userInfo:any;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  currRoleName: any
  constructor(
    public appSettings:AppSettings, 
    public menuService:MenuService, 
    public router:Router
    ) { 
    this.settings = this.appSettings.settings;
    this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
    this.currRoleName = this.userInfo.roleName
    // console.log(`Curr Role Name `, userInfo, this.currRoleName)
  }

  ngOnInit() {
    // console.log("this.currRoleName-->",this.currRoleName,GlobalConstants.lt);
    if(this.currRoleName === 'ROLE_GRAVITY_QA_ADMIN') {
      // console.log("Running 1!");
      const menuItems = this.menuService.getHorizontalMenuItems();
      this.menuItems = menuItems.filter(menuItem => menuItem.parentId == this.menuParentId && (menuItem.title === 'QC' || menuItem.title === 'Admin' || menuItem.title === 'User'))
    } else if(this.currRoleName === 'ROLE_GRAVITY_QA_USER') {
      // console.log("Running 2!");
      this.menuItems = [];
    } else if(this.currRoleName === GlobalConstants.superadmin || this.currRoleName === GlobalConstants.admin){ 
      // console.log("Admin Check");
      this.menuItems = this.menuService.getHorizontalMenuItems();
      this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId && ( item.title !== 'QC' ||  item.title !== 'Admin' || item.title !== 'User'));
    } else if(
         this.currRoleName === GlobalConstants.pi ||
         this.currRoleName === GlobalConstants.si ||
         this.currRoleName === GlobalConstants.sc ||
         this.currRoleName === GlobalConstants.sn ||
         this.currRoleName === GlobalConstants.ph ||
         this.currRoleName === GlobalConstants.llh||
         this.currRoleName === GlobalConstants.lt 
    ){
      console.log("Running Site Menus");
      if(this.userInfo.qaAccess !== 'NO') {
        const menuItems = this.menuService.getHorizontalMenuItems();
        this.menuItems = menuItems.filter(el => el.parentId === this.menuParentId && (el.title == 'Home'  || el.title === 'Admin' || el.title === 'User'));
      } else {
        const menuItems = this.menuService.getHorizontalMenuItems();
        this.menuItems = []
      }
    } else if(
      this.currRoleName === GlobalConstants.crpm  ||
      this.currRoleName === GlobalConstants.crdm  ||
      this.currRoleName === GlobalConstants.crqa  ||
      this.currRoleName === GlobalConstants.crcra ||
      this.currRoleName === GlobalConstants.crcta 
     ){ 
        console.log("Running CRO Menus",this.userInfo);
        if(this.userInfo.qaAccess !== 'NO') {
          const menuItems = this.menuService.getHorizontalMenuItems();
          this.menuItems = menuItems.filter(el => el.parentId === this.menuParentId && (el.title == 'Home' ));
        } else {
          const menuItems = this.menuService.getHorizontalMenuItems();
          this.menuItems = [];
        }
      } else {
      // console.log('Else Block');
     }
  }

  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(this.settings.fixedHeader){
          let mainContent = document.getElementById('main-content');
          if(mainContent){
            mainContent.scrollTop = 0;
          }
        }
        else{
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }                
    });
  } 

}