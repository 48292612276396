import { Menu } from "./menu.model";

// export const verticalProjectMenuItems = [
//   new Menu(
//     1,
//     "Dashboard",
//     "/project/dashboard",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),

//   new Menu(2, "1-Trial Management", null, null, "computer", null, true, 0),

//   new Menu(
//     3,
//     "1.1-Trial Oversight",
//     null,
//     null,
//     "record_voice_over",
//     null,
//     true,
//     2
//   ),
//   new Menu(
//     4,
//     "1.1.1-Trial Master File Plan",
//     "project/trial-management/trial-oversight/ct1",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     5,
//     "1.1.2-Trial Management Plan",
//     "project/trial-management/trial-oversight/ct2",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     7,
//     "1.1.3-Quality Plan",
//     "project/trial-management/trial-oversight/ct3",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     8,
//     "1.1.4-List of SOPs Current During Trial",
//     "project/trial-management/trial-oversight/ct4",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     9,
//     "1.1.5-Operational Procedure Manual",
//     "project/trial-management/trial-oversight/ct5",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     10,
//     "1.1.6-Recruitment Plan",
//     "project/trial-management/trial-oversight/ct6",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     11,
//     "1.1.7-Communication Plan",
//     "project/trial-management/trial-oversight/ct7",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     12,
//     "1.1.8-Monitoring Plan",
//     "project/trial-management/trial-oversight/ct8",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     13,
//     "1.1.9-Medical Monitoring Plan",
//     "project/trial-management/trial-oversight/ct9",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     14,
//     "1.1.10-Publication Policy",
//     "project/trial-management/trial-oversight/ct10",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     15,
//     "1.1.11-Debarment Statement",
//     "project/trial-management/trial-oversight/ct11",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     16,
//     "1.1.12-Trial Status Report",
//     "project/trial-management/trial-oversight/ct12",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     17,
//     "1.1.13-Investigator Newsletter",
//     "project/trial-management/trial-oversight/ct13",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     18,
//     "1.1.14-Audit Certificate",
//     "project/trial-management/trial-oversight/ct14",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     19,
//     "1.1.15-Filenote Master List",
//     "project/trial-management/trial-oversight/ct15",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     20,
//     "1.1.16-Risk Management Plan",
//     "project/trial-management/trial-oversight/ct16",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     21,
//     "1.1.17-Vendor Management Plan",
//     "project/trial-management/trial-oversight/ct17",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     22,
//     "1.1.18-Roles and Responsibility Matrix",
//     "project/trial-management/trial-oversight/ct18",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     23,
//     "1.1.19-Transfer of Regulatory Obligations",
//     "project/trial-management/trial-oversight/ct19",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),
//   new Menu(
//     24,
//     "1.1.20-Operational Oversight",
//     "project/trial-management/trial-oversight/ct20",
//     "",
//     "",
//     null,
//     false,
//     3
//   ),

//   new Menu(25, "1.2-Trial Team", null, null, "groups", null, true, 2),
//   new Menu(
//     26,
//     "1.2.1-Trial Team Details",
//     "project/trial-management/trial-team-details/ct1",
//     "",
//     "",
//     null,
//     false,
//     25
//   ),
//   new Menu(
//     27,
//     "1.2.2-Trial Team Curriculum Vitae",
//     "project/trial-management/trial-oversight/ct21",
//     "",
//     "",
//     null,
//     false,
//     25
//   ),

//   new Menu(28, "1.3-Trial Committee", null, null, "recycling", null, true, 2),
//   new Menu(
//     29,
//     "1.3.1-Committee Process",
//     "project/trial-management/trial-oversight/ct22",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     30,
//     "1.3.2-Committee Member List",
//     "project/trial-management/trial-oversight/ct23",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     31,
//     "1.3.3-Committee Output",
//     "project/trial-management/trial-oversight/ct24",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     32,
//     "1.3.4-Committee Member Curriculum Vitae",
//     "project/trial-management/trial-oversight/ct25",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     33,
//     "1.3.5-Committee Member Financial Disclosure Form",
//     "project/trial-management/trial-oversight/ct26",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     34,
//     "1.3.6-Committee Member Contract",
//     "project/trial-management/trial-oversight/ct27",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     35,
//     "1.3.7-Committee Member Confidentiality Disclosure Agreement",
//     "project/trial-management/trial-oversight/ct28",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),

//   new Menu(36, "1.4-Meetings", null, null, "social_distance", null, true, 2),
//   new Menu(
//     37,
//     "1.4.1-Kick off Meeting Material",
//     "project/trial-management/trial-oversight/ct29",
//     "",
//     "",
//     null,
//     false,
//     36
//   ),
//   new Menu(
//     38,
//     "1.4.2-Trial Team Training Material",
//     "project/trial-management/trial-oversight/ct30",
//     "",
//     "",
//     null,
//     false,
//     36
//   ),
//   new Menu(
//     39,
//     "1.4.3-Investigators Meeting Material",
//     "project/trial-management/trial-oversight/ct31",
//     "",
//     "",
//     null,
//     false,
//     36
//   ),
//   new Menu(
//     40,
//     "1.4.4-Trial Team Evidence of Training",
//     "project/trial-management/trial-oversight/ct32",
//     "",
//     "",
//     null,
//     false,
//     36
//   ),

//   new Menu(41, "1.5-General", null, null, "transform", null, true, 2),
//   new Menu(
//     42,
//     "1.5.1-Relevant Communications",
//     "project/general/meet-comms-track-info/ct1",
//     "",
//     "",
//     null,
//     false,
//     41
//   ),
//   new Menu(
//     43,
//     "1.5.2-Tracking Information",
//     "project/general/meet-comms-track-info/ct2",
//     "",
//     "",
//     null,
//     false,
//     41
//   ),
//   new Menu(
//     44,
//     "1.5.3-Other Meeting Material",
//     "project/general/meet-comms-track-info/ct3",
//     "",
//     "",
//     null,
//     false,
//     41
//   ),
//   new Menu(
//     45,
//     "1.5.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     41
//   ),

//   new Menu(
//     46,
//     "2-Central Trial Documents",
//     null,
//     null,
//     "document_scanner",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     47,
//     "2.1-Product and Trial Documentation",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     46
//   ),
//   new Menu(
//     48,
//     "2.1.1-Investigators Brochure",
//     "project/central-trial-docs/prod-trial-sub-docs/ct33",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     49,
//     "2.1.2-Protocol",
//     "project/central-trial-docs/prod-trial-sub-docs/ct34",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     50,
//     "2.1.3-Protocol Synopsis",
//     "project/central-trial-docs/prod-trial-sub-docs/ct35",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     51,
//     "2.1.4-Protocol Amendment",
//     "project/central-trial-docs/prod-trial-sub-docs/ct36",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     52,
//     "2.1.5-Financial Disclosure Summary",
//     "project/central-trial-docs/prod-trial-sub-docs/ct37",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     53,
//     "2.1.6-Insurance",
//     "project/central-trial-docs/prod-trial-sub-docs/ct38",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     54,
//     "2.1.7-Sample Case Report Form",
//     "project/central-trial-docs/prod-trial-sub-docs/ct39",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     55,
//     "2.1.10-Report of Prior Investigations",
//     "project/central-trial-docs/prod-trial-sub-docs/ct40",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),
//   new Menu(
//     56,
//     "2.1.11-Marketed Product Material",
//     "project/central-trial-docs/prod-trial-sub-docs/ct41",
//     "",
//     "",
//     null,
//     false,
//     47
//   ),

//   new Menu(
//     57,
//     "2.2-Subject Documentation",
//     null,
//     null,
//     "integration_instructions",
//     null,
//     true,
//     46
//   ),
//   new Menu(
//     58,
//     "2.2.1-Subject Diary",
//     "project/central-trial-docs/prod-trial-sub-docs/ct42",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),
//   new Menu(
//     59,
//     "2.2.2-Subject Questionnaire",
//     "project/central-trial-docs/prod-trial-sub-docs/ct43",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),
//   new Menu(
//     60,
//     "2.2.3-Informed Consent Form",
//     "project/central-trial-docs/prod-trial-sub-docs/ct44",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),
//   new Menu(
//     61,
//     "2.2.4-Subject Information Sheet",
//     "project/central-trial-docs/prod-trial-sub-docs/ct45",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),
//   new Menu(
//     62,
//     "2.2.5-Subject Participation Card",
//     "project/central-trial-docs/prod-trial-sub-docs/ct46",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),
//   new Menu(
//     63,
//     "2.2.6-Advertisements for Subject Recruitment",
//     "project/central-trial-docs/prod-trial-sub-docs/ct47",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),
//   new Menu(
//     64,
//     "2.2.7-Other Information Given to Subjects",
//     "project/central-trial-docs/prod-trial-sub-docs/ct48",
//     "",
//     "",
//     null,
//     false,
//     57
//   ),

//   new Menu(65, "2.3-Reports", null, null, "wysiwyg", null, true, 46),
//   new Menu(
//     66,
//     "2.3.1-Clinical Study Report",
//     "project/central-trial-docs/prod-trial-sub-docs/ct49",
//     "",
//     "",
//     null,
//     false,
//     65
//   ),
//   new Menu(
//     67,
//     "2.3.2-Bioanalytical Report",
//     "project/central-trial-docs/prod-trial-sub-docs/ct50",
//     "",
//     "",
//     null,
//     false,
//     65
//   ),

//   new Menu(68, "2.4-General", null, null, "transform", null, true, 46),
//   new Menu(
//     69,
//     "2.4.1-Relevant Communications",
//     "project/general/relevant-track-meet-two-x/ct4",
//     "",
//     "",
//     null,
//     false,
//     68
//   ),
//   new Menu(
//     70,
//     "2.4.2-Tracking Information",
//     "project/general/relevant-track-meet-two-x/ct5",
//     "",
//     "",
//     null,
//     false,
//     68
//   ),
//   new Menu(
//     71,
//     "2.4.3-Meeting Material",
//     "project/general/relevant-track-meet-two-x/ct6",
//     "",
//     "",
//     null,
//     false,
//     68
//   ),
//   new Menu(
//     72,
//     "2.4.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     68
//   ),

//   new Menu(73, "3-Regulatory", null, null, "recent_actors", null, true, 0),

//   new Menu(74, "3.1-Trial Approval", null, null, "fact_check", null, true, 73),
//   new Menu(
//     75,
//     "3.1.1-Regulatory Submission",
//     "project/regulatory/trial-appv-invest-report/ct51",
//     "",
//     "",
//     null,
//     false,
//     74
//   ),
//   new Menu(
//     76,
//     "3.1.2-Regulatory Approval Notification",
//     "project/regulatory/trial-appv-invest-report/ct52",
//     "",
//     "",
//     null,
//     false,
//     74
//   ),
//   new Menu(
//     77,
//     "3.1.3-Notification of Regulatory Identification Number",
//     "project/regulatory/trial-appv-invest-report/ct53",
//     "",
//     "",
//     null,
//     false,
//     74
//   ),
//   new Menu(
//     78,
//     "3.1.4-Public Registration",
//     "project/regulatory/trial-appv-invest-report/ct54",
//     "",
//     "",
//     null,
//     false,
//     74
//   ),

//   new Menu(
//     79,
//     "3.2-Investigational Medicinal Product",
//     null,
//     null,
//     "connect_without_contact",
//     null,
//     true,
//     73
//   ),
//   new Menu(
//     80,
//     "3.2.1-Import or Export License Application",
//     "project/regulatory/trial-appv-invest-report/ct55",
//     "",
//     "",
//     null,
//     false,
//     79
//   ),
//   new Menu(
//     81,
//     "3.2.2-Import or Export License",
//     "project/regulatory/trial-appv-invest-report/ct56",
//     "",
//     "",
//     null,
//     false,
//     79
//   ),

//   new Menu(
//     82,
//     "3.3-Trial Status Reporting",
//     null,
//     null,
//     "assignment_late",
//     null,
//     true,
//     73
//   ),
//   new Menu(
//     83,
//     "3.3.1-Notification to Regulatory Authority of Safety or Trial Information",
//     "project/regulatory/trial-appv-invest-report/ct57",
//     "",
//     "",
//     null,
//     false,
//     82
//   ),
//   new Menu(
//     84,
//     "3.3.2-Regulatory Progress Report",
//     "project/regulatory/trial-appv-invest-report/ct58",
//     "",
//     "",
//     null,
//     false,
//     82
//   ),
//   new Menu(
//     85,
//     "3.3.3-Regulatory Notification of Trial Termination",
//     "project/regulatory/trial-appv-invest-report/ct59",
//     "",
//     "",
//     null,
//     false,
//     82
//   ),

//   new Menu(86, "3.4-General", null, null, "transform", null, true, 73),
//   new Menu(
//     87,
//     "3.4.1-Relevant Communications",
//     "project/general/relevant-track-meet-three-x/ct7",
//     "",
//     "",
//     null,
//     false,
//     86
//   ),
//   new Menu(
//     88,
//     "3.4.2-Tracking Information",
//     "project/general/relevant-track-meet-three-x/ct8",
//     "",
//     "",
//     null,
//     false,
//     86
//   ),
//   new Menu(
//     89,
//     "3.4.3-Meeting Material",
//     "project/general/relevant-track-meet-three-x/ct9",
//     "",
//     "",
//     null,
//     false,
//     86
//   ),
//   new Menu(
//     90,
//     "3.4.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     86
//   ),

//   new Menu(
//     91,
//     "4-IRB or IEC and other approvals",
//     null,
//     null,
//     "grading",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     92,
//     "4.1-IRB or IEC Trial Approval",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     91
//   ),
//   new Menu(
//     93,
//     "4.1.1-IRB or IEC Submission",
//     "project/irb-iec-approvals/irb-iec-com-report/ct60",
//     "",
//     "",
//     null,
//     false,
//     92
//   ),
//   new Menu(
//     94,
//     "4.1.2-IRB or IEC Approval",
//     "project/irb-iec-approvals/irb-iec-com-report/ct61",
//     "",
//     "",
//     null,
//     false,
//     92
//   ),
//   new Menu(
//     95,
//     "4.1.3-IRB or IEC Composition",
//     "project/irb-iec-approvals/irb-iec-com-report/ct62",
//     "",
//     "",
//     null,
//     false,
//     92
//   ),
//   new Menu(
//     96,
//     "4.1.4-IRB or IEC Documentation of Non Voting Status",
//     "project/irb-iec-approvals/irb-iec-com-report/ct63",
//     "",
//     "",
//     null,
//     false,
//     92
//   ),
//   new Menu(
//     97,
//     "4.1.5-IRB or IEC Compliance Documentation",
//     "project/irb-iec-approvals/irb-iec-com-report/ct64",
//     "",
//     "",
//     null,
//     false,
//     92
//   ),

//   new Menu(
//     98,
//     "4.2-Other Committees",
//     null,
//     null,
//     "reduce_capacity",
//     null,
//     true,
//     91
//   ),
//   new Menu(
//     99,
//     "4.2.1-Other Submissions",
//     "project/irb-iec-approvals/irb-iec-com-report/ct65",
//     "",
//     "",
//     null,
//     false,
//     98
//   ),
//   new Menu(
//     100,
//     "4.2.2-Other Approvals",
//     "project/irb-iec-approvals/irb-iec-com-report/ct66",
//     "",
//     "",
//     null,
//     false,
//     98
//   ),

//   new Menu(
//     101,
//     "4.3-Trial Status Reporting",
//     null,
//     null,
//     "assignment_late",
//     null,
//     true,
//     91
//   ),
//   new Menu(
//     102,
//     "4.3.1-Notification to IRB or IEC of Safety Information",
//     "project/irb-iec-approvals/irb-iec-com-report/ct67",
//     "",
//     "",
//     null,
//     false,
//     101
//   ),
//   new Menu(
//     103,
//     "4.3.2-IRB or IEC Progress Report",
//     "project/irb-iec-approvals/irb-iec-com-report/ct68",
//     "",
//     "",
//     null,
//     false,
//     101
//   ),
//   new Menu(
//     104,
//     "4.3.3-IRB or IEC Notification of Trial Termination",
//     "project/irb-iec-approvals/irb-iec-com-report/ct69",
//     "",
//     "",
//     null,
//     false,
//     101
//   ),

//   new Menu(105, "4.4-General", null, null, "transform", null, true, 91),
//   new Menu(
//     106,
//     "4.4.1-Relevant Communications",
//     "project/general/relevant-track-meet-three-x/ct10",
//     "",
//     "",
//     null,
//     false,
//     105
//   ),
//   new Menu(
//     107,
//     "4.4.2-Tracking Information",
//     "project/general/relevant-track-meet-three-x/ct11",
//     "",
//     "",
//     null,
//     false,
//     105
//   ),
//   new Menu(
//     108,
//     "4.4.3-Meeting Material",
//     "project/general/relevant-track-meet-three-x/ct12",
//     "",
//     "",
//     null,
//     false,
//     105
//   ),
//   new Menu(
//     109,
//     "4.4.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     105
//   ),

//   new Menu(110, "5-Site Management", null, null, "grading", null, true, 0),

//   new Menu(
//     111,
//     "5.1-Site Selection",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     110
//   ),
//   new Menu(
//     112,
//     "5.1.1-Site Contact Details",
//     "project/site-management/site-contact-details",
//     "",
//     "",
//     null,
//     false,
//     111
//   ),
//   new Menu(
//     113,
//     "5.1.2-Confidentiality Agreement",
//     "project/site-management/site-related-central-trial-docs/ct1",
//     "",
//     "",
//     null,
//     false,
//     111
//   ),
//   new Menu(
//     114,
//     "5.1.3-Feasibility Documentation",
//     "project/site-management/site-related-central-trial-docs/ct2",
//     "",
//     "",
//     null,
//     false,
//     111
//   ),
//   new Menu(
//     115,
//     "5.1.4-PreStudy Visit Report and Letters",
//     "project/site-management/site-related-central-trial-docs/ct3",
//     "",
//     "",
//     null,
//     false,
//     111
//   ),
//   new Menu(
//     116,
//     "5.1.5-Sites Evaluated but not Selected",
//     "project/site-management/site-related-central-trial-docs/ct4",
//     "",
//     "",
//     null,
//     false,
//     111
//   ),

//   new Menu(117, "5.2-Site Setup", null, null, "fact_check", null, true, 110),
//   new Menu(
//     118,
//     "5.2.1-Acknowledgement of Investigator Brochure",
//     "project/site-management/site-related-central-trial-docs/ct5",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     119,
//     "5.2.2-Protocol Signature Page",
//     "project/site-management/site-related-central-trial-docs/ct6",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     120,
//     "5.2.3-Protocol Amendment Signature Page",
//     "project/site-management/site-related-central-trial-docs/ct7",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     121,
//     "5.2.4-Principal Investigator Curriculum Vitae",
//     "project/site-management/site-contact-det-fivefourtwox/ct1",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     122,
//     "5.2.5-Sub-Investigator Curriculum Vitae",
//     "project/site-management/site-contact-det-fivefourtwox/ct2",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     123,
//     "5.2.6-Other Curriculum Vitae",
//     "project/site-management/site-contact-det-fivefourtwox/ct3",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     124,
//     "5.2.7-Site Staff Qualification Supporting Information",
//     "project/site-management/site-contact-det-fivefourtwox/ct4",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     125,
//     "5.2.8-Form FDA 1572",
//     "project/site-management/site-contact-det-fivefourtwox/ct5",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     126,
//     "5.2.9-Investigator Regulatory Agreement",
//     "project/site-management/site-related-central-trial-docs/ct8",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     127,
//     "5.2.10-Financial Disclosure Form",
//     "project/site-management/site-contact-det-fivefourtwox/ct6",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     128,
//     "5.2.11-Data Privacy Agreement",
//     "project/site-management/site-contact-det-fivefourtwox/ct7",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     129,
//     "5.2.12-Clinical Trial Agreement",
//     "project/site-management/site-related-central-trial-docs/ct9",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     130,
//     "5.2.13-Indemnity",
//     "project/site-management/site-related-central-trial-docs/ct10",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     131,
//     "5.2.14-Other Financial Agreement",
//     "project/site-management/site-related-central-trial-docs/ct11",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     132,
//     "5.2.15-IP Site Release Documentation",
//     "project/site-management/site-related-central-trial-docs/ct12",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     133,
//     "5.2.16-Site Signature Sheet",
//     "project/site-management/site-related-central-trial-docs/ct13",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     134,
//     "5.2.17-Investigators Agreement (Device)",
//     "project/site-management/site-related-central-trial-docs/ct14",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),
//   new Menu(
//     135,
//     "5.2.18-Coordinating Investigator Documentation",
//     "project/site-management/site-related-central-trial-docs/ct15",
//     "",
//     "",
//     null,
//     false,
//     117
//   ),

//   new Menu(
//     136,
//     "5.3-Site Initiation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     110
//   ),
//   new Menu(
//     137,
//     "5.3.1-Trial Initiation Monitoring Report and Letters",
//     "project/site-management/site-related-central-trial-docs/ct16",
//     "",
//     "",
//     null,
//     false,
//     136
//   ),
//   new Menu(
//     138,
//     "5.3.2-Site Training Material",
//     "project/site-management/site-related-central-trial-docs/ct17",
//     "",
//     "",
//     null,
//     false,
//     136
//   ),
//   new Menu(
//     139,
//     "5.3.3-Site Evidence of Training",
//     "project/site-management/site-contact-det-fivefourtwox/ct8",
//     "",
//     "",
//     null,
//     false,
//     136
//   ),

//   new Menu(
//     140,
//     "5.4-Site Management",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     110
//   ),
//   new Menu(
//     141,
//     "5.4.1-Subject Log",
//     "project/site-management/site-contact-det-fivefourtwox/ct9",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     142,
//     "5.4.2-Source Data Verification",
//     "project/site-management/site-related-central-trial-docs/ct18",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     143,
//     "5.4.3-Monitoring Visit Report",
//     "project/site-management/site-related-central-trial-docs/ct19",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     144,
//     "5.4.4-Visit Log",
//     "project/site-management/site-contact-det-fivefourtwox/ct10",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     145,
//     "5.4.5-Additional Monitoring Activity",
//     "project/site-management/site-contact-det-fivefourtwox/ct11",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     146,
//     "5.4.6-Protocol Deviations",
//     "project/site-management/site-related-central-trial-docs/ct20",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     147,
//     "5.4.7-Financial Documentation",
//     "project/site-management/site-related-central-trial-docs/ct21",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     148,
//     "5.4.8-Final Trial Close Out Monitoring Report and Letters",
//     "project/site-management/site-related-central-trial-docs/ct22",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     149,
//     "5.4.9-Notification to Investigators of Safety Information",
//     "project/site-management/site-related-central-trial-docs/ct23",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     150,
//     "5.4.10-Subject Identification Log",
//     "project/site-management/site-related-central-trial-docs/ct24",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     151,
//     "5.4.11-Source Data",
//     "project/site-management/site-related-central-trial-docs/ct25",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     152,
//     "5.4.12-Monitoring Visit Confirmation and Follow-up Documentation",
//     "project/site-management/site-related-central-trial-docs/ct26",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),
//   new Menu(
//     153,
//     "5.4.13-Subject Eligibility Verification Forms and Worksheets",
//     "project/site-management/site-related-central-trial-docs/ct27",
//     "",
//     "",
//     null,
//     false,
//     140
//   ),

//   new Menu(154, "5.5-General", null, null, "transform", null, true, 110),
//   new Menu(
//     155,
//     "5.5.1-Relevant Communications",
//     "project/general/relevant-track-meet-five-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     154
//   ),
//   new Menu(
//     156,
//     "5.5.2-Tracking Information",
//     "project/general/relevant-track-meet-five-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     154
//   ),
//   new Menu(
//     157,
//     "5.5.3-Meeting Material",
//     "project/general/relevant-track-meet-five-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     154
//   ),
//   new Menu(
//     158,
//     "5.5.4-Filenote",
//     "project/sm_filenote-central",
//     "",
//     "",
//     null,
//     false,
//     154
//   ),

//   new Menu(
//     159,
//     "6-IP and Trial Supplies",
//     null,
//     null,
//     "grading",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     160,
//     "6.1-IP Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     159
//   ),
//   new Menu(
//     161,
//     "6.1.1-IP Supply Plan",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct1",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     162,
//     "6.1.2-IP Instructions for Handling",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct2",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     163,
//     "6.1.3-IP Sample Label",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct3",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     164,
//     "6.1.4-IP Shipment Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct4",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     165,
//     "6.1.5-IP Accountability Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct5",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     166,
//     "6.1.6-IP Transfer Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct6",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     167,
//     "6.1.7-IP Re Labeling Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct7",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     168,
//     "6.1.8-IP Recall Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct8",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     169,
//     "6.1.9-IP Quality Complaint Form",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct9",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     170,
//     "6.1.10-IP Return Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct10",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     171,
//     "6.1.11-IP Certificate of Destruction",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct11",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),
//   new Menu(
//     172,
//     "6.1.12-IP Retest and Expiry Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct12",
//     "",
//     "",
//     null,
//     false,
//     160
//   ),

//   new Menu(
//     173,
//     "6.2-IP Release Process Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     159
//   ),
//   new Menu(
//     174,
//     "6.2.1-QP (Qualified Person) Certification",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct13",
//     "",
//     "",
//     null,
//     false,
//     173
//   ),
//   new Menu(
//     175,
//     "6.2.2-IP Regulatory Release Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct14",
//     "",
//     "",
//     null,
//     false,
//     173
//   ),
//   new Menu(
//     176,
//     "6.2.3-IP Verification Statements",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct15",
//     "",
//     "",
//     null,
//     false,
//     173
//   ),
//   new Menu(
//     177,
//     "6.2.4-Certificate of Analysis",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct16",
//     "",
//     "",
//     null,
//     false,
//     173
//   ),

//   new Menu(
//     178,
//     "6.3-IP Allocation Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     159
//   ),
//   new Menu(
//     179,
//     "6.3.1-IP Treatment Allocation Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct17",
//     "",
//     "",
//     null,
//     false,
//     178
//   ),
//   new Menu(
//     180,
//     "6.3.2-IP Unblinding Plan",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct18",
//     "",
//     "",
//     null,
//     false,
//     178
//   ),
//   new Menu(
//     181,
//     "6.3.3-IP Treatment Decoding Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct19",
//     "",
//     "",
//     null,
//     false,
//     178
//   ),

//   new Menu(182, "6.4-Storage", null, null, "fact_check", null, true, 159),
//   new Menu(
//     183,
//     "6.4.1-IP Storage Condition Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct20",
//     "",
//     "",
//     null,
//     false,
//     182
//   ),
//   new Menu(
//     184,
//     "6.4.2-IP Storage Condition Excursion Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct21",
//     "",
//     "",
//     null,
//     false,
//     182
//   ),
//   new Menu(
//     185,
//     "6.4.3-Maintenance Logs (Device)",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct22",
//     "",
//     "",
//     null,
//     false,
//     182
//   ),

//   new Menu(
//     186,
//     "6.5-Non-IP Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     159
//   ),
//   new Menu(
//     187,
//     "6.5.1-Non-IP Supply Plan",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct23",
//     "",
//     "",
//     null,
//     false,
//     186
//   ),
//   new Menu(
//     188,
//     "6.5.2-Non-IP Shipment Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct24",
//     "",
//     "",
//     null,
//     false,
//     186
//   ),
//   new Menu(
//     189,
//     "6.5.3-Non-IP Return Documentation",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct25",
//     "",
//     "",
//     null,
//     false,
//     186
//   ),

//   new Menu(
//     190,
//     "6.6-Interactive Response Technology",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     159
//   ),
//   new Menu(
//     191,
//     "6.6.1-IRT User Requirement Specification",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct26",
//     "",
//     "",
//     null,
//     false,
//     190
//   ),
//   new Menu(
//     192,
//     "6.6.2-IRT Validation Certification",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct27",
//     "",
//     "",
//     null,
//     false,
//     190
//   ),
//   new Menu(
//     193,
//     "6.6.3-IRT User Acceptance Testing (UAT) Certification",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct28",
//     "",
//     "",
//     null,
//     false,
//     190
//   ),
//   new Menu(
//     194,
//     "6.6.4-IRT User Manual",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct29",
//     "",
//     "",
//     null,
//     false,
//     190
//   ),
//   new Menu(
//     195,
//     "6.6.5-IRT User Account Management",
//     "project/ip-trial-supplies/ip-doc-trial-supplies/ct30",
//     "",
//     "",
//     null,
//     false,
//     190
//   ),

//   new Menu(196, "6.7-General", null, null, "transform", null, true, 159),
//   new Menu(
//     197,
//     "6.7.1-Relevant Communications",
//     "project/general/relevant-track-meet-six-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     196
//   ),
//   new Menu(
//     198,
//     "6.7.2-Tracking Information",
//     "project/general/relevant-track-meet-six-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     196
//   ),
//   new Menu(
//     199,
//     "6.7.3-Meeting Material",
//     "project/general/relevant-track-meet-six-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     196
//   ),
//   new Menu(
//     200,
//     "6.7.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     196
//   ),

//   new Menu(201, "7-Safety Reporting", null, null, "grading", null, true, 0),

//   new Menu(
//     202,
//     "7.1-Safety Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     201
//   ),
//   new Menu(
//     203,
//     "7.1.1-Safety Management Plan",
//     "project/safety-reporting/safety-doc-trial-reporting/ct1",
//     "",
//     "",
//     null,
//     false,
//     202
//   ),
//   new Menu(
//     204,
//     "7.1.2-Pharmacovigilance Database Line Listing",
//     "project/safety-reporting/safety-doc-trial-reporting/ct2",
//     "",
//     "",
//     null,
//     false,
//     202
//   ),

//   new Menu(
//     205,
//     "7.2-Trial Status Reporting",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     201
//   ),
//   new Menu(
//     206,
//     "7.2.1-Expedited Safety Report",
//     "project/safety-reporting/safety-doc-trial-reporting/ct3",
//     "",
//     "",
//     null,
//     false,
//     205
//   ),
//   new Menu(
//     207,
//     "7.2.2-SAE Report",
//     "project/safety-reporting/safety-doc-trial-reporting/ct4",
//     "",
//     "",
//     null,
//     false,
//     205
//   ),
//   new Menu(
//     208,
//     "7.2.3-Pregnancy Report",
//     "project/safety-reporting/safety-doc-trial-reporting/ct5",
//     "",
//     "",
//     null,
//     false,
//     205
//   ),
//   new Menu(
//     209,
//     "7.2.4-Special Events of Interest",
//     "project/safety-reporting/safety-doc-trial-reporting/ct6",
//     "",
//     "",
//     null,
//     false,
//     205
//   ),

//   new Menu(210, "7.3-General", null, null, "transform", null, true, 201),
//   new Menu(
//     211,
//     "7.3.1-Relevant Communications",
//     "project/general/relevant-track-meet-seven-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     210
//   ),
//   new Menu(
//     212,
//     "7.3.2-Tracking Information",
//     "project/general/relevant-track-meet-seven-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     210
//   ),
//   new Menu(
//     213,
//     "7.3.3-Meeting Material",
//     "project/general/relevant-track-meet-seven-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     210
//   ),
//   new Menu(
//     214,
//     "7.3.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     210
//   ),

//   new Menu(
//     215,
//     "8-Central and Local Testing",
//     null,
//     null,
//     "grading",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     216,
//     "8.1-Facility Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     215
//   ),
//   new Menu(
//     217,
//     "8.1.1-Certification or Accreditation",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct1",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     218,
//     "8.1.2-Laboratory Validation Documentation",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct2",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     219,
//     "8.1.3-Laboratory Results Documentation",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct3",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     220,
//     "8.1.4-Normal Ranges",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct4",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     221,
//     "8.1.5-Manual",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct5",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     222,
//     "8.1.6-Supply Import Documentation",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct6",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     223,
//     "8.1.7-Head of Facility Curriculum Vitae",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct7",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),
//   new Menu(
//     224,
//     "8.1.8-Standardization Methods",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct8",
//     "",
//     "",
//     null,
//     false,
//     216
//   ),

//   new Menu(
//     225,
//     "8.2-Sample Documentation",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     215
//   ),
//   new Menu(
//     226,
//     "8.2.1-Specimen Label",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct9",
//     "",
//     "",
//     null,
//     false,
//     225
//   ),
//   new Menu(
//     227,
//     "8.2.2-Shipment Records",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct10",
//     "",
//     "",
//     null,
//     false,
//     225
//   ),
//   new Menu(
//     228,
//     "8.2.3-Sample Storage Condition Log",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct11",
//     "",
//     "",
//     null,
//     false,
//     225
//   ),
//   new Menu(
//     229,
//     "8.2.4-Sample Import or Export Documentation",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct12",
//     "",
//     "",
//     null,
//     false,
//     225
//   ),
//   new Menu(
//     230,
//     "8.2.5-Record of Retained Samples",
//     "project/central-local-test/cent-loc-facility-samp-docs/ct13",
//     "",
//     "",
//     null,
//     false,
//     225
//   ),

//   new Menu(231, "8.3-General", null, null, "transform", null, true, 215),
//   new Menu(
//     232,
//     "8.3.1-Relevant Communications",
//     "project/general/relevant-track-meet-eight-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     231
//   ),
//   new Menu(
//     233,
//     "8.3.2-Tracking Information",
//     "project/general/relevant-track-meet-eight-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     231
//   ),
//   new Menu(
//     234,
//     "8.3.3-Meeting Material",
//     "project/general/relevant-track-meet-eight-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     231
//   ),
//   new Menu(
//     235,
//     "8.3.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     231
//   ),

//   new Menu(236, "9-Third Parties", null, null, "grading", null, true, 0),

//   new Menu(
//     237,
//     "9.1-Third Parties Oversight",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     236
//   ),
//   new Menu(
//     238,
//     "9.1.1-Qualification and Compliance",
//     "project/third-parties/tp-oversight-setup/ct1",
//     "",
//     "",
//     null,
//     false,
//     237
//   ),
//   new Menu(
//     239,
//     "9.1.2-Third Party Curriculum Vitae",
//     "project/third-parties/tp-oversight-setup/ct2",
//     "",
//     "",
//     null,
//     false,
//     237
//   ),
//   new Menu(
//     240,
//     "9.1.3-Ongoing Third Party Oversight",
//     "project/third-parties/tp-oversight-setup/ct3",
//     "",
//     "",
//     null,
//     false,
//     237
//   ),

//   new Menu(
//     241,
//     "9.2-Third Party Set-Up",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     236
//   ),
//   new Menu(
//     242,
//     "9.2.1-Confidentiality Agreement",
//     "project/third-parties/tp-oversight-setup/ct4",
//     "",
//     "",
//     null,
//     false,
//     241
//   ),
//   new Menu(
//     243,
//     "9.2.2-Vendor Selection",
//     "project/third-parties/tp-oversight-setup/ct5",
//     "",
//     "",
//     null,
//     false,
//     241
//   ),
//   new Menu(
//     244,
//     "9.2.3-Contractual Agreement",
//     "project/third-parties/tp-oversight-setup/ct6",
//     "",
//     "",
//     null,
//     false,
//     241
//   ),

//   new Menu(245, "9.3-General", null, null, "transform", null, true, 236),
//   new Menu(
//     246,
//     "9.3.1-Relevant Communications",
//     "project/general/relevant-track-meet-nine-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     245
//   ),
//   new Menu(
//     247,
//     "9.3.2-Tracking Information",
//     "project/general/relevant-track-meet-nine-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     245
//   ),
//   new Menu(
//     248,
//     "9.3.3-Meeting Material",
//     "project/general/relevant-track-meet-nine-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     245
//   ),
//   new Menu(
//     249,
//     "9.3.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     245
//   ),

//   new Menu(250, "10-Data Management", null, null, "grading", null, true, 0),

//   new Menu(
//     251,
//     "10.1-Data Management Oversight",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     250
//   ),
//   new Menu(
//     252,
//     "10.1.1-Data Management Plan",
//     "project/data-management/data-over-db-edc-management/ct1",
//     "",
//     "",
//     null,
//     false,
//     251
//   ),

//   new Menu(253, "10.2-Data Capture", null, null, "fact_check", null, true, 250),
//   new Menu(
//     254,
//     "10.2.1-CRF Completion Requirements",
//     "project/data-management/data-capture/ct1",
//     "",
//     "",
//     null,
//     false,
//     253
//   ),
//   new Menu(
//     255,
//     "10.2.2-Annotated CRF",
//     "project/data-management/data-capture/ct2",
//     "",
//     "",
//     null,
//     false,
//     253
//   ),
//   new Menu(
//     256,
//     "10.2.3-Documentation of Corrections to Entered Data",
//     "project/data-management/data-capture/ct3",
//     "",
//     "",
//     null,
//     false,
//     253
//   ),
//   new Menu(
//     257,
//     "10.2.4-Final Subject Data",
//     "project/data-management/data-over-db-edc-management/ct2",
//     "",
//     "",
//     null,
//     false,
//     253
//   ),

//   new Menu(258, "10.3-Database", null, null, "fact_check", null, true, 250),
//   new Menu(
//     259,
//     "10.3.1-Database Specifications",
//     "project/data-management/data-over-db-edc-management/ct3",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     260,
//     "10.3.2-Edit Check Plan",
//     "project/data-management/data-over-db-edc-management/ct4",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     261,
//     "10.3.3-Edit Check Programming",
//     "project/data-management/data-over-db-edc-management/ct5",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     262,
//     "10.3.4-Edit Check Testing",
//     "project/data-management/data-over-db-edc-management/ct6",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     263,
//     "10.3.5-Approval for Database Activation",
//     "project/data-management/data-over-db-edc-management/ct7",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     264,
//     "10.3.6-External Data Transfer Specifications",
//     "project/data-management/data-over-db-edc-management/ct8",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     265,
//     "10.3.7-Data Entry Guidelines (Paper)",
//     "project/data-management/data-over-db-edc-management/ct9",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     266,
//     "10.3.8-SAE Reconciliation",
//     "project/data-management/data-over-db-edc-management/ct10",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     267,
//     "10.3.9-Dictionary Coding",
//     "project/data-management/data-over-db-edc-management/ct11",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     268,
//     "10.3.10-Data QC or QA Plan and Results",
//     "project/data-management/data-over-db-edc-management/ct12",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     269,
//     "10.3.11-Database Lock and Unlock Approval",
//     "project/data-management/data-over-db-edc-management/ct13",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),
//   new Menu(
//     270,
//     "10.3.12-Database Change Control",
//     "project/data-management/data-over-db-edc-management/ct14",
//     "",
//     "",
//     null,
//     false,
//     258
//   ),

//   new Menu(
//     271,
//     "10.4-EDC Management",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     250
//   ),
//   new Menu(
//     272,
//     "10.4.1-System Account Management",
//     "project/data-management/data-over-db-edc-management/ct15",
//     "",
//     "",
//     null,
//     false,
//     271
//   ),
//   new Menu(
//     273,
//     "10.4.2-Technical Design Document",
//     "project/data-management/data-over-db-edc-management/ct16",
//     "",
//     "",
//     null,
//     false,
//     271
//   ),
//   new Menu(
//     274,
//     "10.4.3-Validation Documents",
//     "project/data-management/data-over-db-edc-management/ct17",
//     "",
//     "",
//     null,
//     false,
//     271
//   ),

//   new Menu(275, "10.5-General", null, null, "transform", null, true, 250),
//   new Menu(
//     276,
//     "10.5.1-Relevant Communications",
//     "project/general/relevant-track-meet-ten-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     275
//   ),
//   new Menu(
//     277,
//     "10.5.2-Tracking Information",
//     "project/general/relevant-track-meet-ten-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     275
//   ),
//   new Menu(
//     278,
//     "10.5.3-Meeting Material",
//     "project/general/relevant-track-meet-ten-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     275
//   ),
//   new Menu(
//     279,
//     "10.5.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     275
//   ),

//   new Menu(280, "11-Statistics", null, null, "grading", null, true, 0),

//   new Menu(
//     281,
//     "11.1-Statistics Oversight",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     280
//   ),
//   new Menu(
//     282,
//     "11.1.1-Statistical Analysis Plan",
//     "project/statistics/stat-over-rand-analysis/ct1",
//     "",
//     "",
//     null,
//     false,
//     281
//   ),
//   new Menu(
//     283,
//     "11.1.2-Sample Size Calculation",
//     "project/statistics/stat-over-rand-analysis/ct2",
//     "",
//     "",
//     null,
//     false,
//     281
//   ),

//   new Menu(
//     284,
//     "11.2-Randomization",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     280
//   ),
//   new Menu(
//     285,
//     "11.2.1-Randomization Plan",
//     "project/statistics/stat-over-rand-analysis/ct3",
//     "",
//     "",
//     null,
//     false,
//     284
//   ),
//   new Menu(
//     286,
//     "11.2.2-Randomization Procedure",
//     "project/statistics/stat-over-rand-analysis/ct4",
//     "",
//     "",
//     null,
//     false,
//     284
//   ),
//   new Menu(
//     287,
//     "11.2.3-Master Randomization List",
//     "project/statistics/stat-over-rand-analysis/ct5",
//     "",
//     "",
//     null,
//     false,
//     284
//   ),
//   new Menu(
//     288,
//     "11.2.4-Randomization Programming",
//     "project/statistics/stat-over-rand-analysis/ct6",
//     "",
//     "",
//     null,
//     false,
//     284
//   ),
//   new Menu(
//     289,
//     "11.2.5-Randomization Sign Off",
//     "project/statistics/stat-over-rand-analysis/ct7",
//     "",
//     "",
//     null,
//     false,
//     284
//   ),
//   new Menu(
//     290,
//     "11.2.6-End of Trial or Interim Unblinding",
//     "project/statistics/stat-over-rand-analysis/ct8",
//     "",
//     "",
//     null,
//     false,
//     284
//   ),

//   new Menu(291, "11.3-Analysis", null, null, "fact_check", null, true, 280),
//   new Menu(
//     292,
//     "11.3.1-Data Definitions for Analysis Datasets",
//     "project/statistics/stat-over-rand-analysis/ct9",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     293,
//     "11.3.2-Analysis QC Documentation",
//     "project/statistics/stat-over-rand-analysis/ct10",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     294,
//     "11.3.3-Interim Analysis Raw Datasets",
//     "project/statistics/stat-over-rand-analysis/ct11",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     295,
//     "11.3.4-Interim Analysis Programs",
//     "project/statistics/stat-over-rand-analysis/ct12",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     296,
//     "11.3.5-Interim Analysis Datasets",
//     "project/statistics/stat-over-rand-analysis/ct13",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     297,
//     "11.3.6-Interim Analysis Output",
//     "project/statistics/stat-over-rand-analysis/ct14",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     298,
//     "11.3.7-Final Analysis Raw Datasets",
//     "project/statistics/stat-over-rand-analysis/ct15",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     299,
//     "11.3.8-Final Analysis Programs",
//     "project/statistics/stat-over-rand-analysis/ct16",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     300,
//     "11.3.9-Final Analysis Datasets",
//     "project/statistics/stat-over-rand-analysis/ct17",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     301,
//     "11.3.10-Final Analysis Output",
//     "project/statistics/stat-over-rand-analysis/ct18",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),
//   new Menu(
//     302,
//     "11.3.11-Subject Evaluability Criteria and Subject Classification",
//     "project/statistics/stat-over-rand-analysis/ct19",
//     "",
//     "",
//     null,
//     false,
//     291
//   ),

//   new Menu(303, "11.4-Report", null, null, "fact_check", null, true, 280),
//   new Menu(
//     304,
//     "11.4.1-Interim Statistical Reports",
//     "project/statistics/stat-over-rand-analysis/ct20",
//     "",
//     "",
//     null,
//     false,
//     303
//   ),
//   new Menu(
//     305,
//     "11.4.2-Statistical Report",
//     "project/statistics/stat-over-rand-analysis/ct21",
//     "",
//     "",
//     null,
//     false,
//     303
//   ),

//   new Menu(306, "11.5-General", null, null, "transform", null, true, 280),
//   new Menu(
//     307,
//     "11.5.1-Relevant Communications",
//     "project/general/relevant-track-meet-eleven-x/ct1",
//     "",
//     "",
//     null,
//     false,
//     306
//   ),
//   new Menu(
//     308,
//     "11.5.2-Tracking Information",
//     "project/general/relevant-track-meet-eleven-x/ct2",
//     "",
//     "",
//     null,
//     false,
//     306
//   ),
//   new Menu(
//     309,
//     "11.5.3-Meeting Material",
//     "project/general/relevant-track-meet-eleven-x/ct3",
//     "",
//     "",
//     null,
//     false,
//     306
//   ),
//   new Menu(
//     310,
//     "11.5.4-Filenote",
//     "project/general/filenote",
//     "",
//     "",
//     null,
//     false,
//     306
//   ),

//   new Menu(
//     311,
//     "Reports",
//     "/tmf-reports/all-docs",
//     null,
//     "grading",
//     null,
//     false,
//     0
//   ),
// ];

// export const verticalSiteMenuItems1 = [
//   new Menu(1, "Dashboard", "site/dashboard", null, "dashboard", null, false, 0),

//   new Menu(2, "1-Site Working File", null, null, "fact_check", null, true, 0),
//   new Menu(
//     3,
//     "1.1-Study Team Contact List",
//     "site/site-working-file/study-team-contact-list",
//     "",
//     "",
//     null,
//     false,
//     2
//   ),
//   new Menu(
//     4,
//     "1.2-Site Specific Informed Consent Forms",
//     "site/site-working-file/study-contact-list-x/site-specific-informed-consent-forms",
//     "",
//     "",
//     null,
//     false,
//     2
//   ),
//   new Menu(
//     5,
//     "1.3-Patient Diary",
//     "site/site-working-file/study-contact-list-x/patient-diary",
//     "",
//     "",
//     null,
//     false,
//     2
//   ),
//   new Menu(
//     6,
//     "1.4-Questionnaires",
//     "site/site-working-file/study-contact-list-x/questionnaires",
//     "",
//     "",
//     null,
//     false,
//     2
//   ),
//   new Menu(
//     7,
//     "1.5-Patient Advertisement Materials",
//     "site/site-working-file/study-contact-list-x/patient-advertisement-materials",
//     "",
//     "",
//     null,
//     false,
//     2
//   ),
//   new Menu(
//     8,
//     "1.6-Patient Letters",
//     "site/site-working-file/study-contact-list-x/patient-letters",
//     "",
//     "",
//     null,
//     false,
//     2
//   ),
//   new Menu(9, "1.7-Logs and Forms", null, null, "description", null, true, 2),
//   new Menu(
//     10,
//     "1.7.1-Patient screening and enrolment log",
//     "site/site-working-file/logs-and-forms/ct1",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     11,
//     "1.7.2-Informed consent log",
//     "site/site-working-file/logs-and-forms/ct2",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     12,
//     "1.7.3-Site personnel signature and delegation log",
//     "site/site-working-file/logs-and-forms/ct3",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     13,
//     "1.7.4-Site personnel training log",
//     "site/site-working-file/logs-and-forms/ct4",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     14,
//     "1.7.5-lab sample temperature log",
//     "site/site-working-file/logs-and-forms/ct5",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     15,
//     "1.7.6-Record of retention body fluids/tissue sample log",
//     "site/site-working-file/logs-and-forms/ct6",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     16,
//     "1.7.7-Protocol deviation log",
//     "site/site-working-file/logs-and-forms/ct7",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),
//   new Menu(
//     17,
//     "1.7.8-Subject Identification log",
//     "site/site-working-file/logs-and-forms/ct8",
//     "",
//     "",
//     null,
//     false,
//     9
//   ),

//   new Menu(
//     18,
//     "1.8-Monitoring / Audits",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     2
//   ),
//   new Menu(
//     19,
//     "1.8.1-Pre-study Visit Related Docs",
//     "site/site-working-file/monitoring-and-audits/ct1",
//     "",
//     "",
//     null,
//     false,
//     18
//   ),
//   new Menu(
//     20,
//     "1.8.2-Initiation Visit Related Docs",
//     "site/site-working-file/monitoring-and-audits/ct2",
//     "",
//     "",
//     null,
//     false,
//     18
//   ),

//   new Menu(
//     21,
//     "1.8.3-Monitoring Visit Related Docs",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     18
//   ),
//   new Menu(
//     22,
//     "1.8.3.1-Monitoring confirmation letters",
//     "site/site-working-file/monitoring-and-audits/ct3/sct1",
//     "",
//     "",
//     null,
//     false,
//     21
//   ),
//   new Menu(
//     23,
//     "1.8.3.2-Monitoring follow up letters",
//     "site/site-working-file/monitoring-and-audits/ct3/sct2",
//     "",
//     "",
//     null,
//     false,
//     21
//   ),
//   new Menu(
//     24,
//     "1.8.3.3-Monitoring visit log",
//     "site/site-working-file/monitoring-and-audits/ct3/sct3",
//     "",
//     "",
//     null,
//     false,
//     21
//   ),
//   new Menu(
//     25,
//     "1.8.3.4-Monitoring issues log",
//     "site/site-working-file/monitoring-and-audits/ct3/sct4",
//     "",
//     "",
//     null,
//     false,
//     21
//   ),

//   new Menu(
//     26,
//     "1.8.4-Close Out Visit Related Docs",
//     "site/site-working-file/monitoring-and-audits/ct4",
//     "",
//     "",
//     null,
//     false,
//     18
//   ),
//   new Menu(
//     27,
//     "1.8.5-Audit Related Correspondence and Reports",
//     "site/site-working-file/monitoring-and-audits/ct5",
//     "",
//     "",
//     null,
//     false,
//     18
//   ),

//   new Menu(
//     28,
//     "2-Study Reference File",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     29,
//     "2.1-Protocol and Amendments",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     28
//   ),
//   new Menu(
//     30,
//     "2.1.1-Approved Protocol",
//     "site/study-reference-file/protocol-and-amendments/ct1",
//     "",
//     "",
//     null,
//     false,
//     29
//   ),
//   new Menu(
//     31,
//     "2.1.2-Protocol signature Page",
//     "site/study-reference-file/protocol-and-amendments/ct2",
//     "",
//     "",
//     null,
//     false,
//     29
//   ),

//   new Menu(
//     32,
//     "2.2-Investigator Brochure and Addendum",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     28
//   ),
//   new Menu(
//     33,
//     "2.2.1-Approved IB",
//     "site/study-reference-file/investigator-brochure-and-addendum/ct1",
//     "",
//     "",
//     null,
//     false,
//     32
//   ),
//   new Menu(
//     34,
//     "2.2.2-IB Acknowledgements",
//     "site/study-reference-file/investigator-brochure-and-addendum/ct2",
//     "",
//     "",
//     null,
//     false,
//     32
//   ),

//   new Menu(
//     35,
//     "2.3-Data management",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     28
//   ),
//   new Menu(
//     36,
//     "2.3.1-CRF completion guidelines and Sample CRF",
//     "site/study-reference-file/data-management/ct1",
//     "",
//     "",
//     null,
//     false,
//     35
//   ),
//   new Menu(
//     37,
//     "2.3.2-Data clarification forms",
//     "site/study-reference-file/data-management/ct2",
//     "",
//     "",
//     null,
//     false,
//     35
//   ),

//   new Menu(
//     38,
//     "2.4-Safety management",
//     "site/study-reference-file/safety-management",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),

//   new Menu(
//     39,
//     "2.5-Central and Local laboratory",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     28
//   ),
//   new Menu(
//     40,
//     "2.5.1-Lab Manual",
//     "site/study-reference-file/central-and-local-laboratory/ct1",
//     "",
//     "",
//     null,
//     false,
//     39
//   ),
//   new Menu(
//     41,
//     "2.5.2-Accreditation",
//     "site/study-reference-file/central-and-local-laboratory/ct2",
//     "",
//     "",
//     null,
//     false,
//     39
//   ),
//   new Menu(
//     42,
//     "2.5.3-Normal ranges",
//     "site/study-reference-file/central-and-local-laboratory/ct3",
//     "",
//     "",
//     null,
//     false,
//     39
//   ),
//   new Menu(
//     43,
//     "2.5.4-Lab director CV",
//     "site/study-reference-file/central-and-local-laboratory/ct4",
//     "",
//     "",
//     null,
//     false,
//     39
//   ),
//   new Menu(
//     44,
//     "2.5.5-Central Laboratory Results",
//     "site/study-reference-file/central-and-local-laboratory/ct5",
//     "",
//     "",
//     null,
//     false,
//     39
//   ),

//   new Menu(
//     45,
//     "2.6-Study Level Essential Documents",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     28
//   ),
//   new Menu(
//     46,
//     "2.6.1-Import and Export Licences",
//     "site/study-reference-file/study-level-essential-documents/ct1",
//     "",
//     "",
//     null,
//     false,
//     45
//   ),
//   new Menu(
//     47,
//     "2.6.2-Study Insurance",
//     "site/study-reference-file/study-level-essential-documents/ct2",
//     "",
//     "",
//     null,
//     false,
//     45
//   ),
//   new Menu(
//     48,
//     "2.6.3-Indemnity",
//     "site/study-reference-file/study-level-essential-documents/ct3",
//     "",
//     "",
//     null,
//     false,
//     45
//   ),

//   new Menu(
//     49,
//     "2.7-IRT/IVRS/Study Equipment Management",
//     "site/study-reference-file/irt-ivrs-study-equipment-management",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),
//   new Menu(
//     50,
//     "2.8-Other Study Reference Materials and Worksheets",
//     "site/study-reference-file/other-study-reference-materials-and-worksheets",
//     "",
//     "",
//     null,
//     false,
//     28
//   ),

//   new Menu(
//     51,
//     "3-Site Reference File",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     52,
//     "3.1-Site Level Essential Documents",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     51
//   ),
//   new Menu(
//     53,
//     "3.1.1-Confidentiality Agreements",
//     "site/site-reference-file/site-level-essential-docs/ct1",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     54,
//     "3.1.2-Investigator Agreement",
//     "site/site-reference-file/site-level-essential-docs/ct2",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     55,
//     "3.1.3-FDA 1572",
//     "site/site-reference-file/site-level-essential-docs/ct3",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     56,
//     "3.1.4-Financial Disclosure",
//     "site/site-reference-file/site-level-essential-docs/ct4",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     57,
//     "3.1.5-Curriculum Vitae",
//     "site/site-reference-file/site-level-essential-docs/ct5",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     58,
//     "3.1.6-GCP Certification",
//     "site/site-reference-file/site-level-essential-docs/ct6",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     59,
//     "3.1.7-Medical Registration Certificate",
//     "site/site-reference-file/site-level-essential-docs/ct7",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     60,
//     "3.1.8-Site Team Contact List",
//     "site/site-reference-file/site-level-essential-docs/ct8",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     61,
//     "3.1.9-Training records",
//     "site/site-reference-file/site-level-essential-docs/ct9",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),
//   new Menu(
//     62,
//     "3.1.10-Privacy policies",
//     "site/site-reference-file/site-level-essential-docs/ct10",
//     "",
//     "",
//     null,
//     false,
//     52
//   ),

//   new Menu(
//     63,
//     "3.2-Correspondence",
//     "site/site-reference-file/correspondence",
//     "",
//     "",
//     null,
//     false,
//     51
//   ),
//   new Menu(
//     64,
//     "3.3-Other Site Reference Materials",
//     "site/site-reference-file/other-site-reference",
//     "",
//     "",
//     null,
//     false,
//     51
//   ),

//   new Menu(
//     65,
//     "4-Site Regulatory File",
//     null,
//     null,
//     "fact_check",
//     null,
//     true,
//     0
//   ),

//   new Menu(
//     66,
//     "4.1-Competent Authority",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     65
//   ),
//   new Menu(
//     67,
//     "4.1.1-Submissions",
//     "site/site-regulatory-file/competent-authority/ct1",
//     "",
//     "",
//     null,
//     false,
//     66
//   ),
//   new Menu(
//     68,
//     "4.1.2-Approvals",
//     "site/site-regulatory-file/competent-authority/ct2",
//     "",
//     "",
//     null,
//     false,
//     66
//   ),
//   new Menu(
//     69,
//     "4.1.3-Notifications",
//     "site/site-regulatory-file/competent-authority/ct3",
//     "",
//     "",
//     null,
//     false,
//     66
//   ),
//   new Menu(
//     70,
//     "4.1.4-Others",
//     "site/site-regulatory-file/competent-authority/ct4",
//     "",
//     "",
//     null,
//     false,
//     66
//   ),

//   new Menu(
//     71,
//     "4.2-Ethics Committee and R&D/IEC/IRB",
//     null,
//     null,
//     "description",
//     null,
//     true,
//     65
//   ),
//   new Menu(
//     72,
//     "4.2.1-Submissions",
//     "site/site-regulatory-file/ethics-committee-rd-iec-irb/ct1",
//     "",
//     "",
//     null,
//     false,
//     71
//   ),
//   new Menu(
//     73,
//     "4.2.2-Approvals",
//     "site/site-regulatory-file/ethics-committee-rd-iec-irb/ct2",
//     "",
//     "",
//     null,
//     false,
//     71
//   ),
//   new Menu(
//     74,
//     "4.2.3-Notifications",
//     "site/site-regulatory-file/ethics-committee-rd-iec-irb/ct3",
//     "",
//     "",
//     null,
//     false,
//     71
//   ),
//   new Menu(
//     75,
//     "4.2.4-SOP and Membership List",
//     "site/site-regulatory-file/ethics-committee-rd-iec-irb/ct4",
//     "",
//     "",
//     null,
//     false,
//     71
//   ),
//   new Menu(
//     76,
//     "4.2.5-Others",
//     "site/site-regulatory-file/ethics-committee-rd-iec-irb/ct5",
//     "",
//     "",
//     null,
//     false,
//     71
//   ),

//   new Menu(77, "5-Pharmacy File", null, null, "fact_check", null, true, 0),

//   new Menu(
//     78,
//     "5.1-IP handling and storage requirements",
//     "site/pharmacy-file/ip-storage-requirements",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     79,
//     "5.2-Initial and Resupply Authorizations",
//     "site/pharmacy-file/init-resupply-authorizations",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     80,
//     "5.3-Ordering and shipping documentation",
//     "site/pharmacy-file/order-ship-documentation",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     81,
//     "5.4-Randomized Treatment Assignment",
//     "site/pharmacy-file/rand-treatment-assignment",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     82,
//     "5.5-Final destruction Authorization",
//     "site/pharmacy-file/final-destruction-authorization",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     83,
//     "5.6-Decoding/Un-blinding Procedures for blinded trials",
//     "site/pharmacy-file/decode-proc-blinded-trials",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     84,
//     "5.7-Other IP related documents",
//     "site/pharmacy-file/other-ip-related-documents",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     85,
//     "5.8-Drug inventory log",
//     "site/pharmacy-file/drug-inventory-log",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     86,
//     "5.9-Drug dispensing and accountability log",
//     "site/pharmacy-file/drug-disp-accountability-log",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     87,
//     "5.10-Drug Destruction log or Return log",
//     "site/pharmacy-file/drug-destruction-return-log",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),
//   new Menu(
//     89,
//     "5.11-Pharmacy temperature log",
//     "site/pharmacy-file/pharmacy-temp-log",
//     "",
//     "",
//     null,
//     false,
//     77
//   ),

//   new Menu(90, "6-Safety File", null, null, "fact_check", null, true, 0),
//   new Menu(
//     91,
//     "6.1-IND safety reports",
//     "site/safety-file/ind-safety-reports",
//     "",
//     "",
//     null,
//     false,
//     90
//   ),
//   new Menu(
//     92,
//     "6.2-SAE and Pregnancy reports",
//     "site/safety-file/sae-and-pregnancy-reports",
//     "",
//     "",
//     null,
//     false,
//     90
//   ),
//   new Menu(
//     93,
//     "6.3-Safety specific correspondence",
//     "site/safety-file/safety-specific-correspondence",
//     "",
//     "",
//     null,
//     false,
//     90
//   ),
//   new Menu(
//     94,
//     "6.4-Other Safety Related Documents",
//     "site/safety-file/other-safety-related-documents",
//     "",
//     "",
//     null,
//     false,
//     90
//   ),

//   new Menu(
//     95,
//     "Other Study or Site Related Documents",
//     "site/other-study-site-related-docs",
//     null,
//     "description",
//     null,
//     false,
//     0
//   ),

//   new Menu(
//     96,
//     "Reports",
//     "/reports/all-docs",
//     null,
//     "description",
//     null,
//     false,
//     0
//   ),
// ];

// export const reportMenuItems = [
//   new Menu(
//     1,
//     "Project Dashboard",
//     "/project/dashboard",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     2,
//     "Site Dashboard",
//     "site/dashboard",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),

//   new Menu(
//     3,
//     "All Documents",
//     "/reports/all-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     4,
//     "Final Documents",
//     "/reports/final-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     5,
//     "Documents in QC",
//     "/reports/docs-in-qc",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     6,
//     "Superseded Documents",
//     "/reports/superseded-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     7,
//     "Empty Sections",
//     "/reports/empty-section-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     8,
//     "CRA Verification Status",
//     "/reports/cra-verification-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     9,
//     "PI/Site Team Signature Status",
//     "/reports/pi-site-team-sign-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
//   new Menu(
//     10,
//     "Safety Report",
//     "/reports/safety-report-docs",
//     null,
//     "dashboard",
//     null,
//     false,
//     0
//   ),
// ];

export const horizontalMenuItems = [
  new Menu(1, "Home", "/dashboard", null, "home", null, false, 0, 1),
  new Menu(2, "ORG", null, null, "computer", null, true, 0, 2),
  new Menu(3, "Sponsor", "/org/sponsor", null, "person", null, false, 2, 3),
  new Menu(4, "CRO", "/org/cro", null, "crop", null, false, 2, 4),
  new Menu(5, "Site", "/org/site", null, "web_asset", null, false, 2, 5),
  new Menu(6, "Resource", "/org/resource", null, "engineering", null, false, 2, 6),
  new Menu(
    7,
    "Project Mapping",
    null,
    null,
    "dashboard_customize",
    null,
    true,
    0
  ,7),
  new Menu(
    8,
    "Create Project",
    "/project-mapping/create-project",
    null,
    "create_new_folder",
    null,
    false,
    7
  , 8),
  new Menu(
    9,
    "Site Mapping",
    "/project-mapping/site",
    null,
    "web_asset",
    null,
    false,
    7
  , 9),
  new Menu(
    10,
    "Resource Mapping",
    "/project-mapping/resource",
    null,
    "engineering",
    null,
    false,
    7
  , 10),
  new Menu(
    11,
    "Drug Mapping",
    "/project-mapping/drug",
    null,
    "mouse",
    null,
    false,
    7
  , 11),

  new Menu(12, "QC", null, null, "dashboard_customize", null, true, 0, 12),
  new Menu(13, "Admin", "/qc-dashboard/admin", null, "person", null, false, 12, 13),
  new Menu(14, "User", "/qc-dashboard/user", null, "crop", null, false, 12, 14),
  new Menu(15, "Settings", null, null, "settings", null, true, 0, 15),
  new Menu(16, "Page Access Controls", "/settings/page-controls", null, "library_books", null, false, 15, 16),
  // new Menu(17, "Button Access Controls", "/settings/button-access-control", null, "track_changes", null, false, 15),

];
