import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class StudyReferenceFileService {
  apiUrl: string = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getPiSiteTeamAckDetails(id: number, resourceid: number) {
    const currEndPoint = this.apiUrl + `study-reference-file/getpisiteteamackdetails/${id}/${resourceid}`;
    return this.http.get(currEndPoint);
  }
  // 2x
  get2xPiSiteTeamAckDetails(docUploadId: number, resourceid: number) {
    const currEndPoint = this.apiUrl + `study-reference-file/getpisiteteamackdetailswithdocuploadid/${docUploadId}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  // 174
  getdetailsbyresourceid(id: number, resourceid: number) {
    const currEndPoint = this.apiUrl + `study-reference-file/getdetailsbyresourceid/${id}/${resourceid}`;
    return this.http.get(currEndPoint);
  }
  // getdetailsbyresourceid(id:number,resourceid:number) {
  //   const currEndPoint = this.apiUrl + `study-reference-file/getdetailsbyresourceid/${id}/${resourceid}`;
  //   return this.http.get(currEndPoint);
  // }

  updatesiteteamackdetails(id: number, data: any) {
    const currEndPoint = this.apiUrl + `study-reference-file/updatesiteteamackdetails/${id}`;
    return this.http.put(currEndPoint, data);
  }

  getCraResourceDetails(userid: number) {
    const currEndPoint = this.apiUrl + `study-reference-file/getcraresourcebyuserid/${userid}`;
    return this.http.get(currEndPoint);
  }

  // 2x
  get2xDataById(id: any) {
    const currEndPoint = this.apiUrl + `study-reference-file/get2xdocumentupload/${id}`;
    return this.http.get(currEndPoint);
  }

  getStudyReferenceFileList(data: any) {
    const currEndPoint =
      this.apiUrl + `study-reference-file/getstudyrefile2xseries`;
    return this.http.post(currEndPoint, data);
  }

  getPiSiteDetails(document_upload_id: number) {
    const currEndPoint =
      this.apiUrl +
      `cent-reg-irbiec-234x/get2xpisitedetails/${document_upload_id} `;
    return this.http.get(currEndPoint);
  }

  create2xRecordService(data: any) {
    const currEndPoint =
      this.apiUrl + `study-reference-file/createstudyrefile2xseries`;
    return this.http.post(currEndPoint, data);
  }

  update2xRecord(id: any, data: any) {
    const currEndPoint =
      this.apiUrl + `study-reference-file/updatestudyrefile2xseries/${id}`;
    return this.http.put(currEndPoint, data);
  }

  update2xCraVerification(data: any) {
    const currEndPoint =
      this.apiUrl + `study-reference-file/update2xcraverification`;
    return this.http.post(currEndPoint, data);
  }

  getPISiteDetails(id: any) {
    const currEndPoint = this.apiUrl + `study-reference-file/getisfsiteteamackdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  get174PISiteDetails(id: any) {
    const currEndPoint = this.apiUrl + `study-reference-file/getisfsitepersonneltrainingsiteteamackdetails/${id}`;
    return this.http.get(currEndPoint);
  }

  get2xGlobalAuditHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contentid: number,
    subcontentid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `study-reference-file/getisf2xglobalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contentid}/${subcontentid}`;
    return this.http.get(currEndPoint);
  }

  get7xGlobalAuditHistory(
    projectid: number,
    categoryid: number,
    subcategoryid: number,
    contentid: number,
    subcontentid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `study-reference-file/getisf7xglobalaudithistory/${projectid}/${categoryid}/${subcategoryid}/${contentid}/${subcontentid}`;
    return this.http.get(currEndPoint);
  }
}
