import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { SiteWorkingService } from "./site-working-file/site-working.service";
import { firstValueFrom } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class InvestigatorSiteFileService {
  apiUrl: string = environment.apiUrl;
  constructor(
    private siteWorkingService: SiteWorkingService,
    private http: HttpClient
  ) {}

  // Site Dashboard Endpoints
  async getSiteDocStatusData() {
    const currEndPoint = this.apiUrl + `users/getdashsitedocumentstatuschart`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }

  // Time vs Project Uploads API
  async getDocUpTimeChartData() {
    const currEndPoint =
      this.apiUrl + `organization/getcomparativeregiscountforsite`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }

  async getDocUpTimeWeekData() {
    const currEndPoint =
      this.apiUrl + `organization/getcomparativeregisweekdocforsite`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }

  async getDocUpTimeMonData() {
    const currEndPoint =
      this.apiUrl + `organization/getcomparativeregismonthdocforsite`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  // Resource chart
  async getSiteResourceChartData(projectid, siteid) {
    const currEndPoint =
      this.apiUrl +
      `organization/getdashboardresourcecountsite/${projectid}/${siteid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  // Category chart
  async getSiteCatDay(projectid, siteid) {
    const currEndPoint =
      this.apiUrl +
      `organization/getcategorydocsiteforday/${projectid}/${siteid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  async getSiteCatWeek(projectid, siteid) {
    const currEndPoint =
      this.apiUrl +
      `organization/getcategorydocsiteforweek/${projectid}/${siteid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  async getSiteCatMon(projectid, siteid) {
    const currEndPoint =
      this.apiUrl +
      `organization/getcategorydocsiteformonth/${projectid}/${siteid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  // Project vs site api
  async getDisDocCount() {
    const currEndPoint =
      this.apiUrl + `organization/getdistributeddocuploadcount`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }

  async getDisDocDetails(status: any) {
    const currEndPoint =
      this.apiUrl +
      `organization/getdistributeddocuploadprojectdetails/${status}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }

  //  Upload & Download Endpoints
  getPDFFileUrl(data: any) {
    const currEndPoint = this.apiUrl + `aws/securepdf/url`;
    return this.http.post(currEndPoint, data);
  }

  getPDFDownload(data: any) {
    const currEndPoint = this.apiUrl + `aws/secure/file`;
    return this.http.post(currEndPoint, data);
  }

  getImageFileUrl(data: any) {
    const currEndPoint = this.apiUrl + `aws/secureimage/url`;
    return this.http.post(currEndPoint, data);
  }

  getFileBuffer(data: any) {
    const httpOptions = {
      //'responseType'  : 'arraybuffer' as 'json'
      responseType: "blob" as "json", //This also worked
    };
    const currEndPoint = this.apiUrl + `aws/secure/file`;
    return this.http.post(currEndPoint, data, httpOptions);
  }

  getSecureSignedUrl(data: any) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/pdf");
    //headers.append('Accept', 'application/pdf');
    headers.append("Access-Control-Allow-Origin", "*");
    const currEndPoint = this.apiUrl + `aws/getsignedurl`;
    return this.http.post(currEndPoint, data, { headers });
  }

  getISFMenuTypeDetails(currActiveISFMenu) {
    let hierarchyResp: any;
    if (!currActiveISFMenu?.menuTitle && !currActiveISFMenu?.menuType) return;
    let activeMenuName = "";
    if (currActiveISFMenu?.menuTitle === "1.8.1-Pre-study Visit Related Docs") {
      activeMenuName = currActiveISFMenu?.menuTitle
        .split("-")
        .slice(1)
        .join("-");
    } else {
      activeMenuName = currActiveISFMenu?.menuTitle.split("-")[1].trim();
    }

    if (!activeMenuName) return;

    if (currActiveISFMenu.menuType === "category") {
      console.log("Name", activeMenuName);
      return this.siteWorkingService.categoriesByTitle({
        name: activeMenuName,
      });
    }
    if (currActiveISFMenu.menuType === "sub_category") {
      console.log("Name", activeMenuName);
      if (activeMenuName === "Decoding/Un") {
        activeMenuName = "Decoding/Un-blinding Procedures for blinded trials";
      }
      return this.siteWorkingService.getSubCategoriesByTitle({
        name: activeMenuName,
      });
    }
    if (currActiveISFMenu.menuType === "content_type") {
      console.log("Name", activeMenuName);
      return this.siteWorkingService.getContentTypeByTitle({
        name: activeMenuName,
      });

      // this.siteWorkingService.getContentTypeByTitle({name: activeMenuName}).subscribe(data => {
      //   console.log(`Content Type & Sub category & category Name by Title `, data)
      //   return data
      // })
    }
    if (currActiveISFMenu.menuType === "sub_content_type") {
      console.log("Name", activeMenuName);
      return this.siteWorkingService.getSubContentTypeByTitle({
        name: activeMenuName,
      });

      // this.siteWorkingService.getSubContentTypeByTitle({name: activeMenuName}).subscribe(data => {
      //   console.log(`SubContentType & Content Type & Sub category & category Name by Title `, data)
      //   return data
      // })
    }
  }

  // Get ResourceBy Userid
  getResourceByUserId(userid: number) {
    const currEndPoint = this.apiUrl + `users/getresourceatbyuserid/${userid}`;
    return this.http.get(currEndPoint);
  }

  //  Site Dash Navigation Api
  getsitedashnavidisplaynobycategoryid(categoryid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavidisplaynobycategoryid/${categoryid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnavidisplaynobysubcategoryid(
    categoryid: number,
    subcategoryid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavidisplaynobysubcategoryid/${categoryid}/${subcategoryid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnavidisplaynobycontenttypeid(
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavidisplaynobycontenttypeid/${categoryid}/${subcategoryid}/${contenttypeid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnavidisplaynobysubcontenttypeid(
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavidisplaynobysubcontenttypeid/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  getSiteNavByCategoryId(categoryid: number) {
    const currEndPoint =
      this.apiUrl + `dashboard/getsitedashnavigationbycategoryid/${categoryid}`;
    return this.http.get(currEndPoint);
  }

  getSiteNavBySubCategoryId(categoryid: number, subcategoryid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavigationbysubcategoryid/${categoryid}/${subcategoryid}`;
    return this.http.get(currEndPoint);
  }

  getSiteNavByContentId(
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavigationbycontenttypeid/${categoryid}/${subcategoryid}/${contenttypeid}`;
    return this.http.get(currEndPoint);
  }

  getSiteNavBySubContentId(
    categoryid: number,
    subcategoryid: number,
    contenttypeid: number,
    subcontenttypeid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnavigationbysubcontenttypeid/${categoryid}/${subcategoryid}/${contenttypeid}/${subcontenttypeid}`;
    return this.http.get(currEndPoint);
  }

  // Site Dashboard Api
  getSiteDashboardDataById(id: number) {
    const currEndPoint = this.apiUrl + `dashboard/getsitedashboard/${id}`;
    return this.http.get(currEndPoint);
  }

  getSiteDashboardList(siteid: number) {
    const currEndPoint =
      this.apiUrl + `dashboard/getsitedashboarddetails/${siteid}`;
    return this.http.get(currEndPoint);
  }

  createSiteDashboard(data: any) {
    const currEndPoint = this.apiUrl + `dashboard/createsitedashboard`;
    return this.http.post(currEndPoint, data);
  }

  updateSiteDashboard(id: any, data: any) {
    const currEndPoint = this.apiUrl + `dashboard/updatesitedashboard/${id}`;
    return this.http.put(currEndPoint, data);
  }

  // Project Dashboard APIs
  getProjectDashboardDataById(id: number) {
    const currEndPoint = this.apiUrl + `dashboard/getprojectdashboard/${id}`;
    return this.http.get(currEndPoint);
  }

  createProjectDashboad(data: any) {
    const currEndPoint = this.apiUrl + `dashboard/createprojectdashboard`;
    return this.http.post(currEndPoint, data);
  }

  updateProjectDashboard(id: number, data: any) {
    const currEndPoint = this.apiUrl + `dashboard/updateprojectdashboard/${id}`;
    return this.http.put(currEndPoint, data);
  }

  // -----------------------------------------

  // DashboardInfo
  getDashboardInfo(projectid: number) {
    const currEndPoint =
      this.apiUrl + `dashboard/getsitedashboardinfo/${projectid}`;
    return this.http.get(currEndPoint);
  }

  getProjectDashboardList(projectid: number) {
    const currEndPoint =
      this.apiUrl + `dashboard/getprojectdashboarddetails/${projectid}`;
    return this.http.get(currEndPoint);
  }

  // Role Based Site Dash Notification Api
  getsitedashnotifdocupload(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifdocupload/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifdocuploadrespro(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifdocuploadrespro/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }
  getsitedashnotifsiteperstrain(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifsiteperstrain/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashrolebaseddocuploadresproject(
    siteid: number,
    resourceid: number,
    projectid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashrolebaseddocuploadresproject/${siteid}/${resourceid}/${projectid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashrolebaseddocupload(
    siteid: number,
    projectid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashrolebaseddocupload/${siteid}/${projectid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  // Site Dashboard Doc Notification Api
  // getsitedashnotifdocupload(projectid: number, siteid: number) {
  //   const currEndPoint = this.apiUrl + `dashboard/getsitedashnotifdocupload/${projectid}/${siteid}`;
  //   return this.http.get(currEndPoint);
  // }

  // getsitedashnotifdocuploadrespro(projectid: number, siteid: number) {
  //   const currEndPoint = this.apiUrl + `dashboard/getsitedashnotifdocuploadrespro/${projectid}/${siteid}`;
  //   return this.http.get(currEndPoint);
  // }

  getsitedashnotifpatientscreen(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifpatientscreen/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifinformconslog(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifinformconslog/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifdruginvenlog(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifdruginvenlog/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifdrugdispensory(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifdrugdispensory/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifdrugdestrlog(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifdrugdestrlog/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifdrugdestrlogverification(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifdrugdestrlogverification/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifmonitissuelog(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifmonitissuelog/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifmonitissuelogrespmember(
    projectid: number,
    siteid: number,
    resourceid: number
  ) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashnotifmonitissuelogrespmember/${projectid}/${siteid}/${resourceid}`;
    return this.http.get(currEndPoint);
  }

  // Site Dashboard Empty Section Api

  getsitedashnotifempseccategorylist(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `isfreports/getsitedashnotifempseccategorylist/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifempsecsubcategorylist(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `isfreports/getsitedashnotifempsecsubcategorylist/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifempseccontenttypelist(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `isfreports/getsitedashnotifempseccontenttypelist/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashnotifempsecsubcontenttypelist(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `isfreports/getsitedashnotifempsecsubcontenttypelist/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  // Site Dashboard Essential Section Api
  getsitedashessdocdocuploadrespro(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocdocuploadrespro/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocsiteperstrain(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocsiteperstrain/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocpatientscreen(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocpatientscreen/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocinformconslog(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocinformconslog/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocdruginvenlog(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocdruginvenlog/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocdrugdispensory(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocdrugdispensory/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocUploadProFDA1572(projectid: number, siteid: number) {
    // 1
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocUploadProFDA1572/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocUploadProFDA1572forSI(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocUploadProFDA1572forSI/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocdrugdestrlogverification(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocdrugdestrlogverification/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  getsitedashessdocmonitissuelog(projectid: number, siteid: number) {
    const currEndPoint =
      this.apiUrl +
      `dashboard/getsitedashessdocmonitissuelog/${projectid}/${siteid}`;
    return this.http.get(currEndPoint);
  }

  //chart information

  getprojectpiechartstatus() {
    const currEndPoint = this.apiUrl + `users/getdashdocumentstatuschart`;
    return this.http.get(currEndPoint);
  }
  getprojectbardocUploaddday() {
    const currEndPoint =
      this.apiUrl + `organization/getcomparativeregiscountforproject `;
    return this.http.get(currEndPoint);
  }
  getprojectbardocUploadweek() {
    const currEndPoint =
      this.apiUrl + `organization/getcomparativeregisweekdocforproject`;
    return this.http.get(currEndPoint);
  }
  getprojectbardocUploadmonth() {
    const currEndPoint =
      this.apiUrl + `organization/getcomparativeregismonthdocforproject `;
    return this.http.get(currEndPoint);
  }
  getprojectpieResource(projectid) {
    const currEndPoint =
      this.apiUrl + `organization/getdashboardresourcecount/${projectid}`;
    return this.http.get(currEndPoint);
  }
  async getProjectCatDay(projectid) {
    const currEndPoint =
      this.apiUrl + `organization/getcategorydocprojectforday/${projectid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  async getProjectCatWeek(projectid) {
    const currEndPoint =
      this.apiUrl + `organization/getcategorydocprojectforweek/${projectid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  async getProjectCatMon(projectid) {
    const currEndPoint =
      this.apiUrl + `organization/getcategorydocprojectformonth/${projectid}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
  // Project vs site api
  async getDisDocCountproject() {
    const currEndPoint =
      this.apiUrl + `organization/getdistributeddocuploadprojectcount`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }

  async getDisDocDetailsproject(status: any) {
    const currEndPoint =
      this.apiUrl + `organization/getdistributeddocuploaddetails/${status}`;
    return await firstValueFrom(this.http.get(currEndPoint));
  }
}
