import { Component, OnInit, ViewChild, HostListener, ViewChildren, QueryList } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../app.settings';
import { Settings } from '../app.settings.model';
import { MenuService } from '../theme/components/menu/menu.service';
import { ProjectMappingService } from './project-mapping/project-mapping.service';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { DataTransferService } from '../shared/services/data-transfer.service';
import { GlobalConstants } from '../common/global-constants';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  providers: [MenuService]
})
export class PagesComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  @ViewChild('backToTop') backToTop: any;
  @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>;
  public settings: Settings;
  public menus = ['vertical', 'horizontal'];
  public menuOption: string;
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption: string;
  public lastScrollTop: number = 0;
  public showBackToTop: boolean = false;
  public toggleSearchBar: boolean = false;
  private defaultMenu: string; //declared for return default menu when window resized 
  projectList: Array<any>;
  siteList: Array<any>;
  form: FormGroup;
  dashboardData: any;
  currRoleName: string
  type: string = '';
  menuType: any;
  roleName: any;
  userInfo: any;

  constructor(
    public appSettings: AppSettings,
    public router: Router,
    public fb: FormBuilder,
    private menuService: MenuService,
    private projectMappingService: ProjectMappingService,
    private dataTranserService: DataTransferService,
  ) {
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      siteId: "",
      projectId: "",
      siteProjectId: "",
    })
    this.dashboardData = {}
    this.dataTranserService.getDashData.subscribe(res => {
      // console.log("DashData ==> ", res);
      this.dashboardData = sessionStorage.getItem('dashboardData') ? JSON.parse(sessionStorage.getItem('dashboardData')) : '';
      // console.log("DashBoard-Data ==> ", this.dashboardData);
      this.type = res.type;
      // console.log("MenuType==>", this.type);
      this.ngOnInit();
    });
  }

  ngOnInit() {
    if (this.type === 'type' && this.dashboardData['type'] !== null) {
      this.type = this.dashboardData['type'];
    }
    this.roleName = sessionStorage.getItem('roleName');
    this.userInfo = JSON.parse(localStorage.getItem('USER_INFO'));
    // console.log("MenuType==>", this.type);
    if (window.innerWidth <= 768) {
      this.settings.menu = this.settings.sidenavIsPinned ? 'vertical' : 'horizontal'
      this.settings.sidenavIsOpened = false;
    }
    setTimeout(() => {
      this.dashboardData = sessionStorage.getItem('dashboardData') ? JSON.parse(sessionStorage.getItem('dashboardData')) : '';
    }, 1000)

    if (this.type === 'site') {
      this.getIsfItems();
    } else if (this.type === 'project') {
      this.getTmfItems();
    }
  }


  // Getting Isf Sites & Projects
  getIsfItems() {
    //  Getting sites data
    if (this.roleName === GlobalConstants.superadmin ||
      this.roleName === GlobalConstants.admin) {
      this.projectMappingService.getISFDashboardDropdownSites().subscribe(
        (site: any) => {
          // console.log("Site-Items 1-->", site);
          this.siteList = site;
          // Getting Project Data
          let Data = sessionStorage.getItem('dashboardData') ? JSON.parse(sessionStorage.getItem('dashboardData')) : '';
          // console.log("Data-->", Data.siteId);
          this.projectMappingService.getProjectsBySiteId(Data.siteId).subscribe(
            (project: any) => {
              // console.log("ProjectList-1-->", project);
              this.projectList = project,
                this.form.patchValue({
                  siteId: JSON.stringify(Data.siteId),
                  siteProjectId: JSON.stringify(Data.projectId),
                });
              // console.log("Form-Value 1:", this.form.value);
            });
          // Project fetch end;
        })
    } else {
      this.projectMappingService.getIsfSiteByUserId(this.userInfo.tokenid).subscribe(
        (site: any) => {
          let arr: any[] = [];
          site.map((res: any) => {
            let item = {
              siteId: res.id,
              siteName: res.siteName,
            };
            arr.push(item);
          })
          this.siteList = arr;
          // console.log("Site-Items 2-->", this.siteList);
          // Getting Project Data
          let Data = sessionStorage.getItem('dashboardData') ? JSON.parse(sessionStorage.getItem('dashboardData')) : '';
          // console.log("Data-->", Data.siteId);
          this.projectMappingService.getIsfProjectBySiteId(Data.siteId, this.userInfo.tokenid).subscribe(
            (project: any) => {
              // console.log("ProjectList-2-->", project);
              this.projectList = project,
                this.form.patchValue({
                  siteId: JSON.stringify(Data.siteId),
                  siteProjectId: JSON.stringify(Data.projectId),
                });
              // console.log("Form-Value 2:", this.form.value);
            });
          // Project fetch end;
        })
    }
    // Site Fetch End ;
  }

  getTmfItems() {
    this.projectMappingService.getAllActiveProjects().subscribe(
      (project: any) => {
        // // console.log("Tmf-Project-->", project);
        this.projectList = project;
        let Data = sessionStorage.getItem('dashboardData') ? JSON.parse(sessionStorage.getItem('dashboardData')) : '';
        // // console.log("Data-->", Data);
        this.form.patchValue({
          projectId: JSON.stringify(Data.project),
        });
        // Tmf-Data fetch ends;
      });
  }

  selectionChange(type, item) {
    // ISF
    if (type === 'siteId') {
      this.form.patchValue({
        siteId: item.value,
      });
      // // // console.log(this.form.value);
      this.form.controls.siteProjectId.setValue("");
      this.projectMappingService.getProjectsBySiteId(item.value).subscribe(
        (project: any) => {
          this.projectList = project;
        }
      )
      // ISF
    } else if (type === "siteProjectId") {
      this.form.patchValue({
        siteProjectId: item.value
      })
      let data = {
        projectId: parseInt(this.form.controls?.siteProjectId.value),
        reports: "isf",
        siteId: parseInt(this.form.controls?.siteId.value),
        type: "site"
      }
      sessionStorage.setItem('dashboardData', JSON.stringify(data));
      this.dashboardData = sessionStorage.getItem('dashboardData') ? JSON.parse(sessionStorage.getItem('dashboardData')) : '';
      // // console.log("DashboardData:", this.dashboardData);
      this.router.navigate(['/site/dashboard'])
      this.dataTranserService.updateLandingPageISFData({
        siteId: parseInt(this.form.controls?.siteId.value),
        projectId: parseInt(this.form.controls?.siteProjectId.value),
      });
      this.ngOnInit();
      // TMF
    } else if (type === 'projectId') {
      this.form.patchValue({
        projectId: item.value
      })
      let data = {
        project: parseInt(this.form.controls?.projectId.value),
        reports: "tmf",
        type: "project",
      }
      sessionStorage.setItem('dashboardData', JSON.stringify(data));
      this.dashboardData = JSON.parse(sessionStorage.getItem('dashboardData'));
      this.router.navigate(['/project/dashboard'])
      this.dataTranserService.updateLandingPageTMFData({
        projectId: parseInt(this.form.controls?.projectId.value),
      });
      // console.log("DashboardData:", this.dashboardData);
      this.ngOnInit();
    }
  }



  ngAfterViewInit() {
    setTimeout(() => { this.settings.loading = false }, 300);
    this.backToTop.nativeElement.style.display = 'none';
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (!this.settings.sidenavIsPinned) {
          this.sidenav.close();
        }
        if (window.innerWidth <= 768) {
          this.sidenav.close();
        }
      }
    });
    if (this.settings.menu == "vertical") { }
    // this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
  }

  public chooseMenu() {
    this.settings.menu = this.menuOption;
    this.defaultMenu = this.menuOption;
    this.router.navigate(['/']);
  }

  public chooseMenuType() {
    this.settings.menuType = this.menuTypeOption;
  }

  public changeTheme(theme) {
    this.settings.theme = theme;
  }

  public toggleSidenav() {
    this.sidenav.toggle();
  }

  public onPsScrollY(event) {
    (event.target.scrollTop > 300) ? this.backToTop.nativeElement.style.display = 'flex' : this.backToTop.nativeElement.style.display = 'none';
    if (this.settings.menu == 'horizontal') {
      if (this.settings.fixedHeader) {
        var currentScrollTop = (event.target.scrollTop > 56) ? event.target.scrollTop : 0;
        if (currentScrollTop > this.lastScrollTop) {
          document.querySelector('#horizontal-menu').classList.add('sticky');
          event.target.classList.add('horizontal-menu-hidden');
        }
        else {
          document.querySelector('#horizontal-menu').classList.remove('sticky');
          event.target.classList.remove('horizontal-menu-hidden');
        }
        this.lastScrollTop = currentScrollTop;
      }
      else {
        if (event.target.scrollTop > 56) {
          document.querySelector('#horizontal-menu').classList.add('sticky');
          event.target.classList.add('horizontal-menu-hidden');
        }
        else {
          document.querySelector('#horizontal-menu').classList.remove('sticky');
          event.target.classList.remove('horizontal-menu-hidden');
        }
      }
    }
  }

  public scrollToTop() {
    this.pss.forEach(ps => {
      if (ps.elementRef.nativeElement.id == 'main' || ps.elementRef.nativeElement.id == 'main-content') {
        ps.scrollToTop(0, 250);
      }
    });
  }


  // @HostListener('window:resize')
  // public onWindowResize():void {
  //   if(window.innerWidth <= 768){
  //     this.settings.sidenavIsOpened = false;
  //     this.settings.menu = this.settings.sidenavIsPinned ?  'vertical' : 'horizontal'
  //   }
  //   else{
  //     (this.defaultMenu == 'horizontal') ? this.settings.menu = sessionStorage.sidenavIsPinned ?  'vertical' : 'horizontal':
  //       this.settings.menu = sessionStorage.sidenavIsPinned ?  'vertical' : 'horizontal'
  //     this.settings.sidenavIsOpened = sessionStorage.sidenavIsPinned;
  //   }
  // }

  public closeSubMenus() {
    let menu = document.querySelector(".sidenav-menu-outer");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }

    this.toggleHomeLink()
  }

  toggleHomeLink = () => {
    const userInfo = JSON.parse(localStorage.getItem('USER_INFO'))
    this.currRoleName = userInfo.roleName
    if (this.currRoleName === 'ROLE_GRAVITY_QA_ADMIN' || this.currRoleName === 'ROLE_GRAVITY_QA_USER') {
      this.router.navigate(['/qc-dashboard'])
    } else {
      this.router.navigate(['/dashboard'])
    }
  }

}