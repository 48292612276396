import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators'; 

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
 
  error;
  statusCode;
  statusText;

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        //console.log('--- ErrorInterceptor : before :' + JSON.stringify(err));
   
        if ([400].indexOf(err.status) !== -1) {
          if (err.error != null && err.error.message != null) {
            console.log('err.error          :' + JSON.stringify(err.error));
            //console.log('err.statusTex      :' + JSON.stringify(err.statusTex));
            console.log('err.error.message  :' + JSON.stringify(err.error.message));
            this.error = err.error.message || err.statusText; 
          } 
        } 

        //console.log('ErrorInterceptor : after :' + JSON.stringify(this.error));
        return throwError(this.error);
      })
    );
  }
}