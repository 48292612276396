import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../../pages/auth/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  userData: any;
  public userImage = '../assets/img/users/user.jpg';
  constructor(private authService: AuthService) {
    this.userData = this.authService.getUserDetails$.value;
    // console.log(this.userData, 'useruseruseruser');
   }

  ngOnInit() {}

  logout() {
    this.authService.logout();
  }

}
