export class Settings {
    constructor(public name: string, 
        public loadingDash:boolean,
                public loading: boolean,
                public loadingModal:boolean,
                public fixedHeader:boolean,
                public sidenavIsOpened: boolean,
                public sidenavIsPinned: boolean,
                public dashboardData: any,
                public sidenavUserBlock: boolean,
                public menu: string,
                public menuType: string,
                public theme: string,
                public rtl: boolean,
                public hasFooter: boolean) { }
}