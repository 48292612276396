import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { SiteWorkingService } from "../../../features/investigator-site-file/site-working-file/site-working.service";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD-MMM-YYYY",
  },
  display: {
    dateInput: "DD-MMM-YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "DD-MMM-YYYY",
    monthYearA11yLabel: "MMM YYYY",
  },
};
@Component({
  selector: "app-doc-download",
  templateUrl: "./doc-download.component.html",
  styleUrls: ["./doc-download.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    DatePipe,
  ],
})
export class DocDownloadComponent implements OnInit {
  fromDate: Date;
  toDate: Date;
  docType: any;
  maxDateLimit = new Date();
  constructor(
    public dialogRef: MatDialogRef<DocDownloadComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private datePipe: DatePipe,
    private siteWorkingService: SiteWorkingService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    let Data = {
      fromDate: this.datePipe.transform(this.fromDate, "dd-MM-yyyy"),
      toDate: this.datePipe.transform(this.toDate, "dd-MM-yyyy"),
      docType: this.docType,
    };
    this.dialogRef.close(Data);
  }
}
