import { Component, OnInit, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { QcAdminUserService } from "../../../pages/qc-admin-user/qc-admin-user.service";
import * as UAParser from "ua-parser-js";
import { SnackbarService } from "../../../shared/components/toast/snackbar.service";
import { S3FileOperationService } from "app/common/s3-file-operation.service";
import { InvestigatorSiteFileService } from "app/features/investigator-site-file/investigator-site-file.service";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
})
export class PdfViewerComponent implements OnInit {
  pdf: string;

  // the 3 props below are only utilized by QCUser Component
  isQCUserActive = false;
  form: FormGroup;
  documentUploadId: any;
  docType: any;
  submitted: boolean = false;
  userAgentDetails: any;
  showData: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PdfViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private fb: FormBuilder,
    private qcAdminUserService: QcAdminUserService,
    private downloadService: S3FileOperationService,
    private toast: SnackbarService,
    public ISFService: InvestigatorSiteFileService,
  ) {
    let parser = new UAParser();
    this.userAgentDetails = parser.getResult();
    this.pdf = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";
    if(!this.modalData.file.includes('amazonaws.com/')) {
      this.ISFService.getSecureSignedUrl({fileUrl: this.modalData.file}).subscribe({
        next:(img:any) => {
          this.modalData.file = img.url;
          this.modalData.info.documentLocation = img.url;
          console.log(this.modalData, "Doc-Data");
          this.showData = true;
        }
      }) 
    } 


    if (this.modalData.isQCUserActive) {
      this.isQCUserActive = true;
      this.documentUploadId = this.modalData.documentUploadId;
      this.docType = this.modalData.docType;

      this.form = this.fb.group({
        approveAuditTrial: ["", Validators.required],
        comment: [""],
      });

      this.form.controls.approveAuditTrial.valueChanges.subscribe(
        (chosenValue) => {
          console.log(`Chosen Value `, chosenValue);
          if (parseInt(chosenValue) === 0) {
            this.form.controls.comment.setValidators(Validators.required);
            this.form.controls.comment.updateValueAndValidity();
           
          }
          if (parseInt(chosenValue) === 1) {
            this.form.controls.comment.clearValidators();
            this.form.controls.comment.updateValueAndValidity();
          
          }
        }
      );
    
    }
    
  }

  ngOnInit(): void {}

  close(): void {
    this.dialogRef.close();
  }

  submitAuditStatus() {
    this.submitted = true;
    if (parseInt(this.form.controls.approveAuditTrial.value) === 0) {
      this.form.controls.comment.markAsTouched();
    }
      console.log("ModalData-->",this.modalData.info);
    if (this.form.invalid) return;
    console.log(`Audit Status is submitted `, this.form.value);
    if (this.form.value.approveAuditTrial !== '0'){
      this.toast.openSnackBar(`Document Approved Successfully`);
    } else {
      this.toast.openSnackBar(`Document Rejected Successfully`);
    }
    let Data = {
      approveAuditTrial: this.form.value.approveAuditTrial,
      comment: this.form.value.comment,
      docType: this.docType,
      browser: JSON.stringify(this.userAgentDetails),
     }
     let data = {
      message:"approvedRejected",
      docId:this.documentUploadId,
      Data: Data,
     } 

     console.log('data',data);
     this.dialogRef.close(data);
  }

  download(file) {
    if (file !== null || file !== '' || file !== undefined) {
      console.log('--- fileName :' + file)
      // https://d2g9891qhxvso2.cloudfront.net/gravity/3/0/samplepdf3dcede03445d7442f84955f82e27ed85d.pdf
    var pathname = new URL(file).pathname.replace(/^\/+/, '');
    // gravity/documentsuploaded/BioconInternational/RocheInc/Covid19/12/55/253/0/samplepdf3dcede03445d7442f84955f82e27ed85d.pd
      console.log('Path:', pathname);
      let fileUrl = '';
      this.ISFService.getPDFDownload({ fileUrl: pathname }).subscribe({
        next: (urlRes: any) => {
          console.log('urlRes:', urlRes);
          fileUrl = urlRes.url;
          if (fileUrl !== null || fileUrl !== '' || fileUrl !== undefined) {
            const url = fileUrl;
            let headers = new Headers();
            headers.append('Content-Type', 'application/json');
            headers.append('Accept', 'application/json');
            headers.append('Access-Control-Allow-Origin', '*');
            var bytes = new Uint8Array(urlRes.data); 
            var blob=new Blob([bytes], {type: "application/pdf"});
            var link=document.createElement('a');
            link.href=window.URL.createObjectURL(blob);
            link.download= pathname.split('/').pop();
            link.click();
          }
        },
        error: (err: any) => console.log('Err:', err),
      });
    }
  }
}
