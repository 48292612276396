import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormsService } from "../../services/forms.service";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SnackbarService } from "../toast/snackbar.service";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-prompt-modal",
  templateUrl: "./prompt-modal.component.html",
  styleUrls: ["./prompt-modal.component.scss"],
})
export class PromptModalComponent implements OnInit {
  public form: FormGroup;
  public settings: Settings;
  remarks: string = "";
  constructor(
    private spinner: NgxSpinnerService,
    public appSettings: AppSettings,
    public dialogRef: MatDialogRef<PromptModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private formsService: FormsService,
    private snackBar: SnackbarService,
    public fb: FormBuilder,

  ) {
    this.settings = this.appSettings.settings;
    dialogRef.disableClose = true;
    this.form = this.fb.group({
      remarks: ["", Validators.required],

    })
  }

  ngOnInit() { }

  onConfirmClick(): void {
    console.log("Confirmation Done", this.modalData);
    if (this.modalData.type === "withComment") {
      if (!this.form.valid) {
        return
      }
      console.log("remarks", this.form.controls.remarks.value);
      this.dialogRef.close(this.form.controls.remarks.value ? this.form.controls.remarks.value : "NA");
    }
    if (this.modalData.type !== "withComment")
      // this.dialogRef.close(this.settings.loading =false;);
      this.dialogRef.close(true);


  }

  closebtn() {
    this.dialogRef.close(false);
  }

}
