import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styleUrls: ['./audit-history.component.scss']
})
export class AuditHistoryComponent implements OnInit {
  public form:FormGroup;
  auditHistory: Array<any>;
  constructor(public dialogRef: MatDialogRef<AuditHistoryComponent>,
              @Inject(MAT_DIALOG_DATA) public modalData: any,
              public fb: FormBuilder) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    console.log(this.modalData, 'this.dataaa');
   
    this.auditHistory = this.modalData.data
  }
  close(): void {
    this.dialogRef.close();
  }
  

}



