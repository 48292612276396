import { AbstractControl, AsyncValidatorFn } from '@angular/forms'
import { TrialManagementService } from '../../features/trial-master-file/trial-management/trial-management.service'
import { map } from 'rxjs/operators'
import { SiteWorkingService } from '../../features/investigator-site-file/site-working-file/site-working.service'
import { SiteReferrenceFileService } from '../../features/investigator-site-file/site-reference-file/site-referrence-file.service'

export function uniqueDocVersionValidator(trialManagementService: TrialManagementService, selectedDocTitle, projectId: number, categoryid: number = 0, subcategoryid: number = 0, contentTypeId: number = 0, subContentTypeId: number = 0): AsyncValidatorFn {
    return (control: AbstractControl) => {
        return trialManagementService.getTrialManageDocsStatusList(projectId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
            map((docs: any) => {
                const similiarVersionNo = docs
                    .filter(item => item?.documentTitle === selectedDocTitle)
                    .find(item => item?.documentVersion === control.value)

                return similiarVersionNo ? { versionNumberAlreadyExists: true } : null
            })
        )
    }
}

export function isfUniqueVersionValidator(siteWorkingService: SiteWorkingService, selectedDocTitle, projectId: number, siteid, categoryid: number, subcategoryid: number, contentTypeId: number, subContentTypeId: number): AsyncValidatorFn {
    return (control: AbstractControl) => {
        if (contentTypeId === 253) {
            return siteWorkingService.get174DocumentTitleList(projectId, siteid, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
                map((docs: any) => {
                    const similiarVersionNo = docs.filter(item => item?.documentTitle === selectedDocTitle).find(item => item?.documentVersion === control.value).documentVersion;
                    console.log("UniqueVersion -->", similiarVersionNo);
                    return similiarVersionNo ? { versionNumberAlreadyExists: true } : null

                })
            )
        } else if (contentTypeId === 260) {
            return siteWorkingService.get1834DocumentTitleList(projectId, siteid, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
                map((docs: any) => {
                    const similiarVersionNo = docs.filter(item => item?.documentTitle === selectedDocTitle).find(item => item?.documentVersion === control.value).documentVersion;
                    console.log("UniqueVersion -->", similiarVersionNo);
                    return similiarVersionNo ? { versionNumberAlreadyExists: true } : null

                })
            )
        } else {
            return siteWorkingService.getDocumentTitleList(projectId, siteid, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
                map((docs: any) => {
                    console.log('Docs:', typeof docs[0].documentVersion, 'selectedDocTitle', selectedDocTitle);
                    console.log(typeof control.value);
                    const similiarVersionNo = docs.filter(item => item?.documentTitle === selectedDocTitle).find(item => item?.documentVersion === control.value)?.documentVersion;
                    console.log("UniqueVersion -->", similiarVersionNo);
                    return similiarVersionNo ? { versionNumberAlreadyExists: true } : null

                })
            )
        }
    }
}

export function versionValidator(SiteReferrenceFileService: SiteReferrenceFileService, projectId: number, siteId, categoryid: number, subcategoryid: number, contentTypeId: number, subContentTypeId: number): AsyncValidatorFn {
    return (control: AbstractControl) => {
        return SiteReferrenceFileService.get33documentTitleList(projectId, siteId, categoryid, subcategoryid, contentTypeId, subContentTypeId).pipe(
            map((docs: any) => {
                const similiarTitles = docs.find(item => {
                    return item?.documentTitle?.toLowerCase() === control.value.toLowerCase()
                })

                return similiarTitles ? { titleAlreadyExists: true } : null
            })
        )
    }
}